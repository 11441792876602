import { Dropdown, Label, TextInput } from 'flowbite-react'
import { FunctionComponent, useEffect, useState } from 'react'

type AutocompleteDropdownProps = {
  items: string[]
  labelText?: string
  placeholder?: string
  onSelection: (item: string) => void
  onChange: (item: string) => void
}

export const AutocompleteDropdown: FunctionComponent<AutocompleteDropdownProps> = ({
  items,
  labelText,
  placeholder,
  onSelection,
  onChange,
}) => {
  const [query, setQuery] = useState('')
  const [filteredItems, setFilteredItems] = useState<string[]>([])

  useEffect(() => {
    if (query.length > 2) {
      setFilteredItems(items.filter((item) => item.toLowerCase().includes(query.toLowerCase())))
    } else {
      setFilteredItems([])
    }
  }, [query, items])

  return (
    <div className='flex items-center w-full max-w-xs'>
      {labelText && <Label htmlFor='autocomplete' value={labelText} className='mr-2' />}
      <TextInput
        id='autocomplete'
        type='text'
        placeholder={placeholder || 'Type to search...'}
        value={query}
        onChange={(e) => {
          setQuery(e.target.value)
          onChange(e.target.value)
        }}
        className='flex-grow'
      />
      <Dropdown size='lg' disabled={filteredItems.length === 0}>
        {filteredItems.length > 0 &&
          filteredItems.map((item) => (
            <Dropdown.Item key={item} onClick={() => onSelection(item)}>
              {item}
            </Dropdown.Item>
          ))}
      </Dropdown>
    </div>
  )
}
