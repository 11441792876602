import { DndContext } from '@dnd-kit/core'
import { AllGeoJSON, transformTranslate, type FeatureCollection } from '@turf/turf'
import {
  mapDeletingDestination,
  mapDeletingFolder,
  mapMovingFolderToParent,
  mapRenamingDestinationWithId,
  mapRenamingFolderWithId,
  type DestinationMapFolder,
  type DestinationMapInterface,
  type DestinationMapLineStringFeature,
  type DestinationMapPointFeature,
} from 'burro-map-utils'
import type {
  DestinationMapRequestLidarSitemapUid,
  DestinationMapsGetResponseResultsInner,
} from 'burro-web-api-js-client'
import { Button, Dropdown, Modal } from 'flowbite-react'
import { DotsVertical } from 'flowbite-react-icons/outline'
import { FunctionComponent, HTMLAttributes, useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { FaRegEdit } from 'react-icons/fa'
import {
  FaArrowsUpDownLeftRight,
  FaCopy,
  FaDownload,
  FaFileImport,
  FaFolderPlus,
  FaMapLocationDot,
  FaPaste,
  FaTrash,
} from 'react-icons/fa6'
import { MdOutlineRadar } from 'react-icons/md'
import { TreeRoot } from 'react-nested-tree2'
import { useFilePicker } from 'use-file-picker'
import { FileContent } from 'use-file-picker/types'
import { v4 as uuidv4 } from 'uuid'
import { BURRO_DEFAULT_SPEED_MPS } from '../../constants'
import { GlobalStateContext } from '../../context/GlobalStateContext'
import { useDestinationMapPatchMutation } from '../../hooks/mutations/useDestinationMapPatchMutation'
import { useDestinationMapGetQuery } from '../../hooks/queries/useDestinationMapGetQuery'
import { prettyId } from '../../utils/prettyId'
import { ConfirmationModal } from '../ui/modals/ConfirmationModal'
import { TextareaModal } from '../ui/modals/TextAreaModal'
import { TextInputModal } from '../ui/modals/TextInputModal'
import { PanelDrawerLayoutMode } from '../ui/PanelDrawerLayout/PanelDrawerLayout'
import { DEFAULT_IMPORT_GEOJSON } from '../ui/ShapesTree/ShapesTree'
import { DestinationMapsContext } from './DestinationMapsContext'
import { DestinationMapsFoldersTreeBranchComponent } from './DestinationMapsFoldersTreeBranchComponent'
import { DestinationMapsFoldersTreeLeafComponent } from './DestinationMapsFoldersTreeLeafComponent'
import { LidarSitemapsModal } from './LidarSitemapsModal'
import { TransformForm } from './TransformForm'
import { treeDataForDestinationMap } from './treeDataForDestinationMap'

export const DESTINATION_DND_DELIMITER = '___'

export const DestinationMapsListEntry: FunctionComponent<
  {
    destinationMap: DestinationMapsGetResponseResultsInner
    index: number
  } & HTMLAttributes<HTMLDivElement>
> = ({ destinationMap, index, ...props }) => {
  const { setBounds: setBoundsInContext } = useContext(GlobalStateContext)
  const {
    addDestinationMap,
    destinationMapRenderState,
    pastEditCount,
    removeDestinationMapAtIndex,
    selectedDestinationMapIndex,
    setDestinationMapRenderState,
    setIsLeftOpen,
    setSelectedDestinationMapIndex,
    updateDestinationMapAtIndex,
    mode,
  } = useContext(DestinationMapsContext)

  const filePicker = useFilePicker({
    accept: '.geojson',
    onFilesSuccessfullySelected: ({ filesContent }: { filesContent: FileContent<string>[] }) => {
      const geojson = JSON.parse(filesContent[0].content)
      if (geojson?.properties === undefined) {
        geojson.properties = { folders: [], name: destinationMap.name ?? '' }
      }
      setDestinationMapRenderState(geojson as DestinationMapInterface)
    },
  })

  const destinationMapPatchMutation = useDestinationMapPatchMutation()

  const destinationMapGetQuery = useDestinationMapGetQuery({
    destinationMapUid: index === selectedDestinationMapIndex ? destinationMap.uid : undefined,
  })

  const [isShowingFolderNameTextFieldModalState, setIsShowingFolderNameTextFieldModalState] = useState(false)
  const [isInsertGeoJSONOpenState, setIsImportGeoJSONOpenState] = useState(false)
  const [isRenamingState, setIsRenamingState] = useState(false)
  const [isTreeVisibleState, setIsTreeVisibleState] = useState(false)
  const [addFolderParentIdState, setAddFolderParentIdState] = useState<string | null>(null)
  const [isShowingDeleteModalState, setIsShowingDeleteModalState] = useState(false)
  const [renamingDestinationState, setRenamingDestinationState] = useState<{ id: string; name: string } | null>(null)
  const [renamingFolderState, setRenamingFolderState] = useState<{ id: string; name: string } | null>(null)
  const [isSelectingLidarSitemapState, setIsSelectingLidarSitemapState] = useState<boolean>(false)
  const [isShowingTransformModalState, setIsShowingTransformModalState] = useState<boolean>(false)
  const [isShowingReplaceGeoJSONState, setIsReplacingGeoJSONState] = useState<boolean>(false)

  const isSelected = selectedDestinationMapIndex === index
  useEffect(() => {
    setIsTreeVisibleState(isSelected)
  }, [isSelected])

  const destinationMapGeoJsonData =
    isSelected && destinationMapRenderState ? destinationMapRenderState : destinationMapGetQuery.data?.geojson_data

  const currentEntryGeoJSONString = destinationMapGeoJsonData
    ? JSON.stringify(destinationMapGeoJsonData)
    : DEFAULT_IMPORT_GEOJSON

  async function moveFolderIntoFolder(folderId: string, newParentFolderId: string | null) {
    if (destinationMapRenderState) {
      const newMap = mapMovingFolderToParent({
        map: destinationMapRenderState,
        childFolderId: folderId,
        parentFolderId: newParentFolderId,
      })

      if (newMap) {
        setDestinationMapRenderState(newMap)
      }
    }
  }

  async function moveDestinationIntoFolder(destinationId: string, folderId: string | null) {
    if (destinationMapRenderState) {
      const newMap = structuredClone(destinationMapRenderState) as DestinationMapInterface
      const feature = newMap.features?.find((feature) => feature.id === destinationId) as
        | DestinationMapPointFeature
        | undefined

      if (feature) {
        ;(feature.properties as any).folder_id = folderId ?? undefined
        setDestinationMapRenderState(newMap)
      }
    }
  }

  return (
    <>
      <DndContext
        onDragEnd={(event) => {
          const { over, active } = event
          const overId = over?.id
          const activeId = active?.id

          if (!activeId) {
            return
          }

          const overIds = overId?.toString().split(DESTINATION_DND_DELIMITER)
          const activeIds = activeId.toString().split(DESTINATION_DND_DELIMITER)

          if (!overIds || overIds.length === 0) {
            if (activeIds[1] === 'folder') {
              moveFolderIntoFolder(activeIds[2], null)
            } else if (activeIds[1] === 'destination') {
              moveDestinationIntoFolder(activeIds[2], null)
            }
          } else if (overIds[2] !== activeIds[2]) {
            if (overIds[1] === 'folder') {
              if (activeIds[1] === 'destination') {
                moveDestinationIntoFolder(activeIds[2], overIds[2])
              } else if (activeIds[1] === 'folder') {
                moveFolderIntoFolder(activeIds[2], overIds[2])
              }
            }
          }
        }}
      >
        <div {...props}>
          <div className='flex w-full items-center justify-between gap-x-2 '>
            <Button
              color='light'
              size='xs'
              onClick={() => {
                if (pastEditCount !== 0) {
                  toast.error('Please finish editing')
                } else {
                  setSelectedDestinationMapIndex(index)
                }
              }}
            >
              <FaMapLocationDot size={20} />
            </Button>

            <div className='flex-grow text-left text-gray-900 dark:text-white'>
              <p className='font-medium cursor-text'>
                {destinationMap.name} {}
              </p>
              <p className='text-sm font-normal italic cursor-text'>
                v{destinationMap.revision} updated{' '}
                {destinationMap.modified_date_iso_8601
                  ? new Date(destinationMap.modified_date_iso_8601).toLocaleString()
                  : 'NA'}
              </p>
            </div>
            <div className='flex-shrink'>
              <Dropdown
                label='edit route'
                renderTrigger={() => (
                  <Button title='Edit route' disabled={!isSelected} size='xs' color='gray'>
                    <DotsVertical />
                  </Button>
                )}
              >
                <Dropdown.Item
                  icon={FaFolderPlus}
                  onClick={() => {
                    setIsShowingFolderNameTextFieldModalState(true)
                  }}
                >
                  Add Folder
                </Dropdown.Item>
                <Dropdown.Item
                  icon={FaRegEdit}
                  onClick={() => {
                    setIsRenamingState(true)
                  }}
                >
                  Rename
                </Dropdown.Item>
                <Dropdown.Item
                  icon={FaTrash}
                  onClick={() => {
                    setIsShowingDeleteModalState(true)
                  }}
                >
                  Delete
                </Dropdown.Item>
                <Dropdown.Item
                  icon={FaCopy}
                  onClick={() => {
                    addDestinationMap(
                      `${destinationMap.name ?? 'map'} (copy)`,
                      destinationMapGeoJsonData as DestinationMapInterface
                    )
                  }}
                >
                  Duplicate
                </Dropdown.Item>
                <Dropdown.Item
                  icon={FaPaste}
                  onClick={() => {
                    setIsImportGeoJSONOpenState(true)
                  }}
                >
                  Insert GeoJSON
                </Dropdown.Item>
                <Dropdown.Item
                  icon={FaDownload}
                  onClick={() => {
                    if (!destinationMapGeoJsonData) {
                      return
                    }
                    const file = new Blob([currentEntryGeoJSONString], { type: 'application/json' })
                    const element = document.createElement('a')
                    element.href = URL.createObjectURL(file)
                    element.download =
                      `${destinationMap.name ?? 'file'}_${new Date().toISOString()}`.replace(/[:.]/g, '_') + `.geojson`
                    document.body.appendChild(element)
                    element.click()
                    document.body.removeChild(element)
                  }}
                >
                  Download GeoJSON
                </Dropdown.Item>
                <Dropdown.Item icon={FaFileImport} onClick={() => setIsReplacingGeoJSONState(true)}>
                  Replace GeoJSON
                </Dropdown.Item>
                <Dropdown.Item icon={MdOutlineRadar} onClick={() => setIsSelectingLidarSitemapState(true)}>
                  Associate LiDAR map
                </Dropdown.Item>
                <Dropdown.Item icon={FaArrowsUpDownLeftRight} onClick={() => setIsShowingTransformModalState(true)}>
                  Transform
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>
        </div>

        {isTreeVisibleState && destinationMap && isSelected && (
          <TreeRoot<DestinationMapFolder, DestinationMapPointFeature>
            className='ml-5 border-l border-gray-200 dark:border-gray-700'
            depth={0}
            data={treeDataForDestinationMap(destinationMapGeoJsonData)}
            renderLeaf={(feature) => {
              return (
                <DestinationMapsFoldersTreeLeafComponent
                  data={feature}
                  key={feature.id}
                  className={`px-4 py-2 text-gray-900 dark:text-white`}
                  onClickRename={() => {
                    setRenamingDestinationState({ id: feature.id, name: feature.properties.name ?? '' })
                  }}
                  onClickDelete={() => {
                    if (!destinationMapRenderState) {
                      return
                    }
                    const newMap = mapDeletingDestination({
                      map: destinationMapRenderState,
                      destinationId: feature.id,
                    })
                    if (newMap) {
                      setDestinationMapRenderState(newMap)
                    }
                  }}
                  onClickSelect={() => {
                    if (feature.geometry.type === 'Point') {
                      setBoundsInContext({
                        _northEast: {
                          lat: feature.geometry.coordinates[1] + 0.0001,
                          lng: feature.geometry.coordinates[0] + 0.0001,
                        },
                        _southWest: {
                          lat: feature.geometry.coordinates[1] - 0.0001,
                          lng: feature.geometry.coordinates[0] - 0.0001,
                        },
                      })

                      if (mode === PanelDrawerLayoutMode.DRAWER) {
                        setIsLeftOpen(false)
                      }
                    }
                  }}
                />
              )
            }}
            renderBranch={(folder, isToggled, onToggle) => {
              return (
                <DestinationMapsFoldersTreeBranchComponent
                  key={folder.id}
                  className={`w-full px-4 py-2 text-gray-900 dark:text-white`}
                  onClickRename={() => {
                    setRenamingFolderState({ id: folder.id, name: folder.name })
                  }}
                  onClickDelete={() => {
                    if (!destinationMapRenderState) {
                      return
                    }
                    const newMap = mapDeletingFolder({ map: destinationMapRenderState, folderId: folder.id })!
                    if (newMap) {
                      setDestinationMapRenderState(newMap)
                    }
                  }}
                  onClickAddFolder={() => {
                    setAddFolderParentIdState(folder.id)
                  }}
                  data={folder}
                  isToggled={isToggled}
                  onToggle={onToggle}
                />
              )
            }}
          />
        )}
      </DndContext>
      <TextInputModal
        headerText='Add Folder'
        size={'sm'}
        textInputProps={{ placeholder: 'Folder name' }}
        show={isShowingFolderNameTextFieldModalState || addFolderParentIdState !== null}
        onClose={() => {
          setIsShowingFolderNameTextFieldModalState(false)
          setAddFolderParentIdState(null)
        }}
        onValidSubmit={({ data }) => {
          const newDestinationMap = structuredClone(destinationMapGeoJsonData) as DestinationMapInterface
          if (newDestinationMap == undefined) {
            return
          }

          if (newDestinationMap?.properties === undefined) {
            newDestinationMap.properties = { folders: [], name: destinationMap.name ?? '' }
          }

          newDestinationMap?.properties.folders.push({
            id: prettyId({ name: data.text }),
            name: data.text,
            children: [],
          })
          setDestinationMapRenderState(newDestinationMap)
        }}
      />
      <TextInputModal
        headerText='Rename Destination Map'
        size='sm'
        show={isRenamingState}
        textInputProps={{ placeholder: 'Map name', defaultValue: destinationMap.name }}
        onClose={() => {
          setIsRenamingState(false)
        }}
        onValidSubmit={({ data }) => {
          updateDestinationMapAtIndex(index, destinationMapGeoJsonData as DestinationMapInterface, data.text)
          setIsRenamingState(false)
        }}
      />
      <TextareaModal
        textAreaProps={{
          placeholder: `{
  "type": "FeatureCollection",
  "features": []
}`,
          rows: 10,
          helperText:
            'You can copy Routes created by Person Follow, a Feature Collection from another map, or edit your own GeoJSON and paste them here. It supports LineStrings and Points. To completely replace your GeoJSON, use the "Replace GeoJSON" feature.',
        }}
        labelText='GeoJSON'
        headerText='Insert into Destination Map'
        show={isInsertGeoJSONOpenState}
        onClose={() => {
          setIsImportGeoJSONOpenState(false)
        }}
        onValidSubmit={(data) => {
          const geojson = JSON.parse(data.text) as FeatureCollection

          const newDestinationMap = structuredClone(destinationMapGeoJsonData) as DestinationMapInterface
          for (const feature of geojson.features) {
            if (feature.geometry.type === 'LineString') {
              const existingFeature = newDestinationMap?.features?.find((f) => f.id === feature.id)
              if (!existingFeature) {
                newDestinationMap?.features?.push({
                  id: uuidv4(),
                  ...feature,
                  properties: { direction: 'two_way', speed_limit: BURRO_DEFAULT_SPEED_MPS },
                } as DestinationMapLineStringFeature)
              }
            } else if (feature.geometry.type === 'Point') {
              const existingFeature = newDestinationMap?.features?.find((f) => f.id === feature.id)
              if (!existingFeature) {
                newDestinationMap?.features?.push({
                  ...feature,
                  properties: {
                    name: (feature.properties ? feature.properties['name'] : 'Unnamed') ?? 'Unnamed',
                    type: 'destination',
                  },
                } as DestinationMapPointFeature)
              }
            }
          }

          if (newDestinationMap) {
            setDestinationMapRenderState(newDestinationMap)
          }

          setIsImportGeoJSONOpenState(false)
        }}
      />
      <ConfirmationModal
        headerText={`Delete "${destinationMap.name}"?`}
        bodyText={`Are you sure you want to delete this destination map? It will be deleted for all users and this action cannot be undone.`}
        show={isShowingDeleteModalState}
        onClose={() => setIsShowingDeleteModalState(false)}
        onConfirm={() => removeDestinationMapAtIndex(index)}
      />
      <ConfirmationModal
        headerText={`Replace GeoJSON for "${destinationMap.name}"?`}
        bodyText={`Are you sure you want to replace the GeoJSON for this destination map? This will completely replace the current GeoJSON with the new GeoJSON.`}
        show={isShowingReplaceGeoJSONState}
        onClose={() => setIsReplacingGeoJSONState(false)}
        onConfirm={() => {
          filePicker.openFilePicker()
          setIsReplacingGeoJSONState(false)
        }}
      />
      <TextInputModal
        headerText='Rename Destination'
        textInputProps={{ placeholder: 'Destination name', defaultValue: renamingDestinationState?.name }}
        size='sm'
        show={renamingDestinationState !== null}
        onClose={() => {
          setRenamingDestinationState(null)
        }}
        onValidSubmit={({ data }) => {
          if (renamingDestinationState && destinationMapRenderState) {
            const newMap = mapRenamingDestinationWithId({
              map: destinationMapRenderState,
              destinationId: renamingDestinationState.id,
              newName: data.text,
            })
            if (newMap) {
              setDestinationMapRenderState(newMap)
            }
          }

          setRenamingDestinationState(null)
        }}
      />
      <TextInputModal
        headerText='Rename Folder'
        textInputProps={{ placeholder: 'Folder name', defaultValue: renamingFolderState?.name }}
        size='sm'
        show={renamingFolderState !== null}
        onClose={() => {
          setRenamingFolderState(null)
        }}
        onValidSubmit={({ data }) => {
          if (renamingFolderState && destinationMapRenderState) {
            const newMap = mapRenamingFolderWithId({
              map: destinationMapRenderState,
              folderId: renamingFolderState.id,
              newName: data.text,
            })
            if (newMap) {
              setDestinationMapRenderState(newMap)
            }
          }

          setRenamingFolderState(null)
        }}
      />
      <LidarSitemapsModal
        show={isSelectingLidarSitemapState}
        activeUid={destinationMap.lidar_sitemap_uid}
        onClose={() => setIsSelectingLidarSitemapState(false)}
        onClickLidarSitemap={async ({ uid }) => {
          await destinationMapPatchMutation.mutateAsync({
            destinationMapUid: destinationMap.uid!,
            destinationMapRequest: {
              lidar_sitemap_uid: uid,
            },
          })
          setIsSelectingLidarSitemapState(false)
        }}
        onClickDisassociate={async () => {
          await destinationMapPatchMutation.mutateAsync({
            destinationMapUid: destinationMap.uid!,
            destinationMapRequest: {
              lidar_sitemap_uid: null as unknown as DestinationMapRequestLidarSitemapUid,
            },
          })
          setIsSelectingLidarSitemapState(false)
        }}
      />
      <Modal show={isShowingTransformModalState} onClose={() => setIsShowingTransformModalState(false)}>
        <Modal.Header>Transform Destination Map</Modal.Header>
        <Modal.Body>
          <TransformForm
            onValid={(data) => {
              const geojsonClone = structuredClone(destinationMapGeoJsonData) as AllGeoJSON
              if (!geojsonClone) {
                return
              }
              transformTranslate(geojsonClone as any, data.northDistanceMeters, 0, {
                units: 'meters',
                mutate: true,
              })
              transformTranslate(geojsonClone as any, data.westDistanceMeters, 270, {
                units: 'meters',
                mutate: true,
              })
              setDestinationMapRenderState(geojsonClone as DestinationMapInterface)

              setIsShowingTransformModalState(false)
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
