import { useDraggable, useDroppable } from '@dnd-kit/core'
import type { DestinationMapFolder } from 'burro-map-utils'
import { Button, Dropdown } from 'flowbite-react'
import { DotsVertical, Folder, FolderOpen } from 'flowbite-react-icons/outline'
import { FunctionComponent, HTMLAttributes } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import { FaFolderPlus, FaGripVertical, FaTrash } from 'react-icons/fa6'
import { DESTINATION_DND_DELIMITER } from './DestinationMapsListEntry'

type DestinationMapsFoldersTreeBranchProps = {
  data: DestinationMapFolder
  isToggled: boolean
  onClickRename: () => void
  onToggle: () => void
  onClickDelete: () => void
  onClickAddFolder: () => void
} & HTMLAttributes<HTMLDivElement>

export const DestinationMapsFoldersTreeBranchComponent: FunctionComponent<DestinationMapsFoldersTreeBranchProps> = ({
  data,
  isToggled,
  onClickRename,
  onToggle,
  onClickDelete,
  onClickAddFolder,
  ...divProps
}) => {
  const { setNodeRef: setDroppableNodeRef } = useDroppable({
    id: `droppable${DESTINATION_DND_DELIMITER}folder${DESTINATION_DND_DELIMITER}${data.id}${DESTINATION_DND_DELIMITER}${data.name}`,
  })

  const {
    attributes,
    listeners,
    setNodeRef: setDraggableNodeRef,
    setActivatorNodeRef: setDraggableActivatorNodeRef,
    transform,
  } = useDraggable({
    id: `draggable${DESTINATION_DND_DELIMITER}folder${DESTINATION_DND_DELIMITER}${data.id}${DESTINATION_DND_DELIMITER}${data.name}`,
  })
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: 999,
      }
    : undefined

  return (
    <div ref={setDroppableNodeRef} {...divProps}>
      <div ref={setDraggableNodeRef} style={style} className={`flex items-center justify-between`}>
        <div className={`flex flex-1 items-center rounded-sm gap-x-2`}>
          <div ref={setDraggableActivatorNodeRef} {...listeners} {...attributes} className='cursor-grabbing'>
            <FaGripVertical />
          </div>
          <Button size='xs' color='light' onClick={onToggle}>
            {isToggled ? <FolderOpen /> : <Folder />}
          </Button>

          <div className='flex-1 text-sm'>{data.name}</div>
          <div className={`items-center`}>
            <Dropdown
              label='edit route'
              renderTrigger={() => (
                <Button size='xs' color='gray'>
                  <DotsVertical />
                </Button>
              )}
            >
              <Dropdown.Item icon={FaFolderPlus} onClick={onClickAddFolder}>
                Add Folder
              </Dropdown.Item>
              <Dropdown.Item icon={FaRegEdit} onClick={onClickRename}>
                Rename
              </Dropdown.Item>
              <Dropdown.Item icon={FaTrash} onClick={onClickDelete}>
                Delete
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  )
}
