import CircularSlider from '@fseehawer/react-circular-slider'
import { Button, Checkbox, Label, TextInput, useThemeMode } from 'flowbite-react'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import type { DefaultValues, SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'

interface PlanPathFieldParametersFormValues {
  coverageWidthMeters: number
  turnRadiusMeters: number
  swathsVariant?: number
  robotWidthMeters: number
  mowPatternSkip: number
  overlapPercent: number
  swathsAngleRadians?: number
  coverPerimeters: boolean
}

export const PlanPathFieldParametersForm: FunctionComponent<{
  onValidSubmit?: SubmitHandler<PlanPathFieldParametersFormValues>
  onValidWatch?: (data: PlanPathFieldParametersFormValues) => void
  defaultValues: DefaultValues<PlanPathFieldParametersFormValues>
  className?: string
}> = ({ onValidSubmit, onValidWatch, defaultValues, className }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<PlanPathFieldParametersFormValues>({
    defaultValues: defaultValues,
  })

  const [isOptimizingSwathsAnglesState, setIsOptimizingSwathAngleState] = useState(
    defaultValues.swathsAngleRadians ? false : true
  )

  const swathsAngleRadians = watch('swathsAngleRadians')
  const watchedValues = watch()
  const watchedValuesRef = useRef<PlanPathFieldParametersFormValues | null>(null)
  const themeMode = useThemeMode()

  useEffect(() => {
    if (onValidWatch && JSON.stringify(watchedValues) !== JSON.stringify(watchedValuesRef.current)) {
      watchedValuesRef.current = watchedValues
      onValidWatch(watchedValues)
    }
  }, [watchedValues, onValidWatch])

  const onSubmit = (data: PlanPathFieldParametersFormValues) => {
    const parsedData = {
      ...data,
      coverageWidthMeters: parseFloat(data.coverageWidthMeters.toString()),
      turnRadiusMeters: parseFloat(data.turnRadiusMeters.toString()),
      robotWidthMeters: parseFloat(data.robotWidthMeters.toString()),
      mowPatternSkip: parseFloat(data.mowPatternSkip.toString()),
      overlapPercent: parseFloat(data.overlapPercent.toString()),
      swathsVariant: data.swathsVariant ? parseFloat(data.swathsVariant.toString()) : undefined,
      swathsAngleRadians: data.swathsAngleRadians ? parseFloat(data.swathsAngleRadians.toString()) : undefined,
    }

    if (onValidSubmit) {
      onValidSubmit(parsedData)
    }
  }

  return (
    <form className={`flex flex-col ${className}`} onSubmit={handleSubmit(onSubmit)}>
      <Label htmlFor='turnRadiusMeters' value='Turn Radius (meters)' />
      <TextInput
        id='turnRadiusMeters'
        type='number'
        step='0.1'
        placeholder='Turn Radius'
        {...register('turnRadiusMeters', { required: true, valueAsNumber: true })}
      />
      {errors.turnRadiusMeters && <p className='text-sm text-red-500'>This field is required.</p>}

      <Label htmlFor='overlapPercent' value='Overlap Percent between passes' />
      <TextInput
        id='overlapPercent'
        type='number'
        step='any'
        placeholder='Overlap Percent'
        {...register('overlapPercent', { required: true, valueAsNumber: true })}
      />
      {errors.overlapPercent && <p className='text-sm text-red-500'>This field is required.</p>}

      <Label htmlFor='swathsAngleRadians' value='Mow Line Direction' />
      <div className='flex items-center'>
        <Checkbox
          id='optimizeSwathAngleForMe'
          checked={isOptimizingSwathsAnglesState}
          onChange={() => {
            setIsOptimizingSwathAngleState(!isOptimizingSwathsAnglesState)
            setValue('swathsAngleRadians', isOptimizingSwathsAnglesState ? undefined : 0.0)
          }}
        />
        <Label htmlFor='optimizeSwathAngleForMe' className='ml-2'>
          Choose best direction for me
        </Label>
      </div>
      {!isOptimizingSwathsAnglesState && (
        <div className='flex items-center'>
          <CircularSlider
            width={130}
            min={0}
            max={360}
            direction={-1}
            knobPosition='right'
            appendToValue='°'
            valueFontSize='1rem'
            labelColor={themeMode.mode === 'dark' ? 'white' : 'black'}
            labelBottom={true}
            trackDraggable={true}
            useMouseAdditionalToTouch={true}
            dataIndex={((swathsAngleRadians ?? 0) * 360.0) / 6.28 || 0}
            onChange={(value: number) => setValue('swathsAngleRadians', (value / 360.0) * 6.28)}
          />
        </div>
      )}
      {errors.swathsAngleRadians && <p className='text-sm text-red-500'>This field is required.</p>}

      <Label htmlFor='coverPerimeters' value='Cover Perimeters' />
      <div className='flex items-center'>
        <Checkbox id='coverPerimeters' {...register('coverPerimeters')} />
      </div>

      <Label htmlFor='mowPatternSkip' value='Mow Pattern Skip' />
      <TextInput
        id='mowPatternSkip'
        type='number'
        step='1'
        min='1'
        max='7'
        placeholder='Mow Pattern Skip'
        {...register('mowPatternSkip', { required: true, valueAsNumber: true })}
      />
      {errors.mowPatternSkip && <p className='text-sm text-red-500'>This field is required.</p>}

      <Label htmlFor='robotWidthMeters' value='Robot Width (meters)' />
      <TextInput
        id='robotWidthMeters'
        type='number'
        step='any'
        placeholder='Robot Width'
        {...register('robotWidthMeters', { required: true, valueAsNumber: true })}
      />
      {errors.robotWidthMeters && <p className='text-sm text-red-500'>This field is required.</p>}

      <Label htmlFor='coverageWidthMeters' value='Coverage Width (meters)' />
      <TextInput
        id='coverageWidthMeters'
        type='number'
        step='any'
        placeholder='Coverage Width'
        {...register('coverageWidthMeters', { required: true, valueAsNumber: true })}
      />
      <p className='text-sm text-gray-500 dark:text-gray-400'>Or "Mower Width"</p>

      {errors.coverageWidthMeters && <p className='text-sm text-red-500'>This field is required.</p>}

      <Label htmlFor='swathsVariant' value='Swaths Variant (Experimental)' />
      <TextInput
        id='swathsVariant'
        type='number'
        step='1'
        min='0'
        max='3'
        placeholder='Swaths Variant'
        {...register('swathsVariant', { required: false, valueAsNumber: true })}
      />
      {errors.swathsVariant && <p className='text-sm text-red-500'>This field is required.</p>}

      {onValidSubmit && (
        <Button type='submit' size='sm'>
          Save
        </Button>
      )}
    </form>
  )
}
