import { withProfiler } from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { Flowbite } from 'flowbite-react'
import { RouterProvider } from 'react-router-dom'
import { AtlasApiProvider } from '../context/AtlasApiProvider'
import { GlobalStateProvider } from '../context/GlobalStateContext'
import { RoutesListProvider } from '../context/RoutesListContext'
import { SelectedUserProvider } from '../context/SelectedUserContext'
import { router } from '../router'
import { queryClient } from '../utils/queryClient'

const Atlas = () => {
  return (
    <GlobalStateProvider>
      <RoutesListProvider>
        <QueryClientProvider client={queryClient}>
          <AtlasApiProvider>
            <SelectedUserProvider>
              <Flowbite>
                <RouterProvider router={router} />
              </Flowbite>
            </SelectedUserProvider>
          </AtlasApiProvider>
        </QueryClientProvider>
      </RoutesListProvider>
    </GlobalStateProvider>
  )
}

export const App = withProfiler(Atlas)
