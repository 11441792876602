import { GeoJSON } from 'leaflet'
import { useEffect } from 'react'

import type { ExtFeatureGroup, ExtLayer, ExtPolyline } from '../../../types'
import type { LeafletContextInterfaceOrNull } from '../LeafletContextInterfaceOrNull'

import type { GeomanDrawProps } from './GeomanDraw'

export const useGeomanUpdateLayer = ({
  context,
  editedLayer,
  routeToDelete,
}: Pick<GeomanDrawProps, 'editedLayer' | 'routeToDelete'> & { context: LeafletContextInterfaceOrNull }) => {
  // update polyline coordinates when it's coordinates are changed programmatically
  useEffect(() => {
    if (editedLayer?.geojson) {
      try {
        const geojson = editedLayer.geojson
        const mapContextRef = context?.map
        if (mapContextRef?.pm) {
          const geomanLayers = mapContextRef?.pm?.getGeomanDrawLayers()
          geomanLayers.forEach((geomanLayer) => {
            const layer = geomanLayer as ExtLayer
            if (layer?._leaflet_id === editedLayer.layerId) {
              const lineGeojson = geojson.features.find((feature) => feature.geometry.type === 'LineString')
              const editedCoordinates = GeoJSON.coordsToLatLngs(
                lineGeojson?.geometry?.coordinates as [number, number][]
              )
              const line = layer as ExtPolyline
              line.setLatLngs(editedCoordinates)
              // geomanLayer.pm._initMarkers() // update markers in edit mode
            }
          })
        }
      } catch (e) {
        console.error(e)
      }
    }
  }, [editedLayer])

  useEffect(() => {
    if (Object.keys(routeToDelete ?? {})?.length) {
      const mapContextRef = context?.map
      const layerId = Object.values(routeToDelete ?? {})[0]
      let layerToDelete = null
      mapContextRef?.eachLayer((layer) => {
        const line = layer as ExtFeatureGroup
        if (line._leaflet_id === parseInt(layerId)) {
          layerToDelete = layer
        }
      })
      if (layerToDelete) {
        mapContextRef?.removeLayer(layerToDelete)
      }
    }
  }, [routeToDelete])
}
