import type { UniqueIdentifier } from '@dnd-kit/core/dist/types'
import type { Layer } from 'leaflet'
import { FeatureGroup, LatLng, Marker, Polygon, Polyline, latLngBounds } from 'leaflet'
import { ExtFeatureGroup, ExtPoi, ExtPolygon, GeoJSONLayer } from '../../../types'
import { leaflet_areBoundsValid } from '../../../utils/leaflet_areBoundsValid'
import { LeafletContextInterface } from '../../RoutesMap/LeafletContextInterfaceOrNull'

export function updateMapFocusToSelectedShapes({
  map,
  selectedShapeIds,
}: {
  map: LeafletContextInterface['map']
  selectedShapeIds: UniqueIdentifier[]
}) {
  let newFocusLatLngs: LatLng[] = []
  map?.eachLayer((layer: Layer) => {
    const layerWithOptions = layer as ExtFeatureGroup
    const layerRouteId = layerWithOptions?.options?.routeId
    if (selectedShapeIds.some((shapeId) => shapeId === layerRouteId) && layerWithOptions instanceof FeatureGroup) {
      layerWithOptions.eachLayer((featureLayer) => {
        if (featureLayer instanceof Polyline) {
          newFocusLatLngs = [...newFocusLatLngs, ...(featureLayer.getLatLngs() as LatLng[])]
        }
        if (featureLayer instanceof Marker) {
          newFocusLatLngs.push(featureLayer.getLatLng())
        }
      })
    } else if (
      selectedShapeIds.some((shapeId) => {
        const layerPolygonId = (layer as ExtPolygon)?.options?.polygonId
        return shapeId === layerPolygonId
      })
    ) {
      if (layer instanceof Polygon) {
        newFocusLatLngs = [...newFocusLatLngs, ...(layer.getLatLngs() as LatLng[])]
      }
    } else if (
      selectedShapeIds.some((shapeId) => {
        const layerPoiId = (layer as ExtPoi)?.options?.poiId
        return shapeId === layerPoiId
      })
    ) {
      if ((layer as GeoJSONLayer).feature) {
        const layerLatLngs = (layer as Marker).getLatLng()
        newFocusLatLngs.push(layerLatLngs)
      }
    }
  })
  if (newFocusLatLngs?.length > 0) {
    const latlngBounds = latLngBounds(newFocusLatLngs)
    if (leaflet_areBoundsValid(latlngBounds)) {
      if (newFocusLatLngs.length === 1 && newFocusLatLngs[0] instanceof LatLng) {
        map.setView(newFocusLatLngs[0], 21)
      } else {
        map.fitBounds(latlngBounds)
      }
    }
  }
}
