import { captureException } from '@sentry/react'
import { Alert, Button, Card } from 'flowbite-react'
import type { ErrorInfo, ReactNode } from 'react'
import { Component } from 'react'
import { HiInformationCircle } from 'react-icons/hi'
import { authConfig } from '../utils/auth'

interface AppErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

interface AppErrorBoundaryProps {
  children: ReactNode
}

export class AppErrorBoundary extends Component<AppErrorBoundaryProps, AppErrorBoundaryState> {
  constructor(props: AppErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error: Error): AppErrorBoundaryState {
    return { hasError: true, error }
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
    captureException(error, { extra: { errorInfo: JSON.stringify(errorInfo) } })
  }

  handleRefreshClick = () => {
    window.location.reload()
  }

  handleLogoutClick = async () => {
    await authConfig.logoutFn()
  }

  handleDeleteStorageClick = () => {
    window.localStorage.clear()
    window.sessionStorage.clear()
    window.location.reload()
  }

  override render() {
    if (this.state.hasError) {
      return (
        <div className='p-2 max-w-xl'>
          <Card className='mx-auto '>
            <h1 className='text-4xl font-extrabold leading-none tracking-tight md:text-5xl lg:text-6xl text-gray-900 dark:text-gray-100'>
              Oops!
            </h1>
            <h3 className='text-3xl font-bold text-gray-800 dark:text-gray-200'>Something has gone wrong.</h3>
            <p className='text-gray-700 dark:text-gray-300'>
              Try the below solutions and/or share the details below with the developer. If logging out doesn't fix your
              problem, deleting storage almost certainly will.
            </p>
            <div className='flex gap-2'>
              <Button className='' onClick={this.handleRefreshClick}>
                Refresh
              </Button>
              <Button color='gray' className='' onClick={this.handleLogoutClick}>
                Log out
              </Button>
              <Button color='failure' onClick={this.handleDeleteStorageClick}>
                Clear Cache
              </Button>
            </div>
          </Card>
          {this.state.error && (
            <Alert
              color='failure'
              className='mt-4'
              icon={HiInformationCircle}
              additionalContent={
                <div>
                  <details className='mt-2'>
                    <summary>Click for more details</summary>
                    <pre className='text-left whitespace-pre-wrap'>{this.state.error.stack}</pre>
                  </details>
                </div>
              }
            >
              <span className='block font-medium'>Error: {this.state.error.message}</span>
            </Alert>
          )}
        </div>
      )
    }

    return this.props.children
  }
}
