import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { DefaultApiDestinationMapDeleteRequest } from 'burro-web-api-js-client'
import { useContext } from 'react'
import { useAtlasApi } from '../../context/AtlasApiProvider'
import { SelectedUserContext } from '../../context/SelectedUserContext'
import type { User } from '../../utils/auth'
import { useUser } from '../../utils/auth'
import { getDestinationMapsGetQueryKey } from '../queries/useDestinationMapsGetQuery'

export const useDestinationMapDeleteMutation = () => {
  const api = useAtlasApi()
  const queryClient = useQueryClient()

  const { selectedUser } = useContext(SelectedUserContext)
  const { data: userData }: { data: User | null } = useUser()
  const customerId = selectedUser?.value ? selectedUser.value : userData?.customerTag

  return useMutation({
    mutationKey: ['destinationMapsDestinationMapUidDelete'],
    mutationFn: async (requestParams: DefaultApiDestinationMapDeleteRequest) => {
      const response = await api.destinationMapDelete(requestParams)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getDestinationMapsGetQueryKey({ customerId }) })
    },
  })
}
