import { Button, ListGroup, TextInput } from 'flowbite-react'
import { Close } from 'flowbite-react-icons/outline'
import { FunctionComponent, useContext, useState } from 'react'
import { BsExclude } from 'react-icons/bs'
import { FaX } from 'react-icons/fa6'
import { PiPolygonBold } from 'react-icons/pi'
import { usePolygonDeleteMutation } from '../../hooks/mutations/usePolygonDeleteMutation'
import { AtlasAvatar } from '../ui/AtlasAvatar'
import { AtlasNavigationDropdown } from '../ui/AtlasNavigationDropdown'
import { ConfirmationModal } from '../ui/modals/ConfirmationModal'
import { PanelDrawerLayoutMode } from '../ui/PanelDrawerLayout/PanelDrawerLayout'
import { PolygonContext } from './PolygonContext'

export const PolygonsPagePanelDrawerLeft: FunctionComponent<{ className?: string }> = ({ className }) => {
  const {
    setIsLeftOpen,
    polygons,
    selectedPolygonId,
    setSelectedPolygonId,
    setExcludedPolygonIds,
    excludedPolygonIds,
    addExcludedPolygonId,
    removeExcludedPolygonId,
    setPlannedPath,
    mode,
  } = useContext(PolygonContext)
  const [searchTermState, setSearchTermState] = useState('')
  const [deleteConfirmationIdState, setDeleteConfirmationIdState] = useState<string | null>(null)
  const polygonDeleteMutation = usePolygonDeleteMutation()
  return (
    <>
      <div className={className}>
        <div className='flex items-center justify-between'>
          <div className='flex-1'>
            <AtlasAvatar className='pr-2' />
          </div>
          <div className='flex-1 flex justify-center'>
            <AtlasNavigationDropdown />
          </div>
          <div className='flex-1 flex justify-end'>
            <Button
              onClick={() => setIsLeftOpen(false)}
              size='sm'
              color='gray'
              className={`border-0 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white ${mode === PanelDrawerLayoutMode.DRAWER ? 'visible' : 'invisible'}`}
            >
              <Close size={14} />
            </Button>
          </div>
        </div>
        <div className='flex items-center justify-between'>
          <TextInput
            placeholder='Search'
            className='w-full'
            onChange={(e) => {
              setSearchTermState(e.target.value)
            }}
          />
        </div>
        <ListGroup className='flex-grow overflow-auto'>
          {polygons
            ?.filter((value) => value.polygon_name?.toLowerCase().includes(searchTermState.toLowerCase()))
            ?.map((value) => {
              const isSelected = selectedPolygonId === value.polygon_id

              return (
                <ListGroup.Item key={value.polygon_id} active={isSelected}>
                  <div className='flex-shrink pr-3'>
                    <Button
                      size='sm'
                      color={isSelected ? 'success' : 'light'}
                      onClick={() => {
                        setPlannedPath(null)
                        if (value.polygon_id === selectedPolygonId) {
                          setSelectedPolygonId(null)
                        } else {
                          setSelectedPolygonId(value.polygon_id!)
                        }
                        setExcludedPolygonIds(null)
                        if (mode === PanelDrawerLayoutMode.DRAWER) {
                          setIsLeftOpen(false)
                        }
                      }}
                    >
                      <PiPolygonBold />
                    </Button>
                  </div>
                  <div className='flex-grow text-left'>
                    <p className='font-medium cursor-text'>{value.polygon_name ?? 'NA'}</p>
                    <p className='text-sm font-normal italic cursor-text'>{value.customer ?? 'NA'}</p>
                  </div>
                  {selectedPolygonId != null && !isSelected && (
                    <div className='flex-shrink'>
                      <Button
                        size='sm'
                        color={excludedPolygonIds?.includes(value.polygon_id!) ? 'failure' : 'light'}
                        onClick={() => {
                          if (excludedPolygonIds?.includes(value.polygon_id!)) {
                            removeExcludedPolygonId(value.polygon_id!)
                          } else {
                            addExcludedPolygonId(value.polygon_id!)
                          }
                        }}
                      >
                        <BsExclude />
                      </Button>
                    </div>
                  )}
                  {isSelected && (
                    <div className='flex-shrink'>
                      <Button
                        size='sm'
                        color='yellow'
                        onClick={async () => {
                          if (!value.polygon_id) {
                            return
                          }
                          setDeleteConfirmationIdState(value.polygon_id)
                        }}
                      >
                        <FaX />
                      </Button>
                    </div>
                  )}
                </ListGroup.Item>
              )
            })}
        </ListGroup>
      </div>
      <ConfirmationModal
        headerText={`Delete?`}
        bodyText={`Are you sure you want to delete this polygon?`}
        show={deleteConfirmationIdState !== null}
        onClose={() => setDeleteConfirmationIdState(null)}
        onConfirm={async () => {
          if (!selectedPolygonId) {
            return
          }
          const value = polygons?.find((value) => value.polygon_id === selectedPolygonId)!
          await polygonDeleteMutation.mutateAsync({ polygonId: value.polygon_id! })
          setSelectedPolygonId(null)
          setExcludedPolygonIds(null)
        }}
      />
    </>
  )
}
