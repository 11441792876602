import { geoJSON, marker, Polyline } from 'leaflet'
import { useEffect } from 'react'

import { Feature, LineString } from '@turf/turf'
import type { ExtendedMarkerOptions, ExtFeatureGroup, ExtPolyline, StationMarker } from '../../../types'
import { leaflet_mapFitFoundsToEachLayer } from '../../../utils/leaflet_mapFitFoundsToEachLayer'
import type { SortableRoute } from '../../ui/ShapesTree/SendToRobotModal'
import { DEFAULT_PATH_COLOR } from '../../ui/ShapesTree/ShapesTree'
import { StationIcon } from '../GeomanDraw/GeomanDraw'
import { getStationLayerMetadataFromLineCoords } from '../GeomanDraw/stationReposition'
import { type LeafletContextInterfaceOrNull } from '../LeafletContextInterfaceOrNull'

export function usePreviewRoutes({
  context,
  previewRoutes,
  doFocusMap,
}: {
  context: LeafletContextInterfaceOrNull
  previewRoutes: SortableRoute[]
  doFocusMap: boolean
}) {
  useEffect(() => {
    if (doFocusMap && context?.map) {
      leaflet_mapFitFoundsToEachLayer(context.map, false)
    }
  }, [doFocusMap, context?.map])

  useEffect(() => {
    if (context?.map && previewRoutes?.length > 0) {
      context.map?.eachLayer((layer) => {
        const layerWithOptions = layer as ExtFeatureGroup
        if (layerWithOptions?.options?.routeId?.length && layerWithOptions?.options?.routeName?.length) {
          context?.map.removeLayer(layer)
        }
      })

      const filteredPreviewRoutes = previewRoutes.filter((route) => !route.isEmptySlot)
      filteredPreviewRoutes.forEach((route) => {
        const geojson = route.geojson
        if (geojson) {
          const geoJsonLayer = geoJSON<LineString>(geojson, {
            pointToLayer: function (_feature, latlng) {
              const lineFeature: LineString | undefined = geojson.features.find((feature: Feature) => {
                return feature.geometry.type === 'LineString'
              })?.geometry as LineString
              let markerStationMeasures = null
              if (lineFeature) {
                const lineCoords = lineFeature.coordinates
                markerStationMeasures = getStationLayerMetadataFromLineCoords(lineCoords, [latlng.lng, latlng.lat])
              }
              const stationMarker = marker(latlng, {
                icon: StationIcon,
                stationMeasures: {
                  ...markerStationMeasures,
                },
              } as ExtendedMarkerOptions) as StationMarker
              return stationMarker
            },
            onEachFeature: function (_feature, layer) {
              if (layer instanceof Polyline) {
                const lineString = layer as ExtPolyline
                lineString.bindTooltip(route.idx.toLocaleString(), {
                  permanent: true,
                  className: 'route-sequence-number',
                })
              }
            },
            style: {
              color: DEFAULT_PATH_COLOR,
            },

            // @ts-ignore
            routeId: route.id,
            routeName: route.name,
          })
          geoJsonLayer.addTo(context.map)
        }
      })
      leaflet_mapFitFoundsToEachLayer(context.map, false)
    }
  }, [context?.map, previewRoutes])
}
