import { createContext } from 'react'

import { Position } from '@turf/turf'
import type { DestinationMapInterface } from 'burro-map-utils'
import { PanelDrawerLayoutMode } from '../ui/PanelDrawerLayout/PanelDrawerLayout'

interface DestinationMapsContextProps {
  addDestinationMap: (name: string, destinationMap?: DestinationMapInterface) => Promise<void>
  destinationMapRenderState: DestinationMapInterface | null
  futureEditCount: number
  historyIndex: number | undefined
  isLeftOpen: boolean
  isRightOpen: boolean
  jumpToPointInHistory: (point: number) => void
  mode: PanelDrawerLayoutMode
  navigatingFromPosition: Position | null
  navigatingToPosition: Position | null
  pastEditCount: number
  redo: () => void
  removeDestinationMapAtIndex: (index: number) => Promise<void>
  renameDestinationMapAtIndex: (index: number, name: string) => Promise<void>
  selectedDestinationMap: DestinationMapInterface | null
  selectedDestinationMapIndex: number | null
  selectedDestinationMapUid: string | null
  selectedFeatureIds: string[]
  setDestinationMapRenderState: (index: DestinationMapInterface | null) => void
  setIsLeftOpen: (isOpen: boolean) => void
  setIsRightOpen: (isOpen: boolean) => void
  setNavigatingFromPosition: (position: Position | null) => void
  setNavigatingToPosition: (position: Position | null) => void
  setSelectedDestinationMapIndex: (index: number | null) => void
  setSelectedFeatureId: (id: string[]) => void
  undo: () => void
  updateDestinationMapAtIndex: (index: number, destinationMap: DestinationMapInterface, name?: string) => Promise<void>
}

export const DestinationMapsContext = createContext<DestinationMapsContextProps>({
  addDestinationMap: function (_name: string): Promise<void> {
    throw new Error('Function not implemented.')
  },
  destinationMapRenderState: null,
  futureEditCount: 0,
  historyIndex: 0,
  isLeftOpen: false,
  isRightOpen: false,
  jumpToPointInHistory: function (_point: number): void {
    throw new Error('Function not implemented.')
  },
  mode: PanelDrawerLayoutMode.DRAWER,
  navigatingFromPosition: null,
  navigatingToPosition: null,
  pastEditCount: 0,
  redo: function (): void {
    throw new Error('Function not implemented.')
  },
  removeDestinationMapAtIndex: function (_index: number): Promise<void> {
    throw new Error('Function not implemented.')
  },
  renameDestinationMapAtIndex: function (_index: number, _name: string): Promise<void> {
    throw new Error('Function not implemented.')
  },
  selectedDestinationMap: null,
  selectedDestinationMapIndex: null,
  selectedDestinationMapUid: null,
  selectedFeatureIds: [],
  setDestinationMapRenderState: (_index: DestinationMapInterface | null) => {},
  setIsLeftOpen: (_isOpen: boolean) => {},
  setIsRightOpen: (_isOpen: boolean) => {},
  setSelectedDestinationMapIndex: () => {},
  setSelectedFeatureId: (_id: string[]) => {},
  setNavigatingFromPosition: (_position: Position | null) => {},
  setNavigatingToPosition: (_position: Position | null) => {},
  undo: function (): void {
    throw new Error('Function not implemented.')
  },
  updateDestinationMapAtIndex: function (_index: number, _destinationMap: DestinationMapInterface): Promise<void> {
    throw new Error('Function not implemented.')
  },
})
