import type { UniqueIdentifier } from '@dnd-kit/core'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { area } from '@turf/turf'
import type { ResponseError, ResponseSuccess } from 'api-types'
import { FeatureCollection } from 'burro-web-api-js-client'
import { Checkbox, Spinner, Table } from 'flowbite-react'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { FaInfoCircle } from 'react-icons/fa'
import { FaDownload, FaRegCopy } from 'react-icons/fa6'
import { PiPathBold } from 'react-icons/pi'
import polygonIcon from '../../../../assets/polygon_icon.svg'
import { API_URLS, GET_FOLDERS_KEY } from '../../../../constants'
import { usePlanPathFieldMutation } from '../../../../hooks/mutations/usePlanPathFieldPostMutation'
import { TreePolygon } from '../../../../types/TreePolygon'
import { apiDelete } from '../../../../utils/api'
import { CustomDropdown, CustomDropdownMenuItem } from '../../CustomDropdown'
import { AlertModal } from '../../modals/AlertModal'
import { ConfirmationModal } from '../../modals/ConfirmationModal'

export function PolygonItem({
  polygon,
  isSelected,
  onToggleSelection,
  onSelectTreeItem,
  onSetEditablePolygon,
  onClickCopy,
  onClickDownload,
}: {
  onSelectTreeItem: (routeId: string) => void
  onSetEditablePolygon: (routeId: string) => void
  isSelected: boolean
  polygon: TreePolygon
  onToggleSelection: (id: UniqueIdentifier, isChecked: boolean) => void
  onClickCopy: () => void
  onClickDownload: () => void
}) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false)
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)
  const queryClient = useQueryClient()

  const mutationDeletePolygon = useMutation({
    mutationFn: async () => {
      await apiDelete<ResponseSuccess>(API_URLS.polygons.removePolygon(polygon.id))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      onSetEditablePolygon('')
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
  })

  const planPathFieldMutation = usePlanPathFieldMutation()

  useEffect(() => {
    if (isDeleteConfirmed) {
      mutationDeletePolygon.mutate()
    }
  }, [isDeleteConfirmed])

  const isLoading = mutationDeletePolygon.isPending

  const polygonSquareMeters = area(polygon.geojson)
  const hectares = polygonSquareMeters * 0.0001
  const squareKilometers = polygonSquareMeters * 1e-6
  const squareYards = polygonSquareMeters * 1.19599
  const squareInches = polygonSquareMeters * 1550.0031

  return (
    <div className='flex flex-1 p-2 sm:p-1'>
      <div
        className={`flex flex-1 cursor-pointer rounded-sm ${isSelected ? 'bg-blue-200' : ''} ${
          isLoading ? 'opacity-20' : ''
        }`}
        onClick={(event) => {
          const isCmdOrCtrlPressed = event.metaKey || event.ctrlKey
          if (isCmdOrCtrlPressed) {
            onToggleSelection(polygon.id, !isSelected)
          } else {
            onSelectTreeItem(polygon.id)
          }
        }}
      >
        {isLoading && <Spinner />}
        <div className='flex flex-1 items-center justify-between'>
          <div className='flex items-center'>
            <Checkbox
              className='ml-1 mr-2 text-blue-400 !outline-blue-400 !ring-blue-400'
              title='add to selection'
              onClick={(e) => {
                e.stopPropagation()
              }}
              onChange={(e) => {
                const isChecked = e.target.checked
                onToggleSelection(polygon.id, isChecked)
              }}
              checked={isSelected}
            />
            <div
              className={`relative ml-1 flex h-7 w-7 items-center justify-center rounded-full ${
                isSelected ? 'bg-white' : 'bg-slate-100'
              }`}
            >
              <img src={polygonIcon} className='my-auto h-4 w-4' alt='route icon' />
            </div>
            <span
              className={`flex flex-1 border border-transparent p-1 text-sm text-gray-900 dark:text-white whitespace-nowrap w-60 overflow-hidden`}
            >
              {polygon.name}
            </span>
          </div>
          <div className='flex shrink-0 items-center'>
            <CustomDropdown
              label='edit route'
              TriggerComponent={
                <button
                  type='button'
                  className={`flex h-8 w-8 shrink-0 items-center justify-center rounded border border-solid border-transparent bg-transparent px-1 py-1 text-sm font-normal text-stone-700 hover:border-solid hover:border-blue-500 hover:bg-white hover:text-blue-500 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200`}
                  title='Edit route'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='h-6 w-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z'
                    />
                  </svg>
                </button>
              }
            >
              <CustomDropdownMenuItem
                label='Copy GeoJSON'
                onClick={onClickCopy}
                iconComponent={<FaRegCopy size={22} className='p-0.5' />}
              >
                Copy GeoJSON
              </CustomDropdownMenuItem>
              <CustomDropdownMenuItem
                label='Download GeoJSON'
                onClick={onClickDownload}
                iconComponent={<FaDownload size={22} className='p-0.5' />}
              >
                Download GeoJSON
              </CustomDropdownMenuItem>
              <CustomDropdownMenuItem
                label='Debug Paths'
                onClick={async () => {
                  try {
                    const featureCollection: FeatureCollection = {
                      type: 'FeatureCollection',
                      features: [
                        {
                          ...polygon.geojson,
                          properties: {
                            type: 'coverage_area',
                          },
                        } as any,
                      ],
                    }
                    const planPathField = await planPathFieldMutation.mutateAsync({
                      featureCollection,
                      coverageWidthMeters: '0.9144',
                      mowPatternSkip: '4',
                      overlapPercent: '0.2',
                      robotWidthMeters: '0.94615',
                      turnRadiusMeters: '2.2',
                      coverPerimeters: 'false',
                    })
                    const file = new Blob([JSON.stringify(planPathField)], {
                      type: 'application/json',
                    })
                    const element = document.createElement('a')
                    element.href = URL.createObjectURL(file)
                    element.download = `Path_${new Date().toISOString()}.geojson`
                    document.body.appendChild(element)
                    element.click()
                    document.body.removeChild(element)
                    toast.success('Downloaded')
                  } catch (error) {
                    toast.error(JSON.stringify(error))
                  }
                }}
                iconComponent={<PiPathBold size={22} className='p-0.5' />}
              >
                Debug Paths
              </CustomDropdownMenuItem>
              <CustomDropdownMenuItem
                label='Options'
                onClick={(e) => {
                  e.stopPropagation()
                  onSetEditablePolygon(polygon.id)
                }}
                iconComponent={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='h-4 w-4'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                    />
                  </svg>
                }
              >
                Options
              </CustomDropdownMenuItem>
              <CustomDropdownMenuItem
                label='Delete'
                onClick={(e) => {
                  e.stopPropagation()
                  setIsDeleteModalVisible(true)
                }}
                iconComponent={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='h-4 w-4'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                    />
                  </svg>
                }
              >
                Delete
              </CustomDropdownMenuItem>
              <CustomDropdownMenuItem
                label='Info'
                onClick={() => setIsInfoModalVisible(true)}
                iconComponent={<FaInfoCircle size={22} className='p-0.5' />}
              >
                Info
              </CustomDropdownMenuItem>
            </CustomDropdown>
          </div>
        </div>
      </div>
      <ConfirmationModal
        bodyText={`Are you sure you want to delete polygon "${polygon.name}"?`}
        headerText='Delete Polygon?'
        show={isDeleteModalVisible}
        onClose={() => setIsDeleteModalVisible(false)}
        onConfirm={setIsDeleteConfirmed}
        className=''
      />
      <AlertModal
        onClose={() => setIsInfoModalVisible(false)}
        show={isInfoModalVisible}
        headerText={polygon.name}
        className=''
      >
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                m<sup>2</sup>
              </Table.Cell>
              <Table.Cell>{polygonSquareMeters.toFixed(2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Acres</Table.Cell>
              <Table.Cell>{(polygonSquareMeters * 0.000247105).toFixed(2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                mi<sup>2</sup>
              </Table.Cell>
              <Table.Cell>{(polygonSquareMeters * 3.86102e-7).toFixed(2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                ft<sup>2</sup>
              </Table.Cell>
              <Table.Cell>{(polygonSquareMeters * 10.7639).toFixed(2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Hectares</Table.Cell>
              <Table.Cell>{hectares.toFixed(2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                km<sup>2</sup>
              </Table.Cell>
              <Table.Cell>{squareKilometers.toFixed(6)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                yd<sup>2</sup>
              </Table.Cell>
              <Table.Cell>{squareYards.toFixed(2)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                in<sup>2</sup>
              </Table.Cell>
              <Table.Cell>{squareInches.toFixed(2)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </AlertModal>
    </div>
  )
}
