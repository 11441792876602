import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ResponseError, ResponseSuccess } from 'api-types'
import { EditRouteBodyParams } from 'modules/routes/types'
import { API_URLS } from '../../constants'
import { RoutesFeatureCollection } from '../../types/RoutesFeaturedCollection'
import { apiPut } from '../../utils/api'

export const useRouteEditMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      routeId,
      geojson,
      routeName,
    }: {
      routeId: string
      geojson: RoutesFeatureCollection
      routeName: string
    }) => {
      await apiPut<EditRouteBodyParams, ResponseSuccess>(API_URLS.routes.editRoute, {
        guid: routeId,
        geojsonString: JSON.stringify(geojson),
        name: routeName,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
    onError: (errorResponse: ResponseError) => {
      console.error(errorResponse.error)
    },
  })
}
