import type { ModalProps } from 'flowbite-react'
import { Button, Modal } from 'flowbite-react'
import { FunctionComponent, useState } from 'react'
import { AutocompleteDropdown } from '../AutocompleteDropdown'

type AutocompleteDropdownModalProps = ModalProps & {
  items: string[]
  onSelection: (item: string) => void
  headerText?: string
  labelText?: string
  placeholder?: string
  cancelText?: string
  selectText?: string
}

export const AutocompleteDropdownModal: FunctionComponent<AutocompleteDropdownModalProps> = ({
  items,
  onSelection,
  headerText,
  labelText,
  placeholder,
  cancelText,
  selectText,
  ...modalProps
}) => {
  const [changedItemState, setChangedItemState] = useState('')
  return (
    <Modal {...modalProps}>
      {headerText && <Modal.Header>{headerText}</Modal.Header>}
      <Modal.Body>
        <AutocompleteDropdown
          items={items}
          onSelection={(value) => {
            onSelection(value)
            if (modalProps.onClose) modalProps.onClose()
          }}
          labelText={labelText}
          placeholder={placeholder}
          onChange={(value) => setChangedItemState(value)}
        />
      </Modal.Body>
      <Modal.Footer className='flex justify-end'>
        <Button color='failure' onClick={modalProps.onClose}>
          {cancelText || 'Cancel'}
        </Button>
        <Button onClick={() => onSelection(changedItemState)}>{selectText || 'Select'}</Button>
      </Modal.Footer>
    </Modal>
  )
}
