import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'

import { useAtlasApi } from '../../context/AtlasApiProvider'
import { SelectedUserContext } from '../../context/SelectedUserContext'
import type { User } from '../../utils/auth'
import { useUser } from '../../utils/auth'

export type DestinationMapGetResponseType = {
  name: string
  uid: string
}

export const polygonsGetQueryKey = (params: { customerId?: string }) => ['polygonsGetQueryKey', params.customerId]

export const usePolygonsGetQuery = ({ enabled }: { enabled?: boolean }) => {
  const api = useAtlasApi()
  const { selectedUser } = useContext(SelectedUserContext)
  const { data: userData }: { data: User | null } = useUser()
  const customerId = selectedUser?.value ? selectedUser.value : userData?.customerTag

  return useQuery({
    queryKey: polygonsGetQueryKey({ customerId }),
    queryFn: async () => {
      if (!customerId) {
        return
      }

      const response = await api.polygonsGet({ customerId: customerId })
      return response.data
    },
    enabled: !!customerId && enabled,
  })
}
