import { useQuery } from '@tanstack/react-query'
import type { DefaultApiDestinationMapGetRequest } from 'burro-web-api-js-client'
import { useContext } from 'react'
import { useAtlasApi } from '../../context/AtlasApiProvider'
import { SelectedUserContext } from '../../context/SelectedUserContext'
import type { User } from '../../utils/auth'
import { useUser } from '../../utils/auth'

export type DestinationMapGetResponseType = {
  name: string
  uid: string
}

export const getDestinationMapGetQueryKey = (params: { destinationMapUid?: string }) => [
  'destinationMapsDestinationMapUidGet',
  params.destinationMapUid ?? '',
]

export const useDestinationMapGetQuery = (params: { destinationMapUid?: string }) => {
  const api = useAtlasApi()
  const { selectedUser } = useContext(SelectedUserContext)
  const { data: userData }: { data: User | null } = useUser()
  const customerId = selectedUser?.value ? selectedUser.value : userData?.customerTag

  const enabled = customerId !== undefined && params.destinationMapUid !== undefined

  const query = useQuery({
    queryKey: getDestinationMapGetQueryKey(params),
    queryFn: async () => {
      if (!customerId) {
        return
      }

      const response = await api.destinationMapGet(params as DefaultApiDestinationMapGetRequest)
      return response.data
    },
    enabled,
  })

  return query
}
