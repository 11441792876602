import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Feature, MultiPolygon, Point, Polygon } from '@turf/turf'
import type { ResponseError, ResponseSuccess } from 'api-types'
import type { UpdatePointBodyParams } from 'modules/points/types'
import type { UpdatePolygonBodyParams } from 'modules/polygons/types'
import type { EditRouteBodyParams } from 'modules/routes/types'
import type { Dispatch, SetStateAction } from 'react'
import type { TreeItems } from '../components/ui/ShapesTree/types'
import { findItemDeep } from '../components/ui/ShapesTree/utilities'
import { API_URLS, GET_FOLDERS_KEY } from '../constants'
import type { RoutesFeatureCollection } from '../types/RoutesFeaturedCollection'
import type { TreeFolderOrShape } from '../types/TreeFolderOrShape'
import type { TreeRoute } from '../types/TreeRoute'
import { apiPost, apiPut } from '../utils/api'

export function useEditRouteMutation({
  tree,
  resetEditActiveState,
  setRoutesOperationError,
}: {
  tree: TreeItems<TreeFolderOrShape>
  setRoutesOperationError: Dispatch<SetStateAction<string>>
  resetEditActiveState: VoidFunction
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      routeId,
      geojson,
      routeName,
    }: {
      routeId: string
      geojson: RoutesFeatureCollection
      routeName: string
    }) => {
      const foundRoute = findItemDeep(tree, routeId) as TreeRoute
      await apiPut<EditRouteBodyParams, ResponseSuccess>(API_URLS.routes.editRoute, {
        guid: routeId,
        geojsonString: JSON.stringify(geojson),
        name: routeName,
        ...(foundRoute?.geojson?.metadata ? { metadata: foundRoute.geojson.metadata } : {}),
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      resetEditActiveState()
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}

export function useEditPolygonMutation({
  setRoutesOperationError,
  resetEditActiveState,
}: {
  setRoutesOperationError: Dispatch<SetStateAction<string>>
  resetEditActiveState: VoidFunction
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      polygonId,
      geojson,
      polygonName,
    }: {
      polygonId: string
      geojson: Feature<Polygon | MultiPolygon>
      polygonName: string
    }) => {
      await apiPost<UpdatePolygonBodyParams, ResponseSuccess>(API_URLS.polygons.editPolygon, {
        polygonId,
        geojson: JSON.stringify(geojson),
        polygonName,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      resetEditActiveState()
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}

export function useEditPoiMutation({
  setRoutesOperationError,
  resetEditActiveState,
}: {
  setRoutesOperationError: Dispatch<SetStateAction<string>>
  resetEditActiveState: VoidFunction
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ poiId, geojson, poiName }: { poiId: string; geojson: Feature<Point>; poiName: string }) => {
      await apiPost<UpdatePointBodyParams, ResponseSuccess>(API_URLS.poi.editPoi, {
        pointId: poiId,
        geojson: JSON.stringify(geojson),
        pointName: poiName,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      resetEditActiveState()
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}
