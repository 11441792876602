export const COLOR_OPTIONS = [
  '#00FF00',
  '#FF0000',
  '#00FFFF',
  '#FF00FF',
  '#0000FF',
  '#FFA500',
  '#FFFF00',
  '#BF40BF',
  undefined,
]

export const UNDEFINED_COLOR_OPTION_INDEX = 8

export const SNAP_PRECISION_METERS = 1.0
