export const GET_FOLDERS_KEY = 'getAllFolders'

export const STORAGE_FOLDER_TREE_KEY = 'burro-app:folderTree'

export const MPS_TO_MPH_CONVERSION_FACTOR = 2.23694

export const BURRO_CUSTOMER_TAGS = [
  'agri-automation-au',
  'agri-automation-nz',
  'agrott',
  'agrovision',
  'Altman',
  'bassis',
  'bayer',
  'bravante',
  'burchell',
  'burro-uat',
  'burro-internal',
  'camflor',
  'city-roots',
  'corteva',
  'costa',
  'demo_ca',
  'fieldwork',
  'florsani',
  'fruitometry',
  'green-max',
  'guardian',
  'haggerty-creek',
  'hmc',
  'illume',
  'innov8',
  'kawahara',
  'lagomarsino',
  'landbouw-ranches',
  'mars',
  'migiva',
  'monrovia',
  'monterey-pacific',
  'msk',
  'native-west',
  'neosho-gardens',
  'netgear',
  'northland',
  'npc-solar',
  'par',
  'peterson-family',
  'prides-corner',
  'production',
  'rpcs',
  'sierra-gold',
  'southwood',
  'spring-meadow',
  'sunbelt',
  'sunview',
  'taglermaq',
  'tecnoloc',
  'thanksgiving-point',
  'usdaHawaii',
  'wenke',
  'werehaeuser',
  'withcott',
  'wonderful',
  'zirkle',
]

export const NAV_ITEMS = [
  { href: '/', title: 'ROUTES' },
  { href: '/robots', title: 'ROBOTS' },
  { href: '/destination-maps', title: 'DESTINATION MAPS' },
]

export const API_URLS = {
  auth: {
    login: 'auth/login',
    logout: 'auth/logout',
    users: 'users',
    messageWasRead: 'message-read',
    message: 'message',
  },
  folders: {
    getFolders: 'folders',
    moveFolder: 'folders/move',
    bulkUpload: 'atlas/items',
    createFolder: 'folders/create',
    renameFolder: 'folders/rename',
    deleteFolder: (id: string) => `folders/delete/${id}`,
  },
  routes: {
    getRoutes: 'routes',
    deleteRoute: 'routes/delete',
    addRoute: 'routes/add',
    editRoute: 'routes/edit',
    moveRoute: 'routes/move',
    sendToRobot: 'routes/send-to-robot',
    restoreRoute: (routeId: string) => `routes/restore/${routeId}`,
  },
  polygons: {
    addPolygon: 'polygons/create',
    editPolygon: 'polygons/update',
    movePolygon: (polygonId: string) => `polygons/move/${polygonId}`,
    removePolygon: (polygonId: string) => `polygons/delete/${polygonId}`,
  },
  poi: {
    addPoi: 'points/create',
    editPoi: 'points/update',
    movePoi: (poiId: string) => `points/move/${poiId}`,
    removePoi: (poiId: string) => `points/delete/${poiId}`,
  },
  items: {
    moveItems: 'items/move',
    deleteItems: 'items/delete',
  },
  tileFolders: {
    getTileFolders: 'tile-folders',
  },
  robots: {
    getRobots: 'devices',
    robotsCredentials: (robotNum: string) => `devices/${robotNum}/ntrip_credentials`,
    robotsSchedule: (robotNum: string) => `devices/${robotNum}/schedule`,
  },
} as const

export const ROUTE_PATHS = {
  ROOT: '/',
  DESTINATION_MAPS: '/destination-maps',
  ROBOTS: '/robots',
  LOGIN: '/login',
  MOW: '/mow',
} as const

export const PAGE_TITLES = {
  ROOT: 'Routes',
  DESTINATION_MAPS: 'Destinations',
  ROBOTS: 'Robots',
  LOGIN: 'Login',
  MOW: 'Mowing',
} as const

export function getPageTitleForRoutePath(routePath: string): string {
  const routeKey = (Object.keys(ROUTE_PATHS) as Array<keyof typeof ROUTE_PATHS>).find(
    (key) => ROUTE_PATHS[key] === routePath
  )

  return routeKey ? PAGE_TITLES[routeKey] : 'Unknown Page'
}

export const BURRO_MAXIMUM_SPEED_MPS = 2.4
export const BURRO_MINIMUM_SPEED_MPS = 0
export const BURRO_DEFAULT_SPEED_MPS = 1.2
