import { Button, TextInput, ToggleSwitch, Tooltip } from 'flowbite-react'
import { FunctionComponent, MouseEvent } from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import { FaArrowDownUpAcrossLine, FaCircle, FaLocationDot, FaMapPin } from 'react-icons/fa6'
import { RiRobot2Fill } from 'react-icons/ri'
import {
  useCoordinateColorIndex,
  useDeviceColorIndex,
  useEndpointColorIndex,
  useIsMapShowingDroneTiles,
  useIsMapShowingOverlayTiles,
  useIsMapShowingSatelliteTiles,
  useIsSafeAngleModeIsOn,
  useIsUsingFeet,
  usePathColorIndex,
  usePointColorIndex,
  useTurnRadius,
  useWarningColorIndex,
} from '../../hooks/useLocalStorage'
import { COLOR_OPTIONS, UNDEFINED_COLOR_OPTION_INDEX } from './constants'

export const DestinationMapSettings: FunctionComponent<{className?: string}> = ({className}) => {
  const [deviceColorIndex, setDeviceColorIndex] = useDeviceColorIndex()
  const [pathColorIndex, setPathColorIndex] = usePathColorIndex()
  const [pointColorIndex, setPointColorIndex] = usePointColorIndex()
  const [endpointColorIndex, setEndpointColorIndex] = useEndpointColorIndex()
  const [coordinateColorIndex, setCoordinateColorIndex] = useCoordinateColorIndex()
  const [warningColorIndex, setWarningColorIndex] = useWarningColorIndex()
  const [isUsingFeet, setIsUsingFeet] = useIsUsingFeet()
  const [isSafeAngleModeIsOn, setIsSafeAngleModeOn] = useIsSafeAngleModeIsOn()
  const [turnRadiusState, setTurnRadiusState] = useTurnRadius()
  const [isMapShowingSatelliteTiles, setIsMapShowingSatelliteTiles] = useIsMapShowingSatelliteTiles()
  const [isMapShowingOverlayTiles, setIsMapShowingOverlayTiles] = useIsMapShowingOverlayTiles()
  const [isMapShowingDroneTiles, setIsMapShowingDroneTiles] = useIsMapShowingDroneTiles()

  return (
    <div className={className}>
      <TextInput
        id='input-gray'
        type='number'
        placeholder='1.5m (Turn radius)'
        required
        color='gray'
        value={`${turnRadiusState}`}
        onChange={(e) => {
          setTurnRadiusState(e.target.value)
        }}
      />
      <Button 
        onClick={() => {
          setIsSafeAngleModeOn(!isSafeAngleModeIsOn)
        }}
      >
        Safe angle mode: {isSafeAngleModeIsOn ? 'ON' : 'OFF'}
      </Button>
      <ToggleSwitch
        checked={isMapShowingSatelliteTiles}
        label='Satellite tiles'
        onChange={setIsMapShowingSatelliteTiles}
      />
      <ToggleSwitch checked={isMapShowingOverlayTiles} label='Overlay tiles' onChange={setIsMapShowingOverlayTiles} />
      <ToggleSwitch checked={isMapShowingDroneTiles} label='Drone tiles' onChange={setIsMapShowingDroneTiles} />
      <Button
        color={'light'}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          e.preventDefault()
          setIsUsingFeet(!isUsingFeet)
        }}
      >
        <Tooltip content='Units' placement='top'>
          Using {isUsingFeet ? 'ft' : 'm'} (click to toggle)
        </Tooltip>
      </Button>
      <Button
        color={'light'}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          e.preventDefault()
          switch (e.detail) {
            case 1:
              setDeviceColorIndex((deviceColorIndex + 1) % COLOR_OPTIONS.length)
              break
            case 2:
              setDeviceColorIndex(UNDEFINED_COLOR_OPTION_INDEX)
              break
          }
        }}
      >
        <Tooltip content='Robots' placement='top'>
          <div className='flex flex-col items-center'>
            <RiRobot2Fill color={COLOR_OPTIONS[deviceColorIndex]} size={24} />
          </div>
        </Tooltip>
        Robot color
      </Button>
      <Button
        color={'light'}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          e.preventDefault()
          switch (e.detail) {
            case 1:
              setPathColorIndex((pathColorIndex + 1) % COLOR_OPTIONS.length)
              break
            case 2:
              setPathColorIndex(UNDEFINED_COLOR_OPTION_INDEX)
              break
          }
        }}
      >
        <Tooltip content='Routes' placement='top'>
          <div className='flex flex-col items-center'>
            <FaArrowDownUpAcrossLine color={COLOR_OPTIONS[pathColorIndex]} size={24} />
          </div>
        </Tooltip>
        Route color
      </Button>

      <Button
        color={'light'}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          e.preventDefault()
          switch (e.detail) {
            case 1:
              setPointColorIndex((pointColorIndex + 1) % COLOR_OPTIONS.length)
              break
            case 2:
              setPointColorIndex(UNDEFINED_COLOR_OPTION_INDEX)
              break
          }
        }}
      >
        <Tooltip content='Destinations' placement='top'>
          <div className='flex flex-col items-center'>
            <FaLocationDot color={COLOR_OPTIONS[pointColorIndex]} size={24} />
          </div>
        </Tooltip>
        Destination color
      </Button>

      <Button
        color={'light'}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          e.preventDefault()
          switch (e.detail) {
            case 1:
              setEndpointColorIndex((endpointColorIndex + 1) % COLOR_OPTIONS.length)
              break
            case 2:
              setEndpointColorIndex(UNDEFINED_COLOR_OPTION_INDEX)
              break
          }
        }}
      >
        <Tooltip content='End points' placement='top'>
          <div className='flex flex-col items-center'>
            <FaMapPin color={COLOR_OPTIONS[endpointColorIndex]} size={24} />
          </div>
        </Tooltip>
        Endpoint color
      </Button>

      <Button
        color={'light'}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          e.preventDefault()
          switch (e.detail) {
            case 1:
              setCoordinateColorIndex((coordinateColorIndex + 1) % COLOR_OPTIONS.length)
              break
            case 2:
              setCoordinateColorIndex(UNDEFINED_COLOR_OPTION_INDEX)
              break
          }
        }}
      >
        <Tooltip content='Points' placement='top'>
          <div className='flex flex-col items-center'>
            <FaCircle color={COLOR_OPTIONS[coordinateColorIndex]} size={24} className='p-1.5' />
          </div>
        </Tooltip>
        Point color
      </Button>

      <Button
        color={'light'}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          e.preventDefault()
          switch (e.detail) {
            case 1:
              setWarningColorIndex((warningColorIndex + 1) % COLOR_OPTIONS.length)
              break
            case 2:
              setWarningColorIndex(UNDEFINED_COLOR_OPTION_INDEX)
              break
          }
        }}
      >
        <Tooltip content='Warnings' placement='top'>
          <div className='flex flex-col items-center'>
            <FaExclamationTriangle color={COLOR_OPTIONS[warningColorIndex]} size={24} className='p-0.5' />{' '}
          </div>
        </Tooltip>
        Warning color
      </Button>
    </div>
  )
}
