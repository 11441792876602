import { Navigate } from 'react-router-dom'
import type { User } from '../utils/auth'
import { getUser } from '../utils/auth'
import { AtlasLoginForm } from './ui/AtlasLoginForm'

export function LoginPage() {
  const user: User | null = getUser()

  if (user?.email) {
    return <Navigate to='/' />
  }

  return <AtlasLoginForm />
}
