import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DefaultApiPolygonDeleteRequest } from 'burro-web-api-js-client'
import { useContext } from 'react'
import { useAtlasApi } from '../../context/AtlasApiProvider'
import { SelectedUserContext } from '../../context/SelectedUserContext'
import type { User } from '../../utils/auth'
import { useUser } from '../../utils/auth'
import { polygonsGetQueryKey } from '../queries/usePolygonsGetQuery'

export const usePolygonDeleteMutation = () => {
  const api = useAtlasApi()
  const queryClient = useQueryClient()
  const { selectedUser } = useContext(SelectedUserContext)
  const { data: userData }: { data: User | null } = useUser()
  const customerId = selectedUser?.value ? selectedUser.value : userData?.customerTag

  return useMutation({
    mutationFn: async (params: DefaultApiPolygonDeleteRequest) => {
      if (!customerId) {
        throw new Error('Customer ID is required to delete a polygon')
      }

      await api.polygonDelete(params)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: polygonsGetQueryKey({ customerId }) })
    },
  })
}
