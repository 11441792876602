import { Drawer, DrawerHeaderProps, DrawerItemsProps, DrawerProps } from 'flowbite-react'
import React, { FunctionComponent, useEffect, useRef } from 'react'
import {
  ImperativePanelHandle,
  Panel,
  PanelProps,
  PanelResizeHandle,
  PanelResizeHandleProps,
} from 'react-resizable-panels'
import { PanelDrawerLayoutMode } from './PanelDrawerLayout'

export interface PanelDrawerProps {
  drawerHeaderProps?: DrawerHeaderProps
  drawerItemsProps?: DrawerItemsProps
  drawerProps: DrawerProps
  panelDrawer?: React.ReactNode
  panelProps?: PanelProps
  panelResizeHandleProps?: PanelResizeHandleProps
  mode?: PanelDrawerLayoutMode
  position?: 'left' | 'right'
}

export const PanelDrawer: FunctionComponent<PanelDrawerProps> = ({
  drawerHeaderProps,
  drawerItemsProps,
  drawerProps,
  panelDrawer,
  panelProps,
  panelResizeHandleProps,
  mode,
  position,
}) => {
  const panelRef = useRef<ImperativePanelHandle>(null)

  useEffect(() => {
    if (drawerProps?.open !== undefined) {
      if (panelRef.current?.isCollapsed()) {
        panelRef.current?.expand()
      } else {
        panelRef.current?.collapse()
      }
    }
  }, [drawerProps?.open])

  return (
    <>
      {panelDrawer && mode === PanelDrawerLayoutMode.DRAWER && (
        <Drawer {...drawerProps} position={position}>
          {drawerHeaderProps && <Drawer.Header {...drawerHeaderProps} />}
          <Drawer.Items {...drawerItemsProps}>{panelDrawer}</Drawer.Items>
        </Drawer>
      )}
      {panelDrawer && mode === PanelDrawerLayoutMode.PANEL && (
        <>
          {position === 'right' && <PanelResizeHandle {...panelResizeHandleProps} />}
          <Panel {...panelProps} ref={panelRef}>
            {panelDrawer}
          </Panel>
          {position === 'left' && <PanelResizeHandle {...panelResizeHandleProps} />}
        </>
      )}
    </>
  )
}
