import { Modal as FlowbiteModal, ModalProps } from 'flowbite-react'
import type { FunctionComponent, ReactNode } from 'react'

export interface SimpleModalProps {
  headerText?: ReactNode
  footer?: ReactNode
}

export const SimpleModal: FunctionComponent<SimpleModalProps & ModalProps> = ({
  headerText,
  children,
  footer,
  onClose,
}) => {
  return (
    <FlowbiteModal show onClose={onClose}>
      <FlowbiteModal.Header>{headerText}</FlowbiteModal.Header>

      <FlowbiteModal.Body>{children}</FlowbiteModal.Body>
      {footer && <FlowbiteModal.Footer>{footer}</FlowbiteModal.Footer>}
    </FlowbiteModal>
  )
}
