import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import { bbox, Position } from '@turf/turf'
import axios from 'axios'
import type { DestinationMapInterface } from 'burro-map-utils'
import { mapAddingSiteBounds, mapNamed } from 'burro-map-utils'
import 'leaflet-arrowheads'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { ActionCreators } from 'redux-undo'
import { cleanFeatureCollection } from 'turf-extensions'
import { GlobalStateContext } from '../../context/GlobalStateContext'
import { SelectedUserContext } from '../../context/SelectedUserContext'
import { useDestinationMapDeleteMutation } from '../../hooks/mutations/useDestinationMapDeleteMutation'
import { useDestinationMapPostMutation } from '../../hooks/mutations/useDestinationMapPostMutation'
import { useDestinationMapPutMutation } from '../../hooks/mutations/useDestinationMapPutMutation'
import { useDestinationMapGetQuery } from '../../hooks/queries/useDestinationMapGetQuery'
import {
  getDestinationMapsGetQueryKey,
  useDestinationMapsGetQuery,
} from '../../hooks/queries/useDestinationMapsGetQuery'
import { User, useUser } from '../../utils/auth'
import { identifyFeatureCollection } from '../../utils/identifyFeatureCollection'
import { TailwindBreakpoint, useTailwindBreakpoint } from '../../utils/useTailwindBreakpoint'
import { AlertModal } from '../ui/modals/AlertModal'
import { LoadingModal } from '../ui/modals/LoadingModal'
import { PanelDrawerLayout, PanelDrawerLayoutMode } from '../ui/PanelDrawerLayout/PanelDrawerLayout'
import { DestinationMapsContext } from './DestinationMapsContext'
import { destinationMapSlice, useDestinationMapDispatch, useDestinationMapSelector } from './destinationMapSlice'
import { DestinationMapsMapContainer } from './DestinationMapsMapContainer'
import { DestinationMapsNavbar } from './DestinationMapsNavbar'
import { DestinationMapsPanelDrawerLeft } from './DestinationMapsPanelDrawerLeft'
import { DestinationMapsPanelDrawerRight } from './DestinationMapsPanelDrawerRight'
import './DestinationMapsStyle.css'
import { drawingLineStringStore } from './drawingLineStringSlice'

export const DestinationMapsPage: FunctionComponent = () => {
  const [selectedDestinationMapIndexState, setSelectedDestinationMapIndexState] = useState<number | null>(0)
  const [isLeftOpenState, setIsLeftOpenState] = useState(true)
  const [isRightOpenState, setIsRightOpenState] = useState(false)
  const [selectedFeatureIdState, setSelectedFeatureIdState] = useState<string[]>([])
  const tailwindBreakpoint = useTailwindBreakpoint()
  const [navigatingFromPositionState, setNavigatingFromDestinationState] = useState<Position | null>(null)
  const [navigatingToPositionState, setNavigatingToDestinationState] = useState<Position | null>(null)

  const { selectedUser } = useContext(SelectedUserContext)
  const { data: userData }: { data: User | null } = useUser()
  const customerId = selectedUser?.value ? selectedUser.value : userData?.customerTag

  const isFetching = useIsFetching({ queryKey: getDestinationMapsGetQueryKey({ customerId }) })
  const isMutating = useIsMutating()

  const createMutation = useDestinationMapPostMutation()
  const updateMutation = useDestinationMapPutMutation()
  const deleteMutation = useDestinationMapDeleteMutation()

  const getManyQuery = useDestinationMapsGetQuery()
  const destinationMapsSortedFromQuery = (getManyQuery.data?.results ?? []).sort(
    (a, b) => a.name?.localeCompare(b.name ?? '') ?? 0
  )

  const destinationMapDispatch = useDestinationMapDispatch()
  const destinationMapRenderStateWithHistory = useDestinationMapSelector((state) => {
    return state.destinationMap
  })

  const selectedDestinationMapUid =
    (selectedDestinationMapIndexState !== null &&
    destinationMapsSortedFromQuery.length > selectedDestinationMapIndexState
      ? destinationMapsSortedFromQuery[selectedDestinationMapIndexState].uid
      : null) ?? null

  const getOneQuery = useDestinationMapGetQuery({
    destinationMapUid: selectedDestinationMapUid ?? undefined,
  })

  const selectedDestinationMapResponse = getOneQuery.data
  const selectedDestinationMap = selectedDestinationMapResponse?.geojson_data as DestinationMapInterface

  useEffect(() => {
    destinationMapDispatch(destinationMapSlice.actions.destinationMapRenderStateReducer(selectedDestinationMap))
    destinationMapDispatch(ActionCreators.clearHistory())
  }, [selectedDestinationMapIndexState, selectedDestinationMap])

  useEffect(() => {
    if (selectedFeatureIdState.length > 0 && !isRightOpenState) {
      setIsRightOpenState(true)
    }
  }, [selectedFeatureIdState, isRightOpenState])

  const { setBounds: setMapContextBounds } = useContext(GlobalStateContext)

  const [isConflictModalShowing, setIsConflictModalShowing] = useState(false)

  const handleAddDestinationMap = async (name: string, _destinationMap?: DestinationMapInterface) => {
    const map = mapNamed({ name: name })
    await createMutation.mutateAsync({ name, map })
  }

  const handleRemoveDestinationMapAtIndex = async (index: number) => {
    const destinationMapUid = destinationMapsSortedFromQuery[index].uid
    if (!destinationMapUid) {
      return
    }

    if (selectedDestinationMapIndexState === index) {
      setSelectedDestinationMapIndexState(null)
    }

    await deleteMutation.mutateAsync({ destinationMapUid })
  }

  const handleUpdateDestinationMapAtIndex = async (
    index: number,
    destinationMap: DestinationMapInterface,
    name?: string
  ) => {
    const destinationMapUid = destinationMapsSortedFromQuery[index].uid
    if (!destinationMapUid) {
      return
    }

    const oldEntry = destinationMapsSortedFromQuery[index]

    const newEntry = identifyFeatureCollection({
      featureCollection: {
        id: destinationMapUid,
        ...mapAddingSiteBounds({
          destinationMap: cleanFeatureCollection({
            featureCollection: destinationMap as any,
          }) as DestinationMapInterface,
        }),
      } as any,
    })

    try {
      await updateMutation.mutateAsync({
        destinationMapUid,
        destinationMapRequest: {
          map: newEntry,
          revision: (oldEntry.revision ?? 0) + 1,
          name: name ?? oldEntry.name,
        },
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if ((error?.response?.status as number) === 409) {
          setIsConflictModalShowing(true)
        } else {
          throw error
        }
      }
    }
  }

  const handleRenameDestinationMapAtIndex = async (index: number, name: string) => {
    const entry = structuredClone(selectedDestinationMap) as DestinationMapInterface

    if (!entry) {
      return
    }

    if (!entry.properties) {
      entry.properties = { folders: [], name }
    } else {
      entry.properties.name = name
    }

    await handleUpdateDestinationMapAtIndex(index, entry)
  }

  const handleSelectedDestinationMapIndexChange = (index: number | null) => {
    if (index !== null && selectedDestinationMapIndexState === index) {
      try {
        const bounds = bbox(selectedDestinationMap)

        setMapContextBounds({
          _northEast: { lat: bounds[3], lng: bounds[2] },
          _southWest: { lat: bounds[1], lng: bounds[0] },
        })
      } catch (e) {
        setMapContextBounds({
          _northEast: { lat: 90, lng: 180 },
          _southWest: { lat: -90, lng: -180 },
        })
      }
    }

    setSelectedDestinationMapIndexState(index)
  }

  const mode = tailwindBreakpoint === TailwindBreakpoint.SM ? PanelDrawerLayoutMode.DRAWER : PanelDrawerLayoutMode.PANEL

  return (
    <Provider store={drawingLineStringStore}>
      <DestinationMapsContext.Provider
        value={{
          addDestinationMap: handleAddDestinationMap,
          destinationMapRenderState: destinationMapRenderStateWithHistory.present.value,
          futureEditCount: destinationMapRenderStateWithHistory.future.length,
          historyIndex: destinationMapRenderStateWithHistory.index,
          isLeftOpen: isLeftOpenState,
          isRightOpen: isRightOpenState,
          jumpToPointInHistory: (state) => destinationMapDispatch(ActionCreators.jump(state)),
          mode,
          pastEditCount: destinationMapRenderStateWithHistory.past.length,
          redo: () => destinationMapDispatch(ActionCreators.redo()),
          removeDestinationMapAtIndex: handleRemoveDestinationMapAtIndex,
          renameDestinationMapAtIndex: handleRenameDestinationMapAtIndex,
          selectedDestinationMap,
          selectedDestinationMapIndex: selectedDestinationMapIndexState,
          selectedDestinationMapUid,
          selectedFeatureIds: selectedFeatureIdState,
          setDestinationMapRenderState: (renderState) =>
            destinationMapDispatch(destinationMapSlice.actions.destinationMapRenderStateReducer(renderState)),
          setIsLeftOpen: setIsLeftOpenState,
          setIsRightOpen: setIsRightOpenState,
          setSelectedDestinationMapIndex: handleSelectedDestinationMapIndexChange,
          setSelectedFeatureId: setSelectedFeatureIdState,
          undo: () => destinationMapDispatch(ActionCreators.undo()),
          updateDestinationMapAtIndex: handleUpdateDestinationMapAtIndex,
          navigatingFromPosition: navigatingFromPositionState,
          navigatingToPosition: navigatingToPositionState,
          setNavigatingFromPosition: setNavigatingFromDestinationState,
          setNavigatingToPosition: setNavigatingToDestinationState,
        }}
      >
        <LoadingModal show={isFetching !== 0 || isMutating !== 0} />
        <DestinationMapsNavbar />
        <PanelDrawerLayout
          mode={mode}
          childrenPanelProps={{
            order: 1,
          }}
          left={{
            drawerProps: {
              open: isLeftOpenState,
              onClose: () => setIsLeftOpenState(false),
              className: 'h-full',
            },
            drawerItemsProps: { className: 'h-full' },
            panelDrawer: <DestinationMapsPanelDrawerLeft />,
            panelProps: {
              minSize: 25,
              defaultSize: 25,
              collapsible: true,
              order: 0,
              className: isLeftOpenState ? 'px-2 pb-2 dark:bg-gray-800' : '',
            },
          }}
          right={{
            drawerProps: {
              open: isRightOpenState,
              onClose: () => {
                setIsRightOpenState(false)
                setSelectedFeatureIdState([])
              },
              className: 'h-full',
            },
            drawerItemsProps: { className: 'h-full' },
            panelDrawer: <DestinationMapsPanelDrawerRight className='flex h-full w-full flex-col gap-2' />,
            panelProps: {
              order: 2,
              collapsible: true,
              minSize: 25,
              defaultSize: 0,
              className: isRightOpenState ? 'px-2 dark:bg-gray-800' : '',
            },
          }}
        >
          <DestinationMapsMapContainer className='flex h-full w-full flex-1' />
        </PanelDrawerLayout>

        <AlertModal
          show={isConflictModalShowing}
          onClose={() => setIsConflictModalShowing(false)}
          headerText='Update Conflict Detected'
        >
          <p>
            Someone else has updated this destination map. Your changes could not be applied because revisions must be
            incremental. Please refresh the page to get the latest version and try again.
          </p>
        </AlertModal>
      </DestinationMapsContext.Provider>
    </Provider>
  )
}
