import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ResponseError, ResponseSuccess } from 'api-types'
import type {
  BulkUploadParams,
  DeleteItemsBodyParams,
  MoveFolderBodyParams,
  MoveItemsBodyParams,
} from 'modules/folders/types'
import type { MovePointBodyParams, PointReqParams } from 'modules/points/types'
import type { MovePolygonBodyParams, PolygonReqParams } from 'modules/polygons/types'
import type { MoveRouteBodyParams } from 'modules/routes/types'
import type { Dispatch } from 'react'
import { SetStateAction } from 'react'

import { API_URLS, GET_FOLDERS_KEY } from '../constants'
import { apiPost, apiPostForm } from '../utils/api'

export function useMoveRouteMutation({
  setRoutesOperationError,
}: {
  setRoutesOperationError: Dispatch<SetStateAction<string>>
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ routeId, folderId }: MoveRouteBodyParams) => {
      await apiPost<MoveRouteBodyParams, ResponseSuccess>(API_URLS.routes.moveRoute, {
        routeId,
        folderId,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}

export function useMovePolygonMutation({
  setRoutesOperationError,
}: {
  setRoutesOperationError: Dispatch<SetStateAction<string>>
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ polygonId, folderId }: MovePolygonBodyParams & PolygonReqParams) => {
      await apiPost<MovePolygonBodyParams, ResponseSuccess>(API_URLS.polygons.movePolygon(polygonId), {
        folderId,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}

export function useMovePointOfInterestMutation({
  setRoutesOperationError,
}: {
  setRoutesOperationError: Dispatch<SetStateAction<string>>
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pointId, folderId }: MovePointBodyParams & PointReqParams) => {
      await apiPost<MovePointBodyParams, ResponseSuccess>(API_URLS.poi.movePoi(pointId), {
        folderId,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}

export function useMoveFolderMutation({
  setRoutesOperationError,
}: {
  setRoutesOperationError: Dispatch<SetStateAction<string>>
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ parentFolderId, folderId }: MoveFolderBodyParams) => {
      await apiPost<MoveFolderBodyParams, ResponseSuccess>(API_URLS.folders.moveFolder, {
        parentFolderId,
        folderId,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}

export function useBulkUploadItems({
  setRoutesOperationError,
}: {
  setRoutesOperationError: Dispatch<SetStateAction<string>>
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ file, folder_id }: BulkUploadParams) => {
      const formData = new FormData()
      formData.append('file', file) // Append the file
      formData.append('folder_id', folder_id) // Append the folder ID
      await apiPostForm<ResponseSuccess>(API_URLS.folders.bulkUpload, formData)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}

export function useMoveItemsMutation({
  setRoutesOperationError,
}: {
  setRoutesOperationError: Dispatch<SetStateAction<string>>
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      parentFolderId,
      childrenRouteIds,
      childrenFolderIds,
      childrenPolygonIds,
      childrenPointsIds,
    }: MoveItemsBodyParams) => {
      await apiPost<MoveItemsBodyParams, ResponseSuccess>(API_URLS.items.moveItems, {
        parentFolderId,
        childrenRouteIds,
        childrenFolderIds,
        childrenPolygonIds,
        childrenPointsIds,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}

export function useDeleteItemsMutation({
  setRoutesOperationError,
}: {
  setRoutesOperationError: Dispatch<SetStateAction<string>>
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      childrenRouteIds,
      childrenFolderIds,
      childrenPolygonIds,
      childrenPointsIds,
    }: DeleteItemsBodyParams) => {
      await apiPost<DeleteItemsBodyParams, ResponseSuccess>(API_URLS.items.deleteItems, {
        childrenRouteIds,
        childrenFolderIds,
        childrenPolygonIds,
        childrenPointsIds,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}
