import { useQueryClient } from '@tanstack/react-query'
import { Button, Dropdown, Navbar } from 'flowbite-react'
import { FunctionComponent, useContext, useState } from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { FaBars } from 'react-icons/fa6'
import { useLocalstorageState } from 'rooks'
import { BURRO_CUSTOMER_TAGS } from '../../constants'
import { SelectedUserContext } from '../../context/SelectedUserContext'
import { useDestinationMapsGetQuery } from '../../hooks/queries/useDestinationMapsGetQuery'
import { User, useUser } from '../../utils/auth'
import { AutocompleteDropdownModal } from '../ui/modals/AutocompleteDropdownModal'
import { DestinationMapsContext } from './DestinationMapsContext'

export const DestinationMapsNavbar: FunctionComponent<{}> = () => {
  const [, setUserLsState] = useLocalstorageState<User | null>('BURRO_USER', null)
  const { setSelectedUser } = useContext(SelectedUserContext)
  const { data: user }: { data: User | null } = useUser()
  const queryClient = useQueryClient()
  const [isShowingCustomerTagModalState, setIsShowingCustomerTagModalState] = useState(false)
  const {
    setIsLeftOpen,
    isLeftOpen,
    selectedDestinationMapIndex,
    setSelectedDestinationMapIndex,
    setSelectedFeatureId: setSelectedFeatureIdState,
    setIsRightOpen,
    isRightOpen,
  } = useContext(DestinationMapsContext)

  const getManyQuery = useDestinationMapsGetQuery()
  const destinationMapsSortedFromQuery = (getManyQuery.data?.results ?? []).sort(
    (a, b) => a.name?.localeCompare(b.name ?? '') ?? 0
  )
  return (
    <>
      <Navbar fluid={true} rounded={true} className='w-full !px-2'>
        <div className='flex items-center'>
          <Button
            color='gray'
            onClick={() => {
              setIsLeftOpen(!isLeftOpen)
            }}
          >
            <FaBars />
          </Button>
        </div>

        <div className='flex-1 flex justify-center'>
          <div className='text-center text-gray-900 dark:text-white'>
            {user?.email.includes('burro.ai') ? (
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  setIsShowingCustomerTagModalState(true)
                }}
                className='underline'
              >
                {user.customerTag === '' ? 'Select customer tag' : user.customerTag}
              </a>
            ) : (
              user?.customerTag
            )}
            /
            {selectedDestinationMapIndex !== null &&
              destinationMapsSortedFromQuery.length > selectedDestinationMapIndex && (
                <Dropdown
                  label=''
                  dismissOnClick={false}
                  renderTrigger={() => (
                    <span className={destinationMapsSortedFromQuery.length > 1 ? 'underline' : ''}>
                      {destinationMapsSortedFromQuery[selectedDestinationMapIndex].name}
                    </span>
                  )}
                >
                  {destinationMapsSortedFromQuery.map((map, index) => {
                    return (
                      <Dropdown.Item
                        key={map.uid}
                        onClick={() => {
                          setSelectedDestinationMapIndex(index)
                        }}
                      >
                        {map.name}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown>
              )}
          </div>
        </div>

        <div className='flex items-center gap-2'>
          <Button
            color='gray'
            onClick={() => {
              setSelectedFeatureIdState([])
              setIsRightOpen(!isRightOpen)
            }}
          >
            <FaInfoCircle />
          </Button>
        </div>
      </Navbar>
      <AutocompleteDropdownModal
        headerText='Change customer tag'
        size='sm'
        placeholder='Search or enter customer tag'
        show={isShowingCustomerTagModalState}
        onClose={() => {
          setIsShowingCustomerTagModalState(false)
        }}
        items={BURRO_CUSTOMER_TAGS}
        onSelection={(item) => {
          setUserLsState({ ...user, customerTag: item } as any)
          setSelectedUser({ value: item, label: item })
          queryClient.invalidateQueries()
          window.location.reload()
        }}
      />
    </>
  )
}
