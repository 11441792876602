import type {
  Announcements,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragStartEvent,
  DropAnimation,
  Modifier,
  UniqueIdentifier,
} from '@dnd-kit/core'
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  closestCenter,
  defaultDropAnimation,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { MoveItemsBodyParams } from 'modules/folders/types'
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { TreeFolderOrShape, getBodyParamsFromFlatSubTree } from '../../../types/TreeFolderOrShape'
import { SortableTreeItem } from './components'
import { sortableTreeKeyboardCoordinates } from './keyboardCoordinates'
import type { FlattenedItem, SensorContext, TreeItem, TreeItems } from './types'
import {
  buildTree,
  flattenTree,
  getChildCount,
  getProjection,
  getSelectedSiblings,
  removeChildrenOf,
  removeItem,
} from './utilities'

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
}

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ]
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    })
  },
}

interface Props {
  collapsible?: boolean
  tree: TreeItems<TreeFolderOrShape>
  onSetTree: Dispatch<SetStateAction<TreeItems<TreeFolderOrShape>>>
  onChangeFolderSingleItem: (itemId: UniqueIdentifier, parentId: UniqueIdentifier | null) => void
  onChangeFolderMultipleItems: (params: MoveItemsBodyParams) => void
  indentationWidth?: number
  indicator?: boolean
  removable?: boolean
  labelAttribute?: keyof TreeFolderOrShape
  attributeToDisableDrop?: string
  renderValue?: (data: TreeFolderOrShape & { selected: boolean }) => ReactNode
  isCollapsible?: (data: TreeFolderOrShape) => boolean
  onCollapse: (id: UniqueIdentifier) => void
}

export function SortableTree({
  collapsible = true,
  tree,
  onSetTree,
  onChangeFolderSingleItem,
  onChangeFolderMultipleItems,
  indicator = false,
  indentationWidth = 50,
  removable,
  labelAttribute = 'id',
  attributeToDisableDrop,
  onCollapse,
  renderValue,
  isCollapsible,
}: Props) {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  const [isIndicatorDisabled, setIsIndicatorDisabled] = useState(false)
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null
    overId: UniqueIdentifier
  } | null>(null)

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(tree)
    const collapsedItems = flattenedTree.reduce<UniqueIdentifier[]>(
      (acc, { children, collapsed, id }) => (collapsed && children.length ? [...acc, id] : acc),
      []
    )

    return removeChildrenOf(flattenedTree, activeId ? [activeId, ...collapsedItems] : collapsedItems)
  }, [activeId, tree])

  useEffect(() => {
    document.body.style.setProperty('cursor', isIndicatorDisabled ? 'not-allowed' : 'grabbing')
  }, [isIndicatorDisabled])

  const projected =
    activeId && overId
      ? getProjection<TreeFolderOrShape>(flattenedItems, activeId, overId, offsetLeft, indentationWidth)
      : null

  const sensorContext: SensorContext<TreeFolderOrShape> = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  })

  const [coordinateGetter] = useState(() => sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth))

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  )

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems])
  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    }
  }, [flattenedItems, offsetLeft])

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement('onDragMove', active.id, over?.id)
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement('onDragOver', active.id, over?.id)
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement('onDragEnd', active.id, over?.id)
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`
    },
  }

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map(
          ({ id, collapsed, depth, parentId: _parentId, index: _index, selected, disabled = false, ...itemData }) => {
            const data = itemData as unknown as TreeFolderOrShape
            return (
              <SortableTreeItem<TreeFolderOrShape>
                key={id}
                id={id}
                value={(data[labelAttribute] as string) ?? id}
                depth={id === activeId && projected ? projected.depth : depth}
                indentationWidth={indentationWidth}
                indicator={indicator}
                isIndicatorDisabled={isIndicatorDisabled}
                collapsed={Boolean(collapsed)}
                selected={Boolean(selected)}
                onCollapse={collapsible ? () => onCollapse(id) : undefined}
                onRemove={removable ? () => handleRemove(id) : undefined}
                isCollapsible={isCollapsible}
                renderValue={renderValue}
                // @ts-ignore
                itemData={{ id, disabled, ...data }}
                disabled={disabled}
              />
            )
          }
        )}
        {createPortal(
          <DragOverlay
            zIndex={5000}
            dropAnimation={dropAnimationConfig}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem ? (
              <SortableTreeItem
                id={activeId}
                depth={activeItem.depth}
                clone
                childCount={getChildCount(tree, activeId) + 1}
                value={activeItem[labelAttribute] as string}
                indentationWidth={indentationWidth}
                disabled={activeItem?.disabled}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
    </DndContext>
  )

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId)
    setOverId(activeId)

    const activeItem = flattenedItems.find(({ id }) => id === activeId)

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      })
    }

    document.body.style.setProperty('cursor', 'grabbing')
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x)
    if (
      projected &&
      attributeToDisableDrop &&
      projected?.parentItemData?.[attributeToDisableDrop as keyof TreeItem<TreeFolderOrShape>]
    ) {
      setIsIndicatorDisabled(true)
    } else {
      setIsIndicatorDisabled(false)
    }
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null)
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState()

    if (projected && over && !isIndicatorDisabled) {
      const { depth, parentId } = projected
      if (activeId) {
        const activeItem = flattenedItems.find(({ id }) => id === activeId)
        const selectedSiblingElements = getSelectedSiblings<TreeFolderOrShape>(tree, active.id)
        if (parentId === 'preview-folder') {
          return
        }

        if (activeItem?.parentId !== parentId) {
          if (activeItem && selectedSiblingElements && selectedSiblingElements?.length > 0) {
            const itemParams = getBodyParamsFromFlatSubTree([activeItem, ...selectedSiblingElements])
            onChangeFolderMultipleItems({ parentFolderId: parentId as string, ...itemParams })
          } else {
            onChangeFolderSingleItem(activeId, parentId)
          }
        } else {
          let updatedTree = tree
          const activeElementsIds = [
            active.id,
            ...(selectedSiblingElements ? selectedSiblingElements.map((item) => item.id) : []),
          ]
          activeElementsIds.forEach((activeElementId) => {
            const clonedItems: FlattenedItem<TreeFolderOrShape>[] = JSON.parse(JSON.stringify(flattenTree(updatedTree)))
            const overIndex = clonedItems.findIndex(({ id }) => id === over.id)
            const activeIndex = clonedItems.findIndex(({ id }) => id === activeElementId)
            const activeTreeItem = clonedItems[activeIndex]
            clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId }
            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)
            const updatedIndexes = sortedItems.map((item, idx) => ({ ...item, index: idx }))
            updatedTree = buildTree(updatedIndexes)
          })
          if (updatedTree) {
            onSetTree(updatedTree)
          }
        }
      }
    }
  }

  function handleDragCancel() {
    resetState()
  }

  function resetState() {
    setOverId(null)
    setActiveId(null)
    setOffsetLeft(0)
    setCurrentPosition(null)
    document.body.style.setProperty('cursor', '')
  }

  function handleRemove(id: UniqueIdentifier) {
    onSetTree((items) => removeItem(items, id))
  }

  function getMovementAnnouncement(eventName: string, activeId: UniqueIdentifier, overId?: UniqueIdentifier) {
    if (overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (currentPosition && projected.parentId === currentPosition.parentId && overId === currentPosition.overId) {
          return
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          })
        }
      }

      const clonedItems: FlattenedItem<TreeFolderOrShape>[] = JSON.parse(JSON.stringify(flattenTree(tree)))
      const overIndex = clonedItems.findIndex(({ id }) => id === overId)
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId)
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)

      const previousItem = sortedItems[overIndex - 1]

      let announcement
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved'
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested'

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1]
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`
        } else {
          let previousSibling: FlattenedItem<TreeFolderOrShape> | undefined = previousItem
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId: UniqueIdentifier | null = previousSibling.parentId
            previousSibling = sortedItems.find(({ id }) => id === parentId)
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`
          }
        }
      }

      return announcement
    }

    return
  }
}

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  }
}
