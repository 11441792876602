import { Modal, ModalProps, Spinner } from 'flowbite-react'
import { FunctionComponent } from 'react'

export const LoadingModal: FunctionComponent<ModalProps> = ({ ...modalProps }) => {
  return (
    <Modal
      theme={{
        content: {
          base: 'focus-visible:outline-none',
          inner: `bg-transparent`,
        },
      }}
      {...modalProps}
      tabIndex={-1}
      className='ring-opacity-0'
    >
      <Modal.Body className='flex items-center justify-center align-middle'>
        <Spinner size={'xl'} />
      </Modal.Body>
    </Modal>
  )
}
