import type { ResponseError } from 'api-types'
import { Button } from 'flowbite-react'
import type { SignInBodyParams } from 'modules/auth/types'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BurroLogo from '../../assets/burro_logo.svg'
import { useCustomForm } from '../../hooks/useCustomForm'
import { useLogin } from '../../utils/auth'
import { AlertFailure } from './AlertFailure'

export function AtlasLoginForm() {
  const [isLoading, setIsLoading] = useState(false)
  const [loginError, setLoginError] = useState('')
  const login = useLogin()
  const { values, onChange } = useCustomForm<SignInBodyParams>()
  const navigate = useNavigate()

  return (
    <div>
      <img src={BurroLogo} className='mb-4 h-6 sm:h-9' alt='Burro Logo' />
      <span className='mb-4 inline-flex dark:text-white'>Sign in</span>
      <form
        className='mb-3 flex flex-col items-center gap-4'
        onSubmit={(e) => {
          e.preventDefault()
          setIsLoading(true)
          login.mutate(values, {
            onSuccess: () => {
              setIsLoading(false)
              navigate('/')
            },
            onError: (errorResponse: ResponseError) => {
              setLoginError(errorResponse.error)
              setIsLoading(false)
            },
          })
        }}
      >
        <div>
          <div className='mb-2 block'>
            <label htmlFor='password' className='mb-2 block text-sm font-medium text-gray-900 dark:text-gray-200'>
              Your email
            </label>
          </div>
          <input
            id='email'
            type='email'
            placeholder='name@burro.ai'
            name='email'
            required={true}
            onChange={onChange}
            autoComplete='username'
            className='block w-full rounded border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
          />
        </div>
        <div>
          <div className='mb-2 block'>
            <label htmlFor='password' className='mb-2 block text-sm font-medium text-gray-900 dark:text-gray-200'>
              Your password
            </label>
          </div>
          <input
            id='password'
            type='password'
            placeholder='password'
            name='password'
            required={true}
            onChange={onChange}
            autoComplete='current-password'
            className='block w-full rounded border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
          />
        </div>
        <Button type='submit' isProcessing={isLoading} color='blue' className='rounded'>
          Submit
        </Button>
      </form>
      <AlertFailure errorMsg={loginError} />
    </div>
  )
}
