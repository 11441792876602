export class MetaEnv {
  static get apiUrl(): string {
    return import.meta.env.VITE_API_URL
  }

  static get customMapTilesUrl(): string {
    return import.meta.env.VITE_CUSTOM_MAP_TILES_URL
  }

  static get mapboxAccessToken(): string {
    return import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
  }

  static get burroWebApiBasePath(): string {
    return import.meta.env.VITE_BURRO_WEB_API_BASE_PATH
  }

  static get burroWebApiKey(): string {
    return import.meta.env.VITE_BURRO_WEB_API_KEY
  }

  static get burroWebApiDevComponent(): string {
    return import.meta.env.VITE_BURRO_WEB_API_DEV_COMPONENT
  }

  static get burroWebApiProdComponent(): string {
    return import.meta.env.VITE_BURRO_WEB_API_PROD_COMPONENT
  }

  static get burroEnv(): string {
    return import.meta.env.VITE_BURRO_ENV
  }

  static get amplitudeApiKey(): string {
    return import.meta.env.VITE_AMPLITUDE_API_KEY
  }

  static get sentryDsn(): string {
    return import.meta.env.VITE_SENTRY_DSN
  }

  static get sentryProject(): string {
    return import.meta.env.VITE_SENTRY_PROJECT
  }

  static get sentryOrg(): string {
    return import.meta.env.VITE_SENTRY_ORG
  }

  static get sentryAuthToken(): string {
    return import.meta.env.VITE_SENTRY_AUTH_TOKEN
  }

  static get releaseTag(): string {
    return import.meta.env.VITE_RELEASE_TAG
  }

  static get buildTimestamp(): string {
    return import.meta.env.VITE_BUILD_TIMESTAMP
  }

  static get mowPageEnabled(): boolean {
    return import.meta.env.VITE_MOW_PAGE_ENABLED === 'true'
  }
}
