import classNames from 'classnames'
import type { CSSProperties, ForwardedRef, HTMLAttributes, ReactNode } from 'react'
import { forwardRef } from 'react'

import { Action, Handle, Remove } from '../index'

import styles from './TreeItem.module.css'

const CollapseIcon = (
  <svg width='10' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 41'>
    <path d='M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z' />
  </svg>
)

export interface Props<ItemType> extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  childCount?: number
  clone?: boolean
  collapsed?: boolean
  depth: number
  disableInteraction?: boolean
  disableSelection?: boolean
  ghost?: boolean
  handleProps?: any
  indicator?: boolean
  isIndicatorDisabled?: boolean
  indentationWidth: number
  value: string
  onCollapse?(): void
  onRemove?(): void
  wrapperRef?(node: HTMLLIElement): void
  renderValue?: (data: ItemType & { selected: boolean }) => ReactNode
  isCollapsible?: (data: ItemType) => boolean
  itemData?: ItemType
  selected: boolean
  disabled?: boolean
}

function TreeItemInner<ItemType>(
  {
    childCount,
    clone,
    depth,
    disableSelection,
    disableInteraction,
    ghost,
    handleProps,
    indentationWidth,
    indicator,
    isIndicatorDisabled,
    collapsed,
    onCollapse,
    onRemove,
    style,
    value,
    wrapperRef,
    isCollapsible,
    renderValue,
    itemData,
    selected,
    disabled = false,
    ...props
  }: Props<ItemType>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const showCollapseIcon = isCollapsible?.(itemData as ItemType)
  return (
    <li
      className={classNames(
        styles.wrapper,
        clone && styles.clone,
        ghost && styles.ghost,
        indicator && styles.indicator,
        isIndicatorDisabled && styles.isIndicatorDisabled,
        disableSelection && styles.disableSelection,
        disableInteraction && styles.disableInteraction
      )}
      ref={wrapperRef}
      style={
        {
          '--spacing': `${indentationWidth * depth}px`,
        } as CSSProperties
      }
      // @ts-ignore
      data-id={itemData?.id}
      {...props}
    >
      <div className={styles.treeItem} ref={ref} style={style}>
        {!disabled && <Handle {...handleProps} />}
        {onCollapse &&
          (showCollapseIcon ? (
            <Action onClick={() => onCollapse()} className={classNames(styles.collapse, collapsed && styles.collapsed)}>
              {CollapseIcon}
            </Action>
          ) : (
            <div className='pr-6'></div>
          ))}
        {renderValue && itemData ? (
          renderValue({ ...itemData, selected })
        ) : (
          <span className={styles.text}>{value}</span>
        )}
        {!clone && onRemove && <Remove onClick={onRemove} />}
        {clone && childCount && childCount > 1 ? <span className={styles.count}>{childCount}</span> : null}
      </div>
    </li>
  )
}

export const TreeItem = forwardRef(TreeItemInner) as <ItemType>(
  props: Props<ItemType> & { ref?: ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof TreeItemInner>
