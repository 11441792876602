import { ChangeEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export function CustomSearchForm({
  placeholder,
  includeBtn = false,
  size = 's',
  onSearch = undefined,
}: {
  placeholder: string
  onSearch?: () => void
  includeBtn?: boolean
  size?: 's' | 'm'
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const searchTermFromQuery = queryParams.get('searchTerm') || ''

  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value
    if (newSearchTerm.length > 0) {
      queryParams.set('searchTerm', newSearchTerm)
    } else {
      queryParams.delete('searchTerm')
    }
    navigate(`${location.pathname}?${queryParams.toString()}`)
  }

  return (
    <form
      className='mb-2 w-full'
      onSubmit={(e) => {
        e.preventDefault()
        onSearch?.()
      }}
    >
      <label htmlFor='default-search' className='sr-only mb-2 text-sm font-medium text-stone-700'>
        Search
      </label>
      <div className='relative w-full'>
        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
          <svg
            aria-hidden='true'
            className='h-5 w-5 text-gray-500'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
            ></path>
          </svg>
        </div>
        <input
          type='search'
          className={`block w-full rounded border border-gray-300 bg-gray-50 ${
            size === 's' ? 'p-2' : 'p-2.5'
          } pl-10 text-sm text-stone-700 focus:border-blue-500 focus:ring-blue-500`}
          placeholder={placeholder}
          onChange={handleSearchTermChange}
          value={searchTermFromQuery}
        />
        {includeBtn && (
          <button
            type='submit'
            className='absolute right-2 rounded bg-blue-500 px-3 py-1 text-sm font-normal text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300'
            style={{ bottom: '7px' }}
          >
            Search
          </button>
        )}
      </div>
    </form>
  )
}
