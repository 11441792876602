import { useMutation } from '@tanstack/react-query'
import type { DefaultApiPlanPathFieldPostRequest } from 'burro-web-api-js-client'
import { useAtlasApi } from '../../context/AtlasApiProvider'

export const usePlanPathFieldMutation = () => {
  const api = useAtlasApi()

  return useMutation({
    mutationKey: ['planPathFieldPost'],

    mutationFn: async (params: DefaultApiPlanPathFieldPostRequest) => {
      const response = await api.planPathFieldPost(params)

      return response.data
    },
  })
}
