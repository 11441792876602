import { Alert } from 'flowbite-react'
import { HiInformationCircle } from 'react-icons/hi'

export function AlertFailure({ errorMsg }: { errorMsg: string | undefined }) {
  return (
    <>
      {errorMsg?.length ? (
        <Alert color='failure' icon={HiInformationCircle}>
          {errorMsg}
        </Alert>
      ) : null}
    </>
  )
}
