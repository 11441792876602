import { Button, Label, TextInput } from 'flowbite-react'
import { FunctionComponent } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BURRO_MAXIMUM_SPEED_MPS, BURRO_MINIMUM_SPEED_MPS, MPS_TO_MPH_CONVERSION_FACTOR } from '../../constants'

export interface DestinationMapSpeedLimitForm {
  speedLimitMetersPerSecond: number
}

export const DestinationMapsSpeedLimitForm: FunctionComponent<{
  className?: string
  onSetAll?: (data: DestinationMapSpeedLimitForm) => void
  onSetDefault?: (data: DestinationMapSpeedLimitForm) => void
  onDeleteAll?: () => void
}> = ({ className, onSetAll, onSetDefault, onDeleteAll }) => {
  const { handleSubmit, control, watch, trigger, setError } = useForm<DestinationMapSpeedLimitForm>({
    defaultValues: {
      speedLimitMetersPerSecond: 0,
    },
  })

  const handleSetGlobalSpeedLimit = (data: DestinationMapSpeedLimitForm) => {
    if (onSetDefault) {
      onSetDefault(data)
    }
  }

  const handleSetAllSpeedLimits = async () => {
    if (onSetAll && (await trigger('speedLimitMetersPerSecond'))) {
      onSetAll({
        speedLimitMetersPerSecond: watch('speedLimitMetersPerSecond'),
      })
    }
  }

  const handleDeleteAllSpeedLimits = () => {
    if (onDeleteAll) {
      onDeleteAll()
    }
  }

  return (
    <form
      onSubmit={handleSubmit(handleSetGlobalSpeedLimit)}
      className={`bg-background text-black dark:text-white ${className}`}
    >
      <div className='space-y-4'>
        <div className='space-y-2'>
          <Label value='Speed limit' />
          <Controller
            control={control}
            name='speedLimitMetersPerSecond'
            rules={{
              validate: (value) => {
                if (value < BURRO_MINIMUM_SPEED_MPS || value > BURRO_MAXIMUM_SPEED_MPS) {
                  setError('speedLimitMetersPerSecond', {
                    message: `Speed limit must be between ${BURRO_MINIMUM_SPEED_MPS} and ${BURRO_MAXIMUM_SPEED_MPS} m/s`,
                  })
                  return false
                } else {
                  setError('speedLimitMetersPerSecond', { message: undefined })
                  return true
                }
              },
            }}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
              const mphNumber = Math.round(Number(value ?? 0) * MPS_TO_MPH_CONVERSION_FACTOR * 100) / 100
              const mpmString = `${mphNumber < 0.01 ? '<' : ''}${mphNumber.toFixed(2)}mph`
              return (
                <div>
                  <div className='relative flex items-center'>
                    <TextInput
                      type='number'
                      inputMode='decimal'
                      className='flex-1'
                      id='speedLimitMetersPerSecond'
                      placeholder='0'
                      color={error ? 'failure' : 'gray'}
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(e.target.value === '' ? 0.0 : parseFloat(e.target.value))
                        trigger('speedLimitMetersPerSecond')
                      }}
                      value={value}
                      step={0.000001}
                      min={BURRO_MINIMUM_SPEED_MPS}
                      max={BURRO_MAXIMUM_SPEED_MPS}
                    />
                    <span className='text-gray-500 italic' style={{ position: 'absolute', right: '7em' }}>
                      m/s
                    </span>
                    <span className='ml-1 text-sm text-gray-500 dark:text-gray-400'>{mpmString}</span>
                  </div>
                  {error && <p className='text-sm text-red-500'>{error.message}</p>}
                </div>
              )
            }}
          />
        </div>

        <div className='space-y-2 text-center'>
          <Button type='submit' className='w-full'>
            Override Default Speed Limits
          </Button>

          <Button type='button' color='light' className='w-full' onClick={handleSetAllSpeedLimits}>
            Override All Speed Limits
          </Button>

          <Button type='button' color='failure' className='w-full' onClick={handleDeleteAllSpeedLimits}>
            Delete All Speed Limits
          </Button>
        </div>
      </div>
    </form>
  )
}
