import { area, Feature, length, Polygon } from '@turf/turf'
import { Label, Table, Tabs, TabsRef, ToggleSwitch } from 'flowbite-react'
import { FunctionComponent, useContext, useRef, useState } from 'react'
import { FaDrawPolygon, FaMap, FaTractor } from 'react-icons/fa'
import {
  useCoverageWidthMeters,
  useCoverPerimeters,
  useIsMapShowingDroneTiles,
  useIsMapShowingOverlayTiles,
  useIsMapShowingSatelliteTiles,
  useMowPatternSkip,
  useOverlapPercent,
  useRobotWidthMeters,
  useSwathsAngleRadians,
  useSwathsVariant,
  useTurnRadiusMeters,
} from '../../hooks/useLocalStorage'
import { PlanPathFieldParametersForm } from './PlanPathFieldParametersForm'
import { PolygonContext } from './PolygonContext'

export const PolygonsPagePanelDrawerRight: FunctionComponent<{ className?: string }> = ({ className }) => {
  const tabsRef = useRef<TabsRef>(null)
  const [activeTabState, setActiveTabState] = useState(0)
  const { selectedPolygon, plannedPath } = useContext(PolygonContext)

  const [isMapShowingSatelliteTiles, setIsMapShowingSatelliteTiles] = useIsMapShowingSatelliteTiles()
  const [isMapShowingOverlayTiles, setIsMapShowingOverlayTiles] = useIsMapShowingOverlayTiles()
  const [isMapShowingDroneTiles, setIsMapShowingDroneTiles] = useIsMapShowingDroneTiles()
  const [coverageWidthMetersState, setCoverageWidthMetersState] = useCoverageWidthMeters()
  const [turnRadiusMetersState, setTurnRadiusMetersState] = useTurnRadiusMeters()
  const [swathsVariantState, setSwathsVariantsState] = useSwathsVariant()
  const [robotWidthMetersState, setRobotWidthMetersState] = useRobotWidthMeters()
  const [mowPatternSkipState, setMowPatternSkipState] = useMowPatternSkip()
  const [overlapPercentState, setOverlapPercentState] = useOverlapPercent()
  const [coverPerimetersState, setCoverPerimetersState] = useCoverPerimeters()
  const [swathsAngleRadiansState, setSwathsAngleRadiansState] = useSwathsAngleRadians()

  const polygonGeojsonFeature = selectedPolygon?.geojson
    ? (JSON.parse(selectedPolygon?.geojson ?? '') as Feature<Polygon>)
    : null
  const polygonSquareMeters = polygonGeojsonFeature != null ? area(polygonGeojsonFeature) : null
  const plannedPathMeters = plannedPath != null ? length(plannedPath, { units: 'meters' }) : null
  const plannedPathMiles = plannedPath != null ? length(plannedPath, { units: 'miles' }) : null

  return (
    <div className={`flex flex-col h-full ${className ?? ''}`}>
      <div className='flex-none overflow-x-auto overflow-y-none' style={{ height: '60px', overflowY: 'hidden' }}>
        <Tabs
          ref={tabsRef}
          onActiveTabChange={setActiveTabState}
          className='overflow-y-none'
          variant='fullWidth'
          theme={{ base: '', tabpanel: '' }}
          style={{ height: '60px' }}
        >
          <Tabs.Item title='Settings' icon={FaTractor} className='h-0' />
          <Tabs.Item title='Info' icon={FaDrawPolygon} className='h-0' />
          <Tabs.Item title='Map' icon={FaMap} className='h-0' />
        </Tabs>
      </div>
      {activeTabState === 0 && (
        <PlanPathFieldParametersForm
          className='flex-grow overflow-y-auto gap-2'
          defaultValues={{
            coverageWidthMeters: coverageWidthMetersState,
            turnRadiusMeters: turnRadiusMetersState,
            swathsVariant: swathsVariantState ?? undefined,
            robotWidthMeters: robotWidthMetersState,
            mowPatternSkip: mowPatternSkipState,
            overlapPercent: overlapPercentState,
            swathsAngleRadians: swathsAngleRadiansState ?? undefined,
            coverPerimeters: coverPerimetersState ?? undefined,
          }}
          onValidWatch={(values) => {
            setCoverageWidthMetersState(values.coverageWidthMeters)
            setMowPatternSkipState(values.mowPatternSkip)
            setOverlapPercentState(values.overlapPercent)
            setRobotWidthMetersState(values.robotWidthMeters)
            setTurnRadiusMetersState(values.turnRadiusMeters)
            setSwathsVariantsState(values.swathsVariant ?? null)
            setSwathsAngleRadiansState(values.swathsAngleRadians ?? null)
            setCoverPerimetersState(values.coverPerimeters ?? null)
          }}
        />
      )}
      {activeTabState === 1 && (
        <Table>
          <Table.Head>
            <Table.HeadCell>Measure</Table.HeadCell>
            <Table.HeadCell>Value</Table.HeadCell>
          </Table.Head>
          <Table.Body className='divide-y'>
            <Table.Row className='bg-white dark:border-gray-700 dark:bg-gray-800'>
              <Table.Cell className='whitespace-nowrap font-medium text-gray-900 dark:text-white'>Area</Table.Cell>
              <Table.Cell>
                <div>
                  <span className='font-bold'>{polygonSquareMeters?.toFixed(2)}</span> m<sup>2</sup>
                </div>
                <div>
                  <span className='font-bold'>{((polygonSquareMeters ?? 0) * 0.000247105).toFixed(2)}</span> Acres
                </div>
              </Table.Cell>
            </Table.Row>
            {(plannedPathMeters != null || plannedPathMiles != null) && (
              <Table.Row className='bg-white dark:border-gray-700 dark:bg-gray-800'>
                <Table.Cell className='whitespace-nowrap font-medium text-gray-900 dark:text-white'>
                  Distance
                </Table.Cell>
                <Table.Cell>
                  {plannedPathMeters != null && (
                    <div>
                      <span className='font-bold'>{plannedPathMeters.toFixed(2)}</span> meters
                    </div>
                  )}
                  {plannedPathMiles != null && (
                    <div>
                      <span className='font-bold'>{plannedPathMiles.toFixed(2)}</span> miles
                    </div>
                  )}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      )}
      {activeTabState === 2 && (
        <div className='grid gap-2 background'>
          <Label value='Map tiles' />
          <ToggleSwitch
            checked={isMapShowingSatelliteTiles}
            label='Satellite tiles'
            onChange={setIsMapShowingSatelliteTiles}
          />
          <ToggleSwitch
            checked={isMapShowingOverlayTiles}
            label='Overlay tiles'
            onChange={setIsMapShowingOverlayTiles}
          />
          <ToggleSwitch checked={isMapShowingDroneTiles} label='Drone tiles' onChange={setIsMapShowingDroneTiles} />
        </div>
      )}
    </div>
  )
}
