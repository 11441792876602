import { Dropdown } from 'flowbite-react'
import { FunctionComponent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getPageTitleForRoutePath, PAGE_TITLES, ROUTE_PATHS } from '../../constants'
import { MetaEnv } from '../../meta-env'

export const AtlasNavigationDropdown: FunctionComponent<{ className?: string }> = ({ className }) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div className={className}>
      <Dropdown label={getPageTitleForRoutePath(location.pathname)} color='gray' dismissOnClick={false}>
        <Dropdown.Item
          onClick={() => {
            navigate(ROUTE_PATHS.ROOT)
          }}
        >
          {PAGE_TITLES.ROOT}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            navigate(ROUTE_PATHS.ROBOTS)
          }}
        >
          {PAGE_TITLES.ROBOTS}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            navigate(ROUTE_PATHS.DESTINATION_MAPS)
          }}
        >
          {PAGE_TITLES.DESTINATION_MAPS}
        </Dropdown.Item>
        {MetaEnv.mowPageEnabled && (
          <Dropdown.Item
            onClick={() => {
              navigate(ROUTE_PATHS.MOW)
            }}
          >
            {PAGE_TITLES.MOW}
          </Dropdown.Item>
        )}
      </Dropdown>
    </div>
  )
}
