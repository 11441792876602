import { wrapCreateBrowserRouter } from '@sentry/react'
import { createBrowserRouter } from 'react-router-dom'
import { AppErrorBoundary } from './components/AppErrorBoundary'
import { DestinationMapsPageContainer } from './components/DestinationMaps/DestinationMapsPageContainer'
import { LoginPage } from './components/LoginPage'
import { PolygonsPage } from './components/Polygons/PolygonsPage'
import { RobotsPage } from './components/RobotsList/RobotsPage'
import { RoutesMapPage } from './components/RoutesMap/RoutesMapPage'
import { ROUTE_PATHS } from './constants'

export function createRouter() {
  const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

  return sentryCreateBrowserRouter([
    {
      path: '/',
      children: [
        {
          path: ROUTE_PATHS.ROOT,
          element: (
            <AppErrorBoundary>
              <RoutesMapPage />
            </AppErrorBoundary>
          ),
        },
        {
          path: ROUTE_PATHS.DESTINATION_MAPS,
          element: (
            <AppErrorBoundary>
              <DestinationMapsPageContainer />
            </AppErrorBoundary>
          ),
        },
        {
          path: ROUTE_PATHS.ROBOTS,
          element: (
            <AppErrorBoundary>
              <RobotsPage />
            </AppErrorBoundary>
          ),
        },
        {
          path: ROUTE_PATHS.LOGIN,
          element: (
            <AppErrorBoundary>
              <LoginPage />
            </AppErrorBoundary>
          ),
        },
        {
          path: ROUTE_PATHS.MOW,
          element: (
            <AppErrorBoundary>
              <PolygonsPage />
            </AppErrorBoundary>
          ),
        },
      ],
    },
  ])
}

export const router = createRouter()
