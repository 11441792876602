import type { DestinationMapPointFeature } from 'burro-map-utils'
import { Button, Label, TextInput } from 'flowbite-react'
import { FunctionComponent, useEffect, useRef } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'

export const DestinationMapPointFeatureForm: FunctionComponent<{
  feature: DestinationMapPointFeature
  onValidSubmit?: SubmitHandler<{ name: string }>
  onValidWatch?: (data: { name: string }) => void
  className?: string
}> = ({ feature, onValidSubmit, onValidWatch, className }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<{
    name: string
  }>({ defaultValues: { name: feature.properties.name } })

  const watchedValues = watch()
  const prevValuesRef = useRef(watchedValues)

  useEffect(() => {
    if (onValidWatch && JSON.stringify(watchedValues) !== JSON.stringify(prevValuesRef.current)) {
      prevValuesRef.current = watchedValues
      onValidWatch({
        ...feature.properties,
        ...watchedValues,
      })
    }
  }, [watchedValues, onValidWatch, feature.properties])

  return (
    <form
      className={className}
      onSubmit={handleSubmit((data) => {
        if (onValidSubmit) {
          onValidSubmit({ ...feature.properties, ...data })
        }
      })}
    >
      <Label htmlFor='name' value='Destination name' />
      <TextInput id='name' type='text' placeholder={`Name`} required {...register('name', { required: true })} />
      {errors.name && <p className='text-sm text-red-500'>This field is required.</p>}
      {onValidSubmit !== undefined && <Button type='submit'>Save</Button>}
    </form>
  )
}
