import type { ChangeEvent } from 'react'
import { useState } from 'react'

export const useCustomForm = <V extends Record<string, any>>(initialValues?: V) => {
  const [values, setValues] = useState<V | unknown>(initialValues || {})

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setValues((v: V) => ({ ...v, [e.target.name]: e.target.value }))
  }

  const onSetAll = (values: V) => {
    setValues((v: V) => ({ ...v, ...values }))
  }

  const resetForm = () => {
    setValues(initialValues || {})
  }

  return {
    values: values as V,
    onChange,
    onSetAll,
    resetForm,
  }
}
