import { useQuery } from '@tanstack/react-query'
import type { TileFoldersResponse } from 'modules/tiles/types'
import { FunctionComponent, useContext } from 'react'
import { TileLayer } from 'react-leaflet'
import { API_URLS } from '../../constants'
import { SelectedUserContext } from '../../context/SelectedUserContext'
import {
  useIsMapShowingDroneTiles,
  useIsMapShowingOverlayTiles,
  useIsMapShowingSatelliteTiles,
} from '../../hooks/useLocalStorage'
import { MetaEnv } from '../../meta-env'
import { apiGet } from '../../utils/api'
import type { User } from '../../utils/auth'
import { useUser } from '../../utils/auth'
import { orderDirectories } from '../RoutesMap/utils/orderDirectories'

export const AtlasTileLayers: FunctionComponent = () => {
  const { data: userData }: { data: User | null } = useUser()
  const { selectedUser } = useContext(SelectedUserContext)

  const [isMapShowingSatelliteTiles] = useIsMapShowingSatelliteTiles()
  const [isMapShowingOverlayTiles] = useIsMapShowingOverlayTiles()
  const [isMapShowingDroneTiles] = useIsMapShowingDroneTiles()

  const { data: tileDirectories } = useQuery({
    queryKey: ['getTileFolders'],
    queryFn: async () => {
      const response = await apiGet<TileFoldersResponse>(API_URLS.tileFolders.getTileFolders)
      return response
    },
    staleTime: 60 * 60 * 1000, // 1 hour in milliseconds,}
  })
  const orderedDirectories = orderDirectories(tileDirectories?.directories ?? [], tileDirectories?.config ?? [])
  const customMapUrls = MetaEnv.customMapTilesUrl
  const customerTag = selectedUser?.value ? selectedUser.value : userData?.customerTag
  const overlayMapsUrls: string[] = []

  if (customMapUrls && customerTag) {
    orderedDirectories.map((element) => {
      overlayMapsUrls.push(`${customMapUrls}${customerTag}/${element}/{z}/{x}/{y}.png`)
    })
  }

  return (
    <>
      {isMapShowingSatelliteTiles === true && (
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${
            MetaEnv.mapboxAccessToken
          }`}
          maxZoom={26}
          maxNativeZoom={22}
          noWrap={true}
        />
      )}
      {isMapShowingOverlayTiles === true &&
        overlayMapsUrls.map((url, index) => (
          <TileLayer
            key={index}
            url={url}
            minZoom={11}
            maxZoom={26}
            maxNativeZoom={21}
            opacity={1.0}
            tms={true}
            crossOrigin={true}
            noWrap={true}
          />
        ))}
      {isMapShowingDroneTiles && (
        <TileLayer
          url={`${customMapUrls}orthophoto_tiles/{z}/{x}/{y}.png`}
          minZoom={11}
          maxZoom={26}
          maxNativeZoom={21}
          opacity={1.0}
          tms={true}
          crossOrigin={true}
          noWrap={true}
        />
      )}
    </>
  )
}
