import { useMutation, useQuery } from '@tanstack/react-query'
import type { ResponseError, ResponseSuccess } from 'api-types'
import { Button, Spinner } from 'flowbite-react'
import type { RobotSchedulesResponse } from 'modules/devices/types'
import { Fragment, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { API_URLS } from '../../../constants'
import { apiDelete, apiGet, apiPut } from '../../../utils/api'
import { getDateFromTimestamp, getTimestampFromDate } from '../../../utils/time'
import { SimpleModal } from '../../ui/modals/SimpleModal'
import { AddItemButton } from './AddItemButton'
import { Schedule } from './Schedule'

export interface ScheduleRecord {
  timestamps: (Date | null)[]
  command: string
  rrule: string[]
}

const DEFAULT_SCHEDULE = {
  command: 'START_GUARD_DOG',
  rrule: [],
  timestamps: [],
}

export const GET_ROBOT_SCHEDULES_KEY = 'getRobotSchedules'

export function ScheduleModal() {
  const [schedules, setSchedules] = useState<ScheduleRecord[]>([])
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const robotNum = queryParams.get('schedule') || ''

  const handleDismiss = () => {
    queryParams.delete('schedule')
    navigate(`/robots${queryParams.toString() ? '?' + queryParams.toString() : ''}`, { replace: true })
  }

  const {
    fetchStatus: robotSchedulesFetchStatus,
    data: robotSchedules,
    error: _credentialsError,
  } = useQuery({
    queryKey: [`${GET_ROBOT_SCHEDULES_KEY}_${robotNum}`],
    queryFn: async () => {
      const response = await apiGet<RobotSchedulesResponse>(API_URLS.robots.robotsSchedule(robotNum))
      return response
    },
  })

  const mutationEditSchedules = useMutation({
    mutationFn: async () => {
      await apiPut<RobotSchedulesResponse, ResponseSuccess>(API_URLS.robots.robotsSchedule(robotNum), {
        schedule: schedules.map((scheduleRec) => ({
          command: scheduleRec.command,
          rules: scheduleRec.rrule,
          times: scheduleRec.timestamps.map((date) => getTimestampFromDate(date!)),
        })),
      })
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
    onSuccess: () => {
      handleDismiss()
    },
  })

  const mutationDeleteSchedules = useMutation({
    mutationFn: async () => {
      await apiDelete<ResponseSuccess>(API_URLS.robots.robotsSchedule(robotNum))
    },
    onSuccess: () => {
      setSchedules([])
      handleDismiss()
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
  })

  useEffect(() => {
    if (robotSchedules && robotSchedules?.schedule?.length > 0) {
      setSchedules(
        robotSchedules?.schedule?.map((scheduleRecord) => ({
          command: scheduleRecord.command,
          rrule: scheduleRecord.rules,
          timestamps: scheduleRecord.times?.map((time) => {
            return getDateFromTimestamp(time)
          }),
        }))
      )
    } else {
      setSchedules([DEFAULT_SCHEDULE])
    }
  }, [robotSchedules])

  const handleSetSchedules = () => {
    mutationEditSchedules.mutate()
  }

  const handleDeleteSchedules = () => {
    mutationDeleteSchedules.mutate()
  }

  const isLoading =
    robotSchedulesFetchStatus === 'fetching' || mutationEditSchedules?.isPending || mutationDeleteSchedules?.isPending

  return (
    <SimpleModal
      onClose={handleDismiss}
      headerText={
        <>
          <span className='font-normal'>Robot num. </span> <span className='font-bold'>{robotNum} </span>
          <span className='font-normal'>schedule</span>
        </>
      }
      footer={
        <>
          <Button
            onClick={handleSetSchedules}
            color='blue'
            className='mr-2 rounded'
            isProcessing={mutationEditSchedules?.isPending}
          >
            Set{mutationEditSchedules?.isPending ? 'ting' : ''} Schedules
          </Button>
          <Button
            color='failure'
            onClick={handleDeleteSchedules}
            className='rounded'
            isProcessing={mutationDeleteSchedules?.isPending}
          >
            {mutationDeleteSchedules?.isPending ? 'Deleting' : 'Delete'} Schedules
          </Button>
        </>
      }
    >
      <div className={`${isLoading ? 'opacity-20' : ''}`}>
        {isLoading && <Spinner />}
        {schedules.map((schedule, idx) => (
          <Fragment key={idx}>
            <Schedule
              schedule={schedule}
              onUpdateSchedule={(newSchedule) =>
                setSchedules((prevSchedules) => {
                  const newSchedules = [...prevSchedules]
                  newSchedules[idx] = { ...newSchedule }
                  return newSchedules
                })
              }
              onRemoveSchedule={() => {
                setSchedules((prevSchedules) => {
                  prevSchedules.splice(idx, 1)
                  return [...prevSchedules]
                })
              }}
            />
            <hr className='mb-3' />
          </Fragment>
        ))}
        <AddItemButton
          onClick={() => {
            setSchedules((prevSchedules) => {
              const newSchedules = [...prevSchedules, DEFAULT_SCHEDULE]
              return newSchedules
            })
          }}
        >
          Add Schedule
        </AddItemButton>
      </div>
    </SimpleModal>
  )
}
