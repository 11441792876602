import { featureCollection, Position, simplify } from '@turf/turf'
import { DestinationMapLineStringFeature } from 'burro-map-utils'
import { FunctionComponent, useContext } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import toast from 'react-hot-toast'
import { lineSmoothingLine } from 'turf-extensions'
import { PanelDrawerLayoutMode } from '../ui/PanelDrawerLayout/PanelDrawerLayout'
import { DestinationMapLineStringFeatureForm } from './DestinationMapLineStringFeatureForm'
import { DestinationMapsContext } from './DestinationMapsContext'

export const DestinationMapsFormLineString: FunctionComponent<{}> = () => {
  const {
    selectedFeatureIds,
    destinationMapRenderState,
    setDestinationMapRenderState,
    setIsRightOpen,
    mode,
    setSelectedFeatureId,
  } = useContext(DestinationMapsContext)

  const selectedLineStringFeature = destinationMapRenderState?.features?.find(
    (value) => value.id === selectedFeatureIds[0]
  ) as DestinationMapLineStringFeature | undefined

  if (!selectedLineStringFeature) {
    return null
  }

  function handleCloseIfNecessary() {
    if (mode === PanelDrawerLayoutMode.DRAWER) {
      unstable_batchedUpdates(() => {
        setSelectedFeatureId([])
        setIsRightOpen(false)
      })
    }
  }

  return (
    <DestinationMapLineStringFeatureForm
      className='px-2 grid gap-2'
      lineString={selectedLineStringFeature}
      lengthUnits={'feet'}
      onValidWatch={(data) => {
        const newDestinationMap = structuredClone(destinationMapRenderState)
        for (const selectedFeatureId of selectedFeatureIds) {
          const indexOfFeature = destinationMapRenderState?.features?.findIndex(
            (value) => value.id === selectedFeatureId
          )
          if (indexOfFeature === undefined || indexOfFeature === -1) {
            return
          }
          newDestinationMap!.features![indexOfFeature].properties = data
        }
        setDestinationMapRenderState(newDestinationMap)
      }}
      onClickDelete={() => {
        const newDestinationMap = structuredClone(destinationMapRenderState)
        for (const selectedFeatureId of selectedFeatureIds) {
          const indexOfFeature = destinationMapRenderState?.features?.findIndex(
            (value) => value.id === selectedFeatureId
          )
          if (indexOfFeature === undefined || indexOfFeature === -1) {
            return
          }
          newDestinationMap!.features!.splice(indexOfFeature, 1)
        }
        setDestinationMapRenderState(newDestinationMap)
      }}
      onClickReverse={() => {
        const newDestinationMap = structuredClone(destinationMapRenderState)
        for (const selectedFeatureId of selectedFeatureIds) {
          const indexOfFeature = destinationMapRenderState?.features?.findIndex(
            (value) => value.id === selectedFeatureId
          )

          if (indexOfFeature === undefined || indexOfFeature === -1) {
            return
          }

          const feature = newDestinationMap?.features![indexOfFeature] as DestinationMapLineStringFeature

          // Create a new array with reversed coordinates instead of reversing in place
          const reversedCoordinates = [...feature.geometry.coordinates].reverse() as Position[]
          newDestinationMap!.features![indexOfFeature].geometry.coordinates = reversedCoordinates
        }
        setDestinationMapRenderState(newDestinationMap)
        handleCloseIfNecessary()
      }}
      onClickSimplify={({ tolerance }) => {
        const newDestinationMap = structuredClone(destinationMapRenderState)
        for (const selectedFeatureId of selectedFeatureIds) {
          const indexOfFeature = destinationMapRenderState?.features?.findIndex(
            (value) => value.id === selectedFeatureId
          )
          if (indexOfFeature === undefined || indexOfFeature === -1) {
            return
          }
          const newFeature = structuredClone(
            newDestinationMap?.features![indexOfFeature] as DestinationMapLineStringFeature
          )

          simplify(newFeature, { mutate: true, tolerance })
          newDestinationMap!.features![indexOfFeature] = newFeature
        }
        setDestinationMapRenderState(newDestinationMap)
        handleCloseIfNecessary()
      }}
      onClickSmoothen={() => {
        const newDestinationMap = structuredClone(destinationMapRenderState)
        for (const selectedFeatureId of selectedFeatureIds) {
          const indexOfFeature = destinationMapRenderState?.features?.findIndex(
            (value) => value.id === selectedFeatureId
          )
          if (indexOfFeature === undefined || indexOfFeature === -1) {
            return
          }
          const newFeature = structuredClone(
            newDestinationMap?.features![indexOfFeature] as DestinationMapLineStringFeature
          )

          newFeature.geometry.coordinates = lineSmoothingLine({
            coordinates: newFeature.geometry.coordinates ?? [],
            stanceDistance: 0.5,
            minimumRadius: 1.5,
            withOptions: {
              units: 'meters',
            },
          })
          newDestinationMap!.features![indexOfFeature] = newFeature
        }
        setDestinationMapRenderState(newDestinationMap)
        handleCloseIfNecessary()
      }}
      onClickCopy={() => {
        navigator.clipboard.writeText(
          JSON.stringify(
            featureCollection(
              (destinationMapRenderState?.features?.filter((feature) => selectedFeatureIds.includes(feature.id!)) ??
                []) as DestinationMapLineStringFeature[]
            )
          )
        )
        toast.success('GeoJSON copied to clipboard')
      }}
      onClickDownload={() => {
        const file = new Blob(
          [
            JSON.stringify(
              featureCollection(
                (destinationMapRenderState?.features?.filter((feature) => selectedFeatureIds.includes(feature.id!)) ??
                  []) as DestinationMapLineStringFeature[]
              )
            ),
          ],
          {
            type: 'application/json',
          }
        )
        const element = document.createElement('a')
        element.href = URL.createObjectURL(file)
        element.download = `${
          selectedLineStringFeature.properties.name?.trim() || 'LineString'
        }_${new Date().toISOString()}.geojson`
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
        toast.success('Downloaded')
      }}
    />
  )
}
