import type { UniqueIdentifier } from '@dnd-kit/core'
import { useQueryClient } from '@tanstack/react-query'
import { FeatureCollection } from '@turf/turf'
import { Button } from 'flowbite-react'
import type { Layer } from 'leaflet'
import { FeatureGroup, LayerGroup, Marker as LeafletMarker, Marker, Polyline } from 'leaflet'
import type { ArrowheadOptions } from 'leaflet-arrowheads'
import throttle from 'lodash.throttle'
import { useContext, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { usePreviousDifferent } from 'rooks'
import colors from 'tailwindcss/colors'
import { GET_FOLDERS_KEY } from '../../../constants'
import { RoutesListContext } from '../../../context/RoutesListContext'
import { ExtFeatureGroup, ExtPoi, ExtPolygon, ExtendedLayer } from '../../../types'
import { RoutesFeatureCollection } from '../../../types/RoutesFeaturedCollection'
import { TreeFolder } from '../../../types/TreeFolder'
import { TreeFolderOrShape, flattenGeoJSON, flattenGeoJSONArray } from '../../../types/TreeFolderOrShape'
import { TreePoint } from '../../../types/TreePoint'
import { TreePolygon } from '../../../types/TreePolygon'
import { TreeRoute } from '../../../types/TreeRoute'
import { EditedLayer } from '../../RoutesMap/GeomanDraw/GeomanDraw'
import { GEOJSON_NUM_PRECISION, handleLineMarkerDragEnd } from '../../RoutesMap/GeomanDraw/useGeomanDraw'
import { AtlasAvatar } from '../AtlasAvatar'
import { AtlasNavigationDropdown } from '../AtlasNavigationDropdown'
import { CustomSearchForm } from '../CustomSearchForm'
import { addShapeLayersToMap } from './addShapeLayersToMap'
import { BottomToolbar } from './BottomToolbar/BottomToolbar'
import { EditPoiModal } from './EditModal/EditPoiModal'
import { EditPolygonModal } from './EditModal/EditPolygonModal'
import { EditRouteModal } from './EditModal/EditRouteModal'
import { removeAllUnselectedRoutesWithSigns } from './removeAllUnselectedRoutesWithSigns'
import { SendToRobotModal } from './SendToRobotModal'
import { useAddedShapes, useDeletedShapes, useShapeInDrawMode } from './ShapesTree.hooks'
import type { RoutesTreeProps } from './ShapesTree.types'
import { SortableTree } from './SortableTree'
import { DisabledItem, DisabledItemTypes } from './TreeShapeItem/DisabledItem'
import { FolderItem } from './TreeShapeItem/FolderItem'
import { PoiItem } from './TreeShapeItem/PoiItem'
import { PolygonItem } from './TreeShapeItem/PolygonItem'
import { RouteItem } from './TreeShapeItem/RouteItem'
import { updateMapFocusToSelectedShapes } from './updateMapFocusToSelectedShapes'
import { findItemDeep, flattenTree } from './utilities'

export const DEFAULT_PATH_COLOR = '#3388ff'
export const DEFAULT_DRAW_PATH_COLOR = colors.lime[400]

export const getArrowHeadsOptions = ({ isShapeInDrawMode }: { isShapeInDrawMode: boolean }): ArrowheadOptions => ({
  size: '15px',
  color: isShapeInDrawMode ? DEFAULT_DRAW_PATH_COLOR : DEFAULT_PATH_COLOR,
  frequency: '200px',
  offsets: { end: '200px' },
})

export const DEFAULT_DISABLED_GEOMAN_OPTIONS = {
  allowRemoval: false,
  allowEditing: false,
  allowRotation: false,
  draggable: false,
  allowCutting: false,
}

export const DEFAULT_IMPORT_GEOJSON =
  '{ "type": "FeatureCollection",\n\t "features": [{\n\t\t "type": "Feature",\n\t\t "properties": {},\n\t\t "geometry": {\n\t\t "type": "LineString",\n\t\t\t "coordinates": ...\n\t\t }\n\t }]\n }'

export function ShapesTree({ context, treeProps, mapProps, editingCallbackProps, uiProps }: RoutesTreeProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const { scrollYPosition, setScrollYPosition } = useContext(RoutesListContext)
  const scrollYPositionRef = useRef(scrollYPosition)
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const editRouteId = queryParams.get('editRoute') || ''
  const editPolygonId = queryParams.get('editPolygon') || ''
  const editPoiId = queryParams.get('editPoi') || ''
  const duplicateRouteId = queryParams.get('duplicateRoute') || ''
  const pageModal = queryParams.get('pageModal') || ''
  const queryClient = useQueryClient()

  const handleRemoveStation = (e: { layer: Layer; shape: string }) => {
    if (e.shape === 'Marker') {
      const layer = e.layer as Marker
      const latLng = layer.getLatLng()
      const markerCoordinates = [latLng.lng, latLng.lat]
      let parentLayer: ExtFeatureGroup | null = null
      let markerToRemove = null
      context?.map?.eachLayer((mapLayer) => {
        const currLayer = mapLayer as ExtFeatureGroup
        if (currLayer instanceof LayerGroup && currLayer?.options?.routeId) {
          const featureLayers = currLayer?.getLayers?.()
          featureLayers?.forEach((featureLayer) => {
            if (featureLayer instanceof LeafletMarker) {
              const markerPoint = featureLayer.getLatLng()
              if (markerPoint === latLng) {
                markerToRemove = featureLayer
                parentLayer = currLayer
              }
            }
          })
        }
      })
      if (parentLayer) {
        const parentLayerGroup = parentLayer as ExtFeatureGroup
        const geoJsonGroup = parentLayerGroup.toGeoJSON(GEOJSON_NUM_PRECISION) as FeatureCollection<any, any>
        const newFeatures = geoJsonGroup.features.filter(
          (feature) =>
            feature.geometry.type !== 'Point' ||
            (feature.geometry.type === 'Point' &&
              feature.geometry.coordinates[0] !== markerCoordinates[0] &&
              feature.geometry.coordinates[1] !== markerCoordinates[1])
        )
        geoJsonGroup.features = newFeatures
        if (markerToRemove) {
          parentLayerGroup.removeLayer(markerToRemove)
        }
        mapProps.onSetPendingEditLayer?.({
          geojson: geoJsonGroup,
          layerId: parentLayerGroup._leaflet_id,
          routeId: parentLayerGroup?.options?.routeId,
          routeName: parentLayerGroup?.options?.routeName,
        })
      }
    }
  }

  const handleUpdateScrollPosition = (newYPosition: number) => {
    scrollYPositionRef.current = newYPosition
    setScrollYPosition(newYPosition)
  }

  useEffect(() => {
    const { current } = containerRef
    if (current) {
      current.scrollTo(0, scrollYPositionRef.current)
    }
  })

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef?.current) {
        const newScrollTop = containerRef.current.scrollTop
        handleUpdateScrollPosition(newScrollTop)
      }
    }

    const throttledHandleScroll = throttle(handleScroll, 200)

    const { current } = containerRef
    if (current) {
      current.addEventListener('scroll', throttledHandleScroll)
    }

    return () => {
      if (current) {
        current.removeEventListener('scroll', throttledHandleScroll)
      }
    }
  }, [])

  // scroll to created element in treeview
  useEffect(() => {
    if (containerRef?.current) {
      let createdItemId = treeProps.createdItem?.createdRoute?.routeIdToSelect as string
      if (createdItemId.length < 1) {
        createdItemId = treeProps.createdItem?.createdPolygon?.polygonIdToSelect as string
      }
      if (createdItemId.length < 1) {
        createdItemId = treeProps.createdItem?.createdPoi?.poiIdToSelect as string
      }
      if (createdItemId.length < 1) {
        createdItemId = treeProps.createdItem?.createdFolder?.folderIdToSelect as string
      }
      if (createdItemId.length > 0) {
        const treeItemNode = document.querySelector(`[data-id="${createdItemId}"]`) as HTMLElement
        if (treeItemNode) {
          const newScrollPosition = treeItemNode.offsetTop - containerRef.current.offsetTop
          containerRef.current.scrollTop = newScrollPosition
          handleUpdateScrollPosition(newScrollPosition)
        }
      }
    }
  }, [treeProps.createdItem])

  useEffect(() => {
    if (context?.map) {
      context.map.on('pm:actionclick', ({ text, btnName }) => {
        if ((btnName === 'editMode' || btnName === 'dragMode' || btnName === 'removalMode') && text === 'Finish') {
          handleFinishEdit()
        }
      })
    }
  }, [context?.map, mapProps.pendingEditLayer])

  // effect when geoman editMode, dragMode or removeMode was toggled
  const activeDrawing = mapProps.activeDrawing
  const previousIsActiveDrawingStations = usePreviousDifferent(activeDrawing.isDrawingStationsActive)
  const previousIsEditModeActive = usePreviousDifferent(activeDrawing.isEditModeActive)
  const previousIsDragModeActive = usePreviousDifferent(activeDrawing.isDragModeActive)
  const previousIsRemoveModeActive = usePreviousDifferent(activeDrawing.isRemoveModeActive)
  useEffect(() => {
    if (
      context?.map &&
      ((typeof previousIsEditModeActive == 'boolean' && previousIsEditModeActive !== activeDrawing.isEditModeActive) ||
        (typeof previousIsDragModeActive == 'boolean' && previousIsDragModeActive !== activeDrawing.isDragModeActive) ||
        (typeof previousIsRemoveModeActive == 'boolean' &&
          previousIsRemoveModeActive !== activeDrawing.isRemoveModeActive) ||
        (typeof previousIsActiveDrawingStations == 'boolean' &&
          previousIsActiveDrawingStations !== activeDrawing.isDrawingStationsActive))
    ) {
      if (
        activeDrawing.isEditModeActive ||
        activeDrawing.isDragModeActive ||
        activeDrawing.isRemoveModeActive ||
        activeDrawing.isDrawingStationsActive
      ) {
        removeAllUnselectedRoutesWithSigns(context.map, mapProps.shapeInDrawMode)
      }
      if (
        !activeDrawing.isEditModeActive &&
        !activeDrawing.isDragModeActive &&
        !activeDrawing.isRemoveModeActive &&
        !activeDrawing.isDrawingStationsActive
      ) {
        addShapeLayersToMap({
          context,
          tree: treeProps.tree,
          isEditModeActive: activeDrawing.isEditModeActive,
          isActiveDrawingStations: activeDrawing.isDrawingStationsActive,
          onEditRoute: editingCallbackProps.onEditRoute,
          onSetPendingEditLayer: mapProps.onSetPendingEditLayer,
          handleRemoveStation,
          shapeInDrawMode: mapProps.shapeInDrawMode,
          onSetSelectedTreeItem: treeProps.onSetSelectedTreeItem,
        })
      }
    }
  }, [
    previousIsActiveDrawingStations,
    activeDrawing.isDrawingStationsActive,
    previousIsEditModeActive,
    activeDrawing.isEditModeActive,
    previousIsDragModeActive,
    activeDrawing.isDragModeActive,
    previousIsRemoveModeActive,
    activeDrawing.isRemoveModeActive,
  ])

  const flatTree = flattenTree(treeProps.tree ?? [])
  const deletedItems = flatTree
    .filter((treeItem) => treeItem.disabled && (treeItem as unknown as TreeRoute)?.geojson)
    .map((treeItem) => treeItem.id)
  const previousDeletedItems = usePreviousDifferent(deletedItems)
  const wasRouteDeleted = deletedItems?.length !== previousDeletedItems?.length

  useEffect(() => {
    if (context?.map) {
      addShapeLayersToMap({
        tree: treeProps.tree,
        context,
        isEditModeActive: activeDrawing.isEditModeActive,
        isActiveDrawingStations: activeDrawing.isDrawingStationsActive,
        onEditRoute: editingCallbackProps.onEditRoute,
        onSetPendingEditLayer: mapProps.onSetPendingEditLayer,
        handleRemoveStation,
        shapeInDrawMode: mapProps.shapeInDrawMode,
        onSetSelectedTreeItem: treeProps.onSetSelectedTreeItem,
      })
      updateMapFocusToSelectedShapes({ map: context.map, selectedShapeIds: mapProps.routeIds.selected })
    }
  }, [context, wasRouteDeleted])

  useAddedShapes({
    context,
    tree: treeProps.tree,
    routeIds: mapProps.routeIds,
    polygonsIds: mapProps.polygonsIds,
    poiIds: mapProps.poiIds,
    onSetSelectedTreeItem: treeProps.onSetSelectedTreeItem,
    onSetPendingEditLayer: mapProps.onSetPendingEditLayer,
    onEditRoute: editingCallbackProps.onEditRoute,
    handleRemoveStation,
  })
  useDeletedShapes({
    context,
    routeIds: mapProps.routeIds,
    polygonsIds: mapProps.polygonsIds,
    poiIds: mapProps.poiIds,
  })
  const shapeIdInDrawMode = mapProps.shapeInDrawMode?.id
  useShapeInDrawMode({ context, shapeIdInDrawMode })

  const handleCreateRoute = ({
    routeIdToSelect,
    routeIdToUnselect,
  }: {
    routeIdToSelect: UniqueIdentifier
    routeIdToUnselect: UniqueIdentifier
  }) => {
    // if single route is selected
    editingCallbackProps.onSetCreatedShape((prev) => ({
      ...prev,
      createdRoute: { routeIdToSelect, routeIdToUnselect: shapeIdInDrawMode ? routeIdToUnselect : '' },
    }))
  }

  const handleSetEditablePolygon = (polygonId: string) => {
    const foundPolygon = findItemDeep(treeProps.tree, polygonId)
    if (foundPolygon) {
      queryParams.set('editPolygon', polygonId)
    } else {
      queryParams.delete('editPolygon')
    }
    navigate(`${location.pathname}?${queryParams.toString()}`)
  }

  const handleSetEditablePoi = (poiId: string) => {
    const foundPolygon = findItemDeep(treeProps.tree, poiId)
    if (foundPolygon) {
      queryParams.set('editPoi', poiId)
    } else {
      queryParams.delete('editPoi')
    }
    navigate(`${location.pathname}?${queryParams.toString()}`)
  }

  const handleSetDuplicateRoute = (routeId: string) => {
    const foundRoute = findItemDeep(treeProps.tree, routeId)
    if (foundRoute) {
      queryParams.set('duplicateRoute', foundRoute.id.toString())
    } else {
      queryParams.delete('duplicateRoute')
    }
    navigate(`${location.pathname}?${queryParams.toString()}`)
  }

  const handleFinishEdit = () => {
    if (mapProps.pendingEditLayer) {
      const layerType = (mapProps.pendingEditLayer as ExtendedLayer)?.options?.type
      if (layerType === 'polygon') {
        const polygonLayerOptions = (mapProps.pendingEditLayer as ExtPolygon)?.options
        if (polygonLayerOptions.polygonId && polygonLayerOptions.polygonName)
          editingCallbackProps.onEditPolygon?.({
            polygonId: polygonLayerOptions.polygonId,
            geojson: (mapProps.pendingEditLayer as ExtPolygon).toGeoJSON(GEOJSON_NUM_PRECISION),
            polygonName: polygonLayerOptions.polygonName,
          })
      }
      if (layerType === 'point') {
        const pointLayerOptions = (mapProps.pendingEditLayer as ExtPoi)?.options
        if (pointLayerOptions.poiId && pointLayerOptions.poiName)
          editingCallbackProps.onEditPoi?.({
            poiId: pointLayerOptions.poiId,
            geojson: (mapProps.pendingEditLayer as ExtPoi).toGeoJSON(GEOJSON_NUM_PRECISION),
            poiName: pointLayerOptions.poiName,
          })
      } else if (mapProps.pendingEditLayer instanceof Marker && context) {
        context?.map.eachLayer((layer) => {
          const featureGroup = layer as ExtFeatureGroup
          if (featureGroup instanceof FeatureGroup && featureGroup.options?.routeId === mapProps.shapeInDrawMode?.id) {
            editingCallbackProps.onEditRoute?.({
              geojson: featureGroup.toGeoJSON(GEOJSON_NUM_PRECISION) as RoutesFeatureCollection,
              layerId: featureGroup._leaflet_id,
              routeId: featureGroup.options?.routeId,
              routeName: featureGroup.options?.routeName,
            })
          }
        })
      } else if (mapProps.pendingEditLayer instanceof Polyline) {
        handleLineMarkerDragEnd(mapProps.pendingEditLayer, editingCallbackProps.onEditRoute)
      } else if ((mapProps.pendingEditLayer as EditedLayer)?.geojson) {
        editingCallbackProps.onEditRoute?.(mapProps.pendingEditLayer as EditedLayer)
      }
    }
  }

  const buttonPortal =
    mapProps.pendingEditLayer &&
    ReactDOM.createPortal(
      <div className='fixed bottom-3 left-0 mx-3 flex gap-3 sm:left-1/2 sm:-translate-x-1/2 sm:transform'>
        <Button
          color='blue'
          className={`rounded ${uiProps.isEditLoading ? 'opacity-70' : ''}`}
          onClick={handleFinishEdit}
          disabled={uiProps.isEditLoading}
        >
          Save pending changes
        </Button>
        <Button
          color='gray'
          className={`rounded`}
          onClick={async () => {
            mapProps.onSetPendingEditLayer?.(null)
            context?.map?.pm?.disableGlobalEditMode()
            await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
          }}
          disabled={uiProps.isEditLoading}
        >
          Cancel
        </Button>
      </div>,
      document.getElementById('portal-root')!
    )

  function handleConvertSelectedDataItemToGeoJSON({ dataItem }: { dataItem: TreeFolderOrShape }): FeatureCollection {
    const selectedItems = flatTree.filter((value) => value.selected) as TreeFolderOrShape[]
    const selectedFeaturesGeoJSON = flattenGeoJSONArray(selectedItems)
    const dataItemIsInSelectedItems = selectedItems.findIndex((selectedItem) => selectedItem.id === dataItem.id) !== -1
    if (dataItemIsInSelectedItems) {
      return selectedFeaturesGeoJSON
    } else {
      return flattenGeoJSON(dataItem)
    }
  }

  function handleOnClickCopy({ dataItem }: { dataItem: TreeFolderOrShape }) {
    navigator.clipboard.writeText(JSON.stringify(handleConvertSelectedDataItemToGeoJSON({ dataItem })))
    toast.success('Copied to clipboard')
  }

  function handleOnClickDownload({ dataItem }: { dataItem: TreeFolderOrShape }) {
    const file = new Blob([JSON.stringify(handleConvertSelectedDataItemToGeoJSON({ dataItem }))], {
      type: 'application/json',
    })
    const element = document.createElement('a')
    element.href = URL.createObjectURL(file)
    element.download = `Routes_${new Date().toISOString()}.geojson`
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
    toast.success('Downloaded')
  }

  return (
    <div className={`bg-white dark:bg-gray-800 h-full`}>
      <div className='flex items-center justify-between pb-2'>
        <div className='flex-1'>
          <AtlasAvatar className='' />
        </div>
        <div className='flex-1 flex justify-center'>
          <AtlasNavigationDropdown />
        </div>
        <div className='flex-1 flex justify-end'></div>
      </div>
      <div className={`relative flex h-[calc(100%-54px)] w-full flex-col ${uiProps.className ?? ''}`}>
        <CustomSearchForm placeholder='Search' />
        <div className='flex h-full min-h-0 flex-1 flex-col overflow-auto' ref={containerRef}>
          {treeProps.tree && Object.keys(treeProps.tree)?.length > 0 && (
            <SortableTree
              indicator
              tree={treeProps.tree}
              onSetTree={treeProps.onSetTree}
              onChangeFolderSingleItem={treeProps.onChangeFolderSingleItem}
              onChangeFolderMultipleItems={treeProps.onChangeFolderMultipleItems}
              labelAttribute='name'
              attributeToDisableDrop='geojson'
              isCollapsible={(dataItem) => dataItem.type === 'folder'}
              renderValue={(dataItem) => {
                const commonShapeProps = {
                  onSelectTreeItem: treeProps.onSetSelectedTreeItem,
                  key: dataItem.id,
                  isSelected: dataItem.selected,
                  onToggleSelection: treeProps.onToggleSelection,
                }
                if (dataItem?.id === 'trash-folder' || dataItem?.id === 'preview-folder' || dataItem?.disabled) {
                  let type = DisabledItemTypes.DeletedFolder
                  if (dataItem?.id === 'trash-folder') {
                    type = DisabledItemTypes.TrashFolder
                  }
                  if (dataItem?.id === 'preview-folder') {
                    type = DisabledItemTypes.PreviewFolder
                  }
                  if (dataItem.type === 'polyline') {
                    type = DisabledItemTypes.DeletedRoute
                  }
                  if (dataItem.type === 'polygon') {
                    type = DisabledItemTypes.DeletedPolygon
                  }
                  return <DisabledItem name={(dataItem as TreeFolder).name} type={type} id={dataItem?.id} />
                } else if (dataItem.type === 'polyline') {
                  return (
                    <RouteItem
                      route={dataItem as TreeRoute}
                      onSetEditableRoute={editingCallbackProps.onSetEditableRoute}
                      onSetDuplicateRoute={handleSetDuplicateRoute}
                      onClickCopy={() => handleOnClickCopy({ dataItem })}
                      onClickDownload={() => handleOnClickDownload({ dataItem })}
                      {...commonShapeProps}
                    />
                  )
                } else if (dataItem.type === 'polygon') {
                  return (
                    <PolygonItem
                      onSetEditablePolygon={handleSetEditablePolygon}
                      polygon={dataItem as TreePolygon}
                      onClickCopy={() => handleOnClickCopy({ dataItem })}
                      onClickDownload={() => handleOnClickDownload({ dataItem })}
                      {...commonShapeProps}
                    />
                  )
                } else if (dataItem.type === 'point') {
                  return (
                    <PoiItem
                      onSetEditablePoi={handleSetEditablePoi}
                      poi={dataItem as TreePoint}
                      onClickCopy={() => handleOnClickCopy({ dataItem })}
                      onClickDownload={() => handleOnClickDownload({ dataItem })}
                      {...commonShapeProps}
                    />
                  )
                } else {
                  return (
                    <FolderItem
                      folderName={(dataItem as TreeFolder).name}
                      folderId={dataItem.id}
                      onCreateSubFolder={treeProps.onCreateSubFolder}
                      onRemoveNode={treeProps.onRemoveNode}
                      onAddSubFolder={treeProps.onAddSubFolder}
                      onClickCopy={() => handleOnClickCopy({ dataItem })}
                      onClickDownload={() => handleOnClickDownload({ dataItem })}
                      onBulkUpload={editingCallbackProps.onBulkUploadItems}
                      {...commonShapeProps}
                      {...dataItem}
                    />
                  )
                }
              }}
              onCollapse={treeProps.onTreeItemCollapse}
            />
          )}
        </div>
        <BottomToolbar
          onImportRoute={editingCallbackProps.onImportRoute}
          isCreatingRoute={uiProps.isCreatingRoute}
          selectedRoutes={mapProps.routeIds.selected}
          onSetCreatedItem={treeProps.onSetCreatedItem}
        />
      </div>
      {editRouteId?.length > 0 && <EditRouteModal tree={treeProps.tree} />}
      {editPolygonId?.length > 0 && <EditPolygonModal tree={treeProps.tree} />}
      {editPoiId?.length > 0 && <EditPoiModal tree={treeProps.tree} />}
      {duplicateRouteId?.length > 0 && (
        <EditRouteModal tree={treeProps.tree} doDuplicate onCreatedRoute={handleCreateRoute} />
      )}
      {pageModal === 'sendToRobot' && <SendToRobotModal tree={treeProps.tree} />}
      {buttonPortal}
    </div>
  )
}
