import { bbox } from '@turf/turf'
import { latLngBounds, Map as LeafletMap } from 'leaflet'
import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react'
import { MapContainer } from 'react-leaflet'
import { GlobalStateContext } from '../../context/GlobalStateContext'
import { AtlasTileLayers } from '../ui/AtlasTileLayers'
import { LeafletGeosearchControl } from '../ui/LeafletGeosearchControl'
import { PolygonContext } from './PolygonContext'
import { PolygonEditor } from './PolygonEditor'

const POLYGON_MAP_CONTAINER_ID = 'polygon-map-container'

export const PolygonMapContainer: FunctionComponent = () => {
  const mapRef = useRef<LeafletMap | null>(null)
  const resizeObserverRef = useRef<ResizeObserver | null>(null)
  const { selectedPolygon, selectedPolygonId } = useContext(PolygonContext)
  const { bounds: boundsFromContext, setBounds: setMapContextBounds } = useContext(GlobalStateContext)
  const [boundsSetForIdState, setBoundsSetForIdState] = useState<string | null>(null)

  useEffect(() => {
    if (selectedPolygon && selectedPolygonId !== boundsSetForIdState) {
      try {
        const bounds = bbox(JSON.parse(selectedPolygon.geojson ?? ''))

        setBoundsSetForIdState(selectedPolygonId)
        setMapContextBounds({
          _northEast: { lat: bounds[3], lng: bounds[2] },
          _southWest: { lat: bounds[1], lng: bounds[0] },
        })
      } catch (e) {
        setMapContextBounds({
          _northEast: { lat: 90, lng: 180 },
          _southWest: { lat: -90, lng: -180 },
        })
      }
    }
  }, [selectedPolygonId, setMapContextBounds, boundsSetForIdState, selectedPolygon])

  useEffect(() => {
    return () => {
      resizeObserverRef && resizeObserverRef.current?.disconnect()
    }
  }, [])

  return (
    <MapContainer
      ref={mapRef}
      id={POLYGON_MAP_CONTAINER_ID}
      whenReady={() => {
        resizeObserverRef.current = new ResizeObserver(() => mapRef.current?.invalidateSize())
        const mapContainer = document.getElementById(POLYGON_MAP_CONTAINER_ID)
        if (mapContainer) {
          resizeObserverRef.current.observe(mapContainer)
        }
      }}
      bounds={
        boundsFromContext ? latLngBounds(boundsFromContext?._southWest, boundsFromContext?._northEast) : undefined
      }
      zoom={13}
      maxZoom={25}
      worldCopyJump={true}
      scrollWheelZoom={true}
      doubleClickZoom={false}
      zoomControl={false}
      className={`flex h-full w-full flex-1 z-0`}
    >
      <AtlasTileLayers />
      <PolygonEditor />
      <LeafletGeosearchControl />
    </MapContainer>
  )
}
