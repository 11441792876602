import { Feature, LineString, Polygon, Position } from '@turf/turf'
import Color from 'color'
import { Button } from 'flowbite-react'
import { LatLngBounds } from 'leaflet'
import { Fragment, FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react'
import { FaRoute, FaSave } from 'react-icons/fa'
import { Polyline, Polygon as ReactLeafletPolygon, useMap } from 'react-leaflet'
import Control from 'react-leaflet-custom-control'
import { GlobalStateContext } from '../../context/GlobalStateContext'
import { usePlanPathFieldMutation } from '../../hooks/mutations/usePlanPathFieldPostMutation'
import { useRoutesCreatePostMutation } from '../../hooks/mutations/useRoutesCreatePostQuery'
import {
  useCoverageWidthMeters,
  useCoverPerimeters,
  useMowPatternSkip,
  useOverlapPercent,
  useRobotWidthMeters,
  useSwathsAngleRadians,
  useSwathsVariant,
  useTurnRadiusMeters,
} from '../../hooks/useLocalStorage'
import { geojson_convertFromPositionsToLeafletLatLngs } from '../../utils/geojson_convertFromPositionsToLeafletLatLngs'
import { leaflet_areBoundsValid } from '../../utils/leaflet_areBoundsValid'
import { TextInputModal } from '../ui/modals/TextInputModal'
import { PolygonContext } from './PolygonContext'

export const PolygonEditor: FunctionComponent = () => {
  const leafletMapRef = useMap()
  const { bounds: boundsFromContext } = useContext(GlobalStateContext)
  const { selectedPolygon, excludedPolygons, setPlannedPath, setIsRightOpen } = useContext(PolygonContext)
  const {
    data: planPathFieldData,
    mutateAsync: planPathFieldMutateAsync,
    reset: planPathFieldMutationReset,
  } = usePlanPathFieldMutation()

  const [isShowingSavePlannedPath, setIsShowingSavePlannedPath] = useState(false)

  const routesPostCreateMutation = useRoutesCreatePostMutation()

  useEffect(() => {
    planPathFieldMutationReset()
  }, [selectedPolygon?.polygon_id])

  useEffect(() => {
    if (boundsFromContext) {
      const southWest = boundsFromContext._southWest
      const northEast = boundsFromContext._northEast

      if (leaflet_areBoundsValid(boundsFromContext)) {
        try {
          const bounds = new LatLngBounds(southWest, northEast)
          leafletMapRef.fitBounds(bounds, { animate: true })
        } catch (e) {
          console.error(e)
        }
      }
    }
  }, [boundsFromContext, leafletMapRef])

  function buildPolyline(params: { forCoordinates: Position[] }): ReactNode[] {
    const polylines: ReactNode[] = []
    const leafletLatLngs = geojson_convertFromPositionsToLeafletLatLngs(params.forCoordinates)
    const colorStart = Color('#00ff00')
    const colorEnd = Color('#ff0000')
    for (let i = 0; i < leafletLatLngs.length - 1; i++) {
      const factor = i / (leafletLatLngs.length - 2) // Calculate interpolation factor
      const interpolatedColor = colorStart.mix(colorEnd, factor).hex()
      polylines.push(<Polyline positions={[leafletLatLngs[i], leafletLatLngs[i + 1]]} color={interpolatedColor} />)
    }
    return polylines
  }

  const [coverageWidthMetersState] = useCoverageWidthMeters()
  const [turnRadiusMetersState] = useTurnRadiusMeters()
  const [swathsVariantState] = useSwathsVariant()
  const [robotWidthMetersState] = useRobotWidthMeters()
  const [mowPatternSkipState] = useMowPatternSkip()
  const [overlapPercentState] = useOverlapPercent()
  const [coverPerimetersState] = useCoverPerimeters()
  const [swathsAngleRadiansState] = useSwathsAngleRadians()

  const selectedPolygonGeojsonFeature = selectedPolygon?.geojson
    ? (JSON.parse(selectedPolygon?.geojson ?? '') as Feature<Polygon>)
    : null

  const excludedPolygonGeojsonFeatures = excludedPolygons?.map(
    (polygon) => JSON.parse(polygon?.geojson ?? '') as Feature<Polygon>
  )

  return (
    <Fragment>
      <Control prepend position='topleft' key='topleft-control'>
        <div className='flex flex-col items-left space-y-1 z-[9999]'>
          <Button
            size='sm'
            disabled={selectedPolygon?.geojson == undefined}
            onClick={async () => {
              if (selectedPolygon?.geojson == undefined) {
                return
              }
              const result = await planPathFieldMutateAsync({
                featureCollection: {
                  type: 'FeatureCollection',
                  features: [
                    {
                      type: 'Feature',
                      geometry: selectedPolygonGeojsonFeature?.geometry,
                      properties: {
                        type: 'coverage_area',
                      },
                    },
                    ...(excludedPolygonGeojsonFeatures?.map((feature) => ({
                      type: 'Feature',
                      geometry: feature.geometry,
                      properties: {
                        type: 'exclusion',
                      },
                    })) ?? []),
                  ],
                },
                coverageWidthMeters: coverageWidthMetersState.toString(),
                turnRadiusMeters: turnRadiusMetersState.toString(),
                swathsVariant: swathsVariantState?.toString(),
                robotWidthMeters: robotWidthMetersState.toString(),
                mowPatternSkip: mowPatternSkipState.toString(),
                overlapPercent: overlapPercentState.toString(),
                swathsAngleRadians: swathsAngleRadiansState?.toString(),
                coverPerimeters: coverPerimetersState.toString(),
              })
              setPlannedPath(result?.features[0] as unknown as Feature<LineString>)
            }}
          >
            <FaRoute size={24} />
          </Button>
          <Button
            size='sm'
            disabled={planPathFieldData == undefined}
            onClick={async () => {
              if (planPathFieldData == undefined) {
                return
              }
              setIsShowingSavePlannedPath(true)
            }}
          >
            <FaSave size={24} />
          </Button>
        </div>
      </Control>
      {selectedPolygonGeojsonFeature && (
        <ReactLeafletPolygon
          color='#0000ff'
          positions={geojson_convertFromPositionsToLeafletLatLngs(
            selectedPolygonGeojsonFeature.geometry.coordinates[0]
          )}
          eventHandlers={{
            click: () => {
              setIsRightOpen(true)
            },
          }}
        />
      )}
      {excludedPolygonGeojsonFeatures?.map((feature, index) => (
        <ReactLeafletPolygon
          key={index}
          color='#ff0000'
          positions={geojson_convertFromPositionsToLeafletLatLngs(feature.geometry.coordinates[0])}
        />
      ))}
      {planPathFieldData &&
        buildPolyline({
          forCoordinates: planPathFieldData.features[0].geometry!.coordinates! as unknown as Position[],
        })}

      <TextInputModal
        headerText='Save planned path'
        textInputProps={{ placeholder: 'ex. "Mow mission 007"' }}
        labelText='Route name'
        helperText='This planned path will become available in the routes tab of Atlas after creation.'
        show={isShowingSavePlannedPath}
        onClose={() => {
          setIsShowingSavePlannedPath(false)
        }}
        onValidSubmit={async ({ data }) => {
          if (planPathFieldData == undefined) {
            return
          }
          await routesPostCreateMutation.mutateAsync({
            routesPostRequest: {
              customer: selectedPolygon?.customer ?? 'NA',
              name: `${data.text}_${new Date().toLocaleString()}`,
              geojson_string: JSON.stringify(planPathFieldData),
            },
          })
          setIsShowingSavePlannedPath(false)
        }}
      />
    </Fragment>
  )
}
