import { ExtFeatureGroup } from '../../../types'
import { TreeFolderOrShape } from '../../../types/TreeFolderOrShape'
import { LeafletContextInterface } from '../../RoutesMap/LeafletContextInterfaceOrNull'
import { removeSignsFromAllRoutes } from './RouteSigns'
import type { TreeItem } from './types'

export function removeAllUnselectedRoutesWithSigns(
  map: LeafletContextInterface['map'],
  shapeInDrawMode: TreeItem<TreeFolderOrShape> | undefined | null
) {
  map.eachLayer((layer) => {
    const layerWithOptions = layer as ExtFeatureGroup
    if (
      shapeInDrawMode &&
      layerWithOptions?.options?.routeId?.length &&
      layerWithOptions?.options?.routeName?.length &&
      layerWithOptions?.options?.routeId !== shapeInDrawMode.id
    ) {
      map.removeLayer(layer)
    }
  })
  removeSignsFromAllRoutes(map)
}
