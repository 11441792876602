import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from '@floating-ui/react'
import type { ButtonHTMLAttributes, Dispatch, HTMLProps, ReactNode, SetStateAction } from 'react'
import {
  cloneElement,
  createContext,
  FocusEvent,
  forwardRef,
  MouseEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

// source https://codesandbox.io/s/admiring-lamport-5wt3yg?file=/src/DropdownMenu.tsx
const MenuContext = createContext<{
  getItemProps: (userProps?: HTMLProps<HTMLElement>) => Record<string, unknown>
  activeIndex: number | null
  setActiveIndex: Dispatch<SetStateAction<number | null>>
  setHasFocusInside: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
}>({
  getItemProps: (_) => {
    return {}
  },
  activeIndex: null,
  setActiveIndex: () => {},
  setHasFocusInside: () => {},
  isOpen: false,
})

interface MenuProps {
  label: string
  nested?: boolean
  children?: ReactNode
  TriggerComponent?: ReactNode
}

export const CustomMenuComponent = forwardRef<HTMLButtonElement, MenuProps & HTMLProps<HTMLButtonElement>>(
  ({ children, label, TriggerComponent, ...rest }, forwardedRef) => {
    const [isOpen, setIsOpen] = useState(false)
    const [hasFocusInside, setHasFocusInside] = useState(false)
    const [activeIndex, setActiveIndex] = useState<number | null>(null)

    const elementsRef = useRef<Array<HTMLButtonElement | null>>([])
    const labelsRef = useRef<Array<string | null>>([])
    const parent = useContext(MenuContext)

    const tree = useFloatingTree()
    const nodeId = useFloatingNodeId()
    const parentId = useFloatingParentNodeId()
    const item = useListItem()

    const isNested = parentId != null

    const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
      nodeId,
      open: isOpen,
      onOpenChange: setIsOpen,
      placement: isNested ? 'right-start' : 'bottom-end',
      middleware: [offset({ mainAxis: isNested ? 0 : 4, alignmentAxis: isNested ? -4 : 0 }), flip(), shift()],
      whileElementsMounted: autoUpdate,
    })

    const hover = useHover(context, {
      enabled: isNested,
      delay: { open: 75 },
      handleClose: safePolygon({ blockPointerEvents: true }),
    })
    const click = useClick(context, {
      event: 'mousedown',
      toggle: !isNested,
      ignoreMouse: isNested,
    })
    const role = useRole(context, { role: 'menu' })
    const dismiss = useDismiss(context, { bubbles: true })
    const listNavigation = useListNavigation(context, {
      listRef: elementsRef,
      activeIndex,
      nested: isNested,
      onNavigate: setActiveIndex,
    })
    const typeahead = useTypeahead(context, {
      listRef: labelsRef,
      onMatch: isOpen ? setActiveIndex : undefined,
      activeIndex,
    })

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
      hover,
      click,
      role,
      dismiss,
      listNavigation,
      typeahead,
    ])

    // Event emitter allows you to communicate across tree components.
    // This effect closes all menus when an item gets clicked anywhere
    // in the tree.
    useEffect(() => {
      if (!tree) return

      function handleTreeClick() {
        setIsOpen(false)
      }

      function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
        if (event.nodeId !== nodeId && event.parentId === parentId) {
          setIsOpen(false)
        }
      }

      tree.events.on('click', handleTreeClick)
      tree.events.on('menuopen', onSubMenuOpen)

      return () => {
        tree.events.off('click', handleTreeClick)
        tree.events.off('menuopen', onSubMenuOpen)
      }
    }, [tree, nodeId, parentId])

    useEffect(() => {
      if (isOpen && tree) {
        tree.events.emit('menuopen', { parentId, nodeId })
      }
    }, [tree, isOpen, nodeId, parentId])

    const parentProps = parent.getItemProps({
      ...rest,
      onFocus(event: FocusEvent<HTMLButtonElement>) {
        rest.onFocus?.(event)
        setHasFocusInside(false)
        parent.setHasFocusInside(true)
      },
    })

    const { onClick, ...restReferenceProps } = getReferenceProps(parentProps)

    const menuButtonProps = {
      ref: useMergeRefs([refs.setReference, item.ref, forwardedRef]),
      tabIndex: !isNested ? undefined : parent.activeIndex === item.index ? 0 : -1,
      role: isNested ? 'menuitem' : undefined,
      'data-open': isOpen ? '' : undefined,
      'data-nested': isNested ? '' : undefined,
      'data-focus-inside': hasFocusInside ? '' : undefined,
      className: isNested ? 'min-w-[110px] !justify-start border-none py-4 outline-none' : 'cursor-pointer',
      ...restReferenceProps,
      onClick: (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        ;(onClick as (e: MouseEvent<HTMLElement>) => VoidFunction)(e)
      },
    }

    return (
      <FloatingNode id={nodeId}>
        {!TriggerComponent && (
          <button {...menuButtonProps}>
            {TriggerComponent ? (
              TriggerComponent
            ) : (
              <>
                {label}
                {isNested && (
                  <span aria-hidden style={{ marginLeft: 10, fontSize: 10 }}>
                    ▶
                  </span>
                )}
              </>
            )}
          </button>
        )}
        {TriggerComponent &&
          !isNested &&
          cloneElement(<button>{TriggerComponent}</button>, {
            ...menuButtonProps,
          })}
        <MenuContext.Provider
          value={{
            activeIndex,
            setActiveIndex,
            getItemProps,
            setHasFocusInside,
            isOpen,
          }}
        >
          <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
            {isOpen && (
              <FloatingPortal>
                <FloatingFocusManager
                  context={context}
                  modal={false}
                  initialFocus={isNested ? -1 : 0}
                  returnFocus={!isNested}
                >
                  <div
                    ref={refs.setFloating}
                    className='z-[3000] flex flex-col overflow-hidden rounded-lg border border-solid border-gray-200 bg-white shadow-lg outline-none [&>*:first-child]:border-none'
                    style={floatingStyles}
                    {...getFloatingProps()}
                  >
                    {children}
                  </div>
                </FloatingFocusManager>
              </FloatingPortal>
            )}
          </FloatingList>
        </MenuContext.Provider>
      </FloatingNode>
    )
  }
)

export const CustomDropdownMenuItem = forwardRef<
  HTMLButtonElement,
  {
    label: string
    disabled?: boolean
    iconComponent?: ReactNode
  } & ButtonHTMLAttributes<HTMLButtonElement>
>(({ children, label, disabled, iconComponent: Icon, ...props }, forwardedRef) => {
  const menu = useContext(MenuContext)
  const item = useListItem({ label: disabled ? null : label })
  const tree = useFloatingTree()
  const isActive = item.index === menu.activeIndex

  return (
    <button
      {...props}
      ref={useMergeRefs([item.ref, forwardedRef])}
      type='button'
      role='menuitem'
      className={`flex w-full flex-1 items-center justify-start gap-2 border-t border-gray-200 px-4 py-3  text-sm font-medium not-italic leading-5
        outline-none hover:bg-gray-100`}
      tabIndex={isActive ? 0 : -1}
      disabled={disabled}
      {...menu.getItemProps({
        onClick(event: MouseEvent<HTMLButtonElement>) {
          props.onClick?.(event)
          tree?.events.emit('click')
        },
        onFocus(event: FocusEvent<HTMLButtonElement>) {
          props.onFocus?.(event)
          menu.setHasFocusInside(true)
        },
      })}
    >
      {Icon}
      {children}
    </button>
  )
})

export const CustomDropdown = forwardRef<HTMLButtonElement, MenuProps & HTMLProps<HTMLButtonElement>>((props, ref) => {
  const parentId = useFloatingParentNodeId()

  if (parentId === null) {
    return (
      <FloatingTree>
        <CustomMenuComponent {...props} ref={ref} />
      </FloatingTree>
    )
  }

  return <CustomMenuComponent {...props} ref={ref} />
})
