import { Units, length } from '@turf/turf'
import type { DestinationMapLineStringFeature, DestinationMapLineStringProperties } from 'burro-map-utils'
import { Button, Checkbox, Label, RangeSlider, Select, TextInput, ToggleSwitch } from 'flowbite-react'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { BURRO_MAXIMUM_SPEED_MPS, BURRO_MINIMUM_SPEED_MPS, MPS_TO_MPH_CONVERSION_FACTOR } from '../../constants'

export const DestinationMapLineStringFeatureForm: FunctionComponent<{
  className?: string
  lengthUnits?: Units | undefined
  lineString: DestinationMapLineStringFeature
  onClickCopy: () => void
  onClickDelete: () => void
  onClickDownload: () => void
  onClickReverse: () => void
  onClickSimplify: (params: { tolerance: number }) => void
  onClickSmoothen: () => void
  onValidSubmit?: SubmitHandler<DestinationMapLineStringProperties>
  onValidWatch?: (data: DestinationMapLineStringProperties) => void
}> = ({
  className,
  lengthUnits,
  lineString,
  onClickCopy,
  onClickDelete,
  onClickDownload,
  onClickReverse,
  onClickSimplify,
  onClickSmoothen,
  onValidSubmit,
  onValidWatch,
}) => {
  const { properties } = lineString

  const [toleranceState, setToleranceState] = useState<number>(0.000001)
  const [hasSpeedLimitState, setHasSpeedLimitState] = useState<boolean>(
    properties.speed_limit !== 0.0 && properties.speed_limit !== null
  )

  const { register, handleSubmit, control, setValue, watch, reset, trigger, formState, setError } =
    useForm<DestinationMapLineStringProperties>({
      defaultValues: {
        name: properties.name,
        direction: properties.direction,
        speed_limit: properties.speed_limit,
        enabled: properties.enabled === undefined ? true : properties.enabled === true,
      },
    })

  const watchedValues = watch()
  const watchedValuesRef = useRef<DestinationMapLineStringProperties | null>(null)

  useEffect(() => {
    if (
      onValidWatch &&
      !formState.isValidating &&
      formState.isValid &&
      JSON.stringify(watchedValues) !== JSON.stringify(watchedValuesRef.current)
    ) {
      const aWatchedValues = watchedValuesRef.current
      watchedValuesRef.current = watchedValues
      if (aWatchedValues !== null) {
        onValidWatch({
          ...properties,
          ...watchedValues,
        })
      }
    }
  }, [watchedValues, onValidWatch, properties, formState])

  useEffect(() => {
    if (!hasSpeedLimitState) {
      setValue('speed_limit', 0.0)
    }
  }, [hasSpeedLimitState])

  useEffect(() => {
    reset({
      name: properties.name,
      direction: properties.direction,
      speed_limit: properties.speed_limit,
      enabled: properties.enabled === undefined ? true : properties.enabled === true,
    })
    setHasSpeedLimitState(properties.speed_limit !== 0.0 && properties.speed_limit !== null)
  }, [lineString.id])

  var lengthOfLineString = 0

  try {
    lengthOfLineString = Number(
      length(lineString, {
        units: lengthUnits,
      }).toFixed(2)
    )
  } catch (e) {
    // intentionally do nothing
  }

  return (
    <form
      className={className}
      onSubmit={handleSubmit((data) => {
        if (onValidSubmit) {
          onValidSubmit({
            ...properties,
            ...data,
          })
        }
      })}
    >
      <Label className='block' htmlFor='name'>
        Name{' '}
        {lengthOfLineString !== 0 && lengthUnits && (
          <span className='text-xs text-gray-500 dark:text-gray-400'>({lengthOfLineString + ' ' + lengthUnits})</span>
        )}
      </Label>
      <TextInput id='name' type='text' placeholder={`Name`} {...register('name')} />
      <div className='flex items-center gap-2'>
        <Checkbox id='enabled' {...register('enabled', { required: false })} />
        <Label htmlFor='enabled'>Enabled</Label>
      </div>
      <Label className='block' htmlFor='direction'>
        Direction
      </Label>
      <Select id='direction' required {...register('direction', { required: true })}>
        <option value='one_way'>One Way</option>
        <option value='two_way'>Two Way</option>
      </Select>
      {properties.direction === 'one_way' && (
        <Button color='gray' onClick={onClickReverse}>
          Reverse
        </Button>
      )}
      <div className='flex items-center justify-between'>
        <Label className='block' htmlFor='speed_limit'>
          Speed limit
        </Label>
        <ToggleSwitch checked={hasSpeedLimitState} onChange={() => setHasSpeedLimitState(!hasSpeedLimitState)} />
      </div>
      <Controller
        control={control}
        name='speed_limit'
        rules={{
          validate: (value) => {
            if (value < BURRO_MINIMUM_SPEED_MPS || value > BURRO_MAXIMUM_SPEED_MPS) {
              setError('speed_limit', {
                message: `Speed limit must be between ${BURRO_MINIMUM_SPEED_MPS} and ${BURRO_MAXIMUM_SPEED_MPS} m/s`,
              })
              return false
            } else {
              setError('speed_limit', { message: undefined })
              return true
            }
          },
        }}
        render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
          const mphNumber = Math.round(Number(value ?? 0) * MPS_TO_MPH_CONVERSION_FACTOR * 100) / 100
          const mpmString = `${mphNumber < 0.01 ? '<' : ''}${mphNumber.toFixed(2)}mph`
          return (
            <div>
              <div className='relative flex items-center'>
                <TextInput
                  disabled={!hasSpeedLimitState}
                  type='number'
                  inputMode='decimal'
                  className='flex-1'
                  id='speed_limit'
                  placeholder='0'
                  color={error?.message ? 'failure' : 'gray'}
                  onBlur={onBlur}
                  onChange={async (e) => {
                    onChange(e.target.value === '' ? 0.0 : parseFloat(e.target.value))
                    await trigger('speed_limit')
                  }}
                  value={value}
                  step={0.000001}
                  min={BURRO_MINIMUM_SPEED_MPS}
                  max={BURRO_MAXIMUM_SPEED_MPS}
                />
                <span className='text-gray-500 italic' style={{ position: 'absolute', right: '7em' }}>
                  m/s
                </span>
                <span className='ml-1 text-sm text-gray-500 dark:text-gray-400'>{mpmString}</span>
              </div>
              {error && <p className='text-sm text-red-500'>{error.message}</p>}
            </div>
          )
        }}
      />

      <Label className='block'>Simplification</Label>
      <RangeSlider
        id='sm-range'
        value={toleranceState}
        min={0.000000000000001}
        max={0.000005}
        step={0.0000000000001}
        onChange={(e) => {
          setToleranceState(e.target.valueAsNumber)
        }}
      />
      <Button onClick={() => onClickSimplify({ tolerance: toleranceState })}>Simplify</Button>
      <Button onClick={() => onClickSmoothen()}>Smoothen</Button>

      <Button type='button' color='failure' onClick={onClickDelete}>
        Delete
      </Button>
      <Button color='gray' onClick={() => onClickCopy()}>
        Copy GeoJSON
      </Button>
      <Button color='gray' onClick={() => onClickDownload()}>
        Download GeoJSON
      </Button>
      {onValidSubmit && <Button type='submit'>Save</Button>}
    </form>
  )
}
