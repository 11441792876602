import { Button } from 'flowbite-react'
import { Close, Plus } from 'flowbite-react-icons/outline'
import { FunctionComponent, useContext, useState } from 'react'
import { useDestinationMapsGetQuery } from '../../hooks/queries/useDestinationMapsGetQuery'
import { AtlasAvatar } from '../ui/AtlasAvatar'
import { AtlasNavigationDropdown } from '../ui/AtlasNavigationDropdown'
import { TextInputModal } from '../ui/modals/TextInputModal'
import { PanelDrawerLayoutMode } from '../ui/PanelDrawerLayout/PanelDrawerLayout'
import { DestinationMapsContext } from './DestinationMapsContext'
import { DestinationMapsListEntry } from './DestinationMapsListEntry'

export const DestinationMapsPanelDrawerLeft: FunctionComponent<{}> = () => {
  const [isAddFolderOpen, setIsAddFolderOpen] = useState(false)
  const getManyQuery = useDestinationMapsGetQuery()
  const { selectedDestinationMapIndex, addDestinationMap, setIsLeftOpen, mode } = useContext(DestinationMapsContext)
  const destinationMapsSortedFromQuery = (getManyQuery.data?.results ?? []).sort(
    (a, b) => a.name?.localeCompare(b.name ?? '') ?? 0
  )

  return (
    <>
      <div className={`flex h-full w-full flex-col gap-2`}>
        <div className='flex items-center justify-between'>
          <div className='flex-1'>
            <AtlasAvatar className='pr-2' />
          </div>
          <div className='flex-1 flex justify-center'>
            <AtlasNavigationDropdown />
          </div>
          <div className='flex-1 flex justify-end'>
            <Button
              onClick={() => setIsLeftOpen(false)}
              size='sm'
              color='gray'
              className={`border-0 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white ${mode === PanelDrawerLayoutMode.DRAWER ? 'visible' : 'invisible'}`}
            >
              <Close size={14} />
            </Button>
          </div>
        </div>
        <div className={`flex-grow overflow-auto divide-y rounded-lg border border-gray-200 dark:border-gray-700`}>
          {selectedDestinationMapIndex !== null &&
            destinationMapsSortedFromQuery.length > selectedDestinationMapIndex && (
              <DestinationMapsListEntry
                className={`px-4 py-2 cursor-default`}
                destinationMap={destinationMapsSortedFromQuery[selectedDestinationMapIndex]}
                index={selectedDestinationMapIndex}
              />
            )}
        </div>
        <Button onClick={() => setIsAddFolderOpen(true)} size='sm' color='gray'>
          <Plus className='mr-2' /> New Map
        </Button>
      </div>
      <TextInputModal
        headerText='New Destination Map'
        textInputProps={{ placeholder: 'ex. "Main Facility"' }}
        labelText='Name'
        helperText='Creating a new map will sync it to all robots, and if maps overlap, the newest one is selected.'
        show={isAddFolderOpen}
        onClose={() => {
          setIsAddFolderOpen(false)
        }}
        onValidSubmit={({ data }) => {
          addDestinationMap(data.text)
          setIsAddFolderOpen(false)
        }}
      />
    </>
  )
}
