import { QueryCache, QueryClient } from '@tanstack/react-query'
import axios from 'axios'
import toast from 'react-hot-toast'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      onError: (error) => {
        if (axios.isAxiosError(error) && !error.message?.includes('/var/tiles')) {
          toast(`${error.message}`)
        }
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (axios.isAxiosError(error)) {
        toast(`${error.message}`)
      } else if (error.message.includes('/var/tiles') == false) {
        toast(`${JSON.stringify(error)}`)
      } else {
        console.error('error', error, query)
      }
    },
  }),
})
