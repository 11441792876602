import { Feature, LineString } from '@turf/turf'
import { AtlasPolygon } from 'burro-web-api-js-client'
import { createContext } from 'react'
import { PanelDrawerLayoutMode } from '../ui/PanelDrawerLayout/PanelDrawerLayout'

interface PolygonContextProps {
  mode: PanelDrawerLayoutMode
  selectedPolygonId: string | null
  selectedPolygon: AtlasPolygon | null
  polygons: AtlasPolygon[] | null
  plannedPath: Feature<LineString> | null
  setSelectedPolygonId: (index: string | null) => void
  isLeftOpen: boolean
  isRightOpen: boolean
  setIsLeftOpen: (isOpen: boolean) => void
  setIsRightOpen: (isOpen: boolean) => void
  excludedPolygonIds: string[] | null
  excludedPolygons: AtlasPolygon[] | null
  addExcludedPolygonId: (index: string) => void
  removeExcludedPolygonId: (index: string) => void
  setExcludedPolygonIds: (values: string[] | null) => void
  setPlannedPath: (path: Feature<LineString> | null) => void
}

export const PolygonContext = createContext<PolygonContextProps>({
  mode: PanelDrawerLayoutMode.DRAWER,
  selectedPolygonId: null,
  selectedPolygon: null,
  polygons: null,
  plannedPath: null,
  setSelectedPolygonId: (_index: string | null) => {},
  isLeftOpen: false,
  isRightOpen: false,
  setIsLeftOpen: (_isOpen: boolean) => {},
  setIsRightOpen: (_isOpen: boolean) => {},
  excludedPolygonIds: null,
  excludedPolygons: null,
  addExcludedPolygonId: (_index: string) => {},
  removeExcludedPolygonId: (_index: string) => {},
  setExcludedPolygonIds: (_values: string[] | null) => {},
  setPlannedPath: (_path: Feature<LineString> | null) => {},
})
