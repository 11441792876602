import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ResponseError, ResponseSuccess } from 'api-types'
import { Button } from 'flowbite-react'
import type { UpdatePolygonBodyParams } from 'modules/polygons/types'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { API_URLS, GET_FOLDERS_KEY } from '../../../../constants'
import { useCustomForm } from '../../../../hooks/useCustomForm'
import { TreeFolderOrShape } from '../../../../types/TreeFolderOrShape'
import { TreePolygon, isTreePolygon } from '../../../../types/TreePolygon'
import { apiPost } from '../../../../utils/api'
import { SimpleModal } from '../../modals/SimpleModal'
import type { TreeItems } from '../types'
import { findItemDeep } from '../utilities'

interface RouteForm {
  name: string
}

export function EditPolygonModal({ tree }: { tree: TreeItems<TreeFolderOrShape> }) {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const editPolygonId = queryParams.get('editPolygon') || ''
  const treeItem = findItemDeep(tree, editPolygonId)
  let polygon: TreePolygon | null = null
  if (treeItem && isTreePolygon(treeItem)) {
    polygon = treeItem
  }

  const { values, onChange } = useCustomForm<RouteForm>({
    name: polygon ? polygon?.name : '',
  })
  const queryClient = useQueryClient()

  const mutationEditPolygon = useMutation({
    mutationFn: async () => {
      const { name } = values
      if (polygon?.id) {
        await apiPost<UpdatePolygonBodyParams, ResponseSuccess>(API_URLS.polygons.editPolygon, {
          polygonId: polygon?.id,
          geojson: JSON.stringify(polygon?.geojson),
          polygonName: name,
        })
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      handleDismiss()
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
  })

  const handleDismiss = () => {
    queryParams.delete('editPolygon')
    navigate(`/${queryParams.toString() ? '?' + queryParams.toString() : ''}`, { replace: true })
  }

  const handleUpdateRoute = () => {
    mutationEditPolygon.mutate()
  }

  const isLoading = mutationEditPolygon.isPending

  let buttonLabel = ''
  if (isLoading) {
    buttonLabel = 'Updating'
  } else {
    buttonLabel = 'Update'
  }

  return (
    <SimpleModal
      onClose={handleDismiss}
      headerText={
        <>
          <span className='font-normal'>Edit Polygon</span> <span className='font-bold'>{polygon?.name}</span>
        </>
      }
      footer={
        <Button onClick={handleUpdateRoute} className='mr-2 rounded' color='blue' isProcessing={isLoading}>
          {buttonLabel} Polygon
        </Button>
      }
    >
      <div className={`${isLoading ? 'opacity-20' : ''}`}>
        <form className='flex flex-col gap-6'>
          <div>
            <div className='mb-2 block'>
              <label htmlFor='name' className='mb-2 block text-sm font-medium text-gray-900'>
                Polygon name
              </label>
            </div>
            <input
              id='name'
              type='name'
              placeholder='Polygon name'
              name='name'
              required={true}
              onChange={onChange}
              value={values.name}
              className='block rounded border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
            />
          </div>
        </form>
      </div>
    </SimpleModal>
  )
}
