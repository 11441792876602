import bbox from '@turf/bbox'
import { latLngBounds, Map as LeafletMap } from 'leaflet'
import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react'
import { MapContainer } from 'react-leaflet'
import { GlobalStateContext } from '../../context/GlobalStateContext'
import { useDestinationMapGetQuery } from '../../hooks/queries/useDestinationMapGetQuery'
import { AtlasTileLayers } from '../ui/AtlasTileLayers'
import { LeafletGeosearchControl } from '../ui/LeafletGeosearchControl'
import { DestinationMapEditorComponent } from './DestinationMapEditorComponent'
import { DestinationMapsContext } from './DestinationMapsContext'

const DESTINATION_MAP_CONTAINER_ID = 'destination-map-container'

export const DestinationMapsMapContainer: FunctionComponent<{ className?: string }> = ({ className }) => {
  const mapRef = useRef<LeafletMap | null>(null)
  const resizeObserverRef = useRef<ResizeObserver | null>(null)

  const { bounds: boundsFromContext, setBounds: setMapContextBounds } = useContext(GlobalStateContext)
  const { selectedDestinationMapIndex, selectedDestinationMapUid } = useContext(DestinationMapsContext)
  const [boundsSetForIndexState, setBoundsSetForIndexState] = useState<number | null>(null)

  const getOneQuery = useDestinationMapGetQuery({
    destinationMapUid: selectedDestinationMapUid ?? undefined,
  })
  const selectedDestinationMap = getOneQuery.data?.geojson_data

  useEffect(() => {
    if (selectedDestinationMap && selectedDestinationMapIndex !== boundsSetForIndexState) {
      try {
        const bounds = bbox(selectedDestinationMap)

        setBoundsSetForIndexState(selectedDestinationMapIndex)
        setMapContextBounds({
          _northEast: { lat: bounds[3], lng: bounds[2] },
          _southWest: { lat: bounds[1], lng: bounds[0] },
        })
      } catch (e) {
        setMapContextBounds({
          _northEast: { lat: 90, lng: 180 },
          _southWest: { lat: -90, lng: -180 },
        })
      }
    }
  }, [selectedDestinationMapIndex, setMapContextBounds, boundsSetForIndexState, selectedDestinationMap])

  useEffect(() => {
    return () => {
      resizeObserverRef && resizeObserverRef.current?.disconnect()
    }
  }, [])

  return (
    <MapContainer
      ref={mapRef}
      id={DESTINATION_MAP_CONTAINER_ID}
      style={{ zIndex: 0 }}
      whenReady={() => {
        resizeObserverRef.current = new ResizeObserver(() => mapRef.current?.invalidateSize())
        const mapContainer = document.getElementById(DESTINATION_MAP_CONTAINER_ID)
        if (mapContainer) {
          resizeObserverRef.current.observe(mapContainer)
        }
      }}
      bounds={
        boundsFromContext ? latLngBounds(boundsFromContext?._southWest, boundsFromContext?._northEast) : undefined
      }
      zoom={13}
      maxZoom={25}
      worldCopyJump={true}
      scrollWheelZoom={true}
      doubleClickZoom={false}
      zoomControl={false}
      tapTolerance={60}
      tap={true}
      className={className}
    >
      <AtlasTileLayers />
      <DestinationMapEditorComponent />
      <LeafletGeosearchControl />
    </MapContainer>
  )
}
