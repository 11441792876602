import { useQuery } from '@tanstack/react-query'
import type { Device } from 'modules/devices/types'
import { useState } from 'react'
import { API_URLS } from '../../constants'
import { apiGet } from '../../utils/api'
import { RoutesMapContainer } from '../RoutesMap/RoutesMapContainer'
import { RobotsList } from './RobotsList'

export const GET_ROBOTS_KEY = 'getAllRobots'

export function RobotsPage() {
  const [selectedRobot, setSelectedRobot] = useState({} as Device)

  const {
    fetchStatus: robotsFetchStatus,
    data: robotsList,
    error: _sitesError,
  } = useQuery({
    queryKey: [GET_ROBOTS_KEY],
    queryFn: async () => {
      const response = await apiGet<Device[]>(API_URLS.robots.getRobots)
      return response
    },
  })

  const isLoading = robotsFetchStatus === 'fetching'

  return (
    <RoutesMapContainer
      uiProps={{
        containerClassName: `flex h-full w-full flex-1 ${isLoading ? 'opacity-20' : ''}`,
        leftContent: (
          <RobotsList
            robotsList={robotsList}
            isLoading={isLoading}
            selectedRobot={selectedRobot}
            onSetSelectedRobot={(selectedRobot) => setSelectedRobot(selectedRobot)}
          />
        ),
      }}
      deviceProps={{
        devicesLocations: robotsList,
        selectedDevice: selectedRobot,
        onSetSelectedDevice: (selectedRobot) => setSelectedRobot(selectedRobot),
      }}
    />
  )
}
