import { lineString, point } from '@turf/helpers'
import pointToLineDistance from '@turf/point-to-line-distance'
import type { Position } from 'geojson'
import type { LatLngTuple, Layer, PM } from 'leaflet'
import { DomUtil, FeatureGroup, LatLng, Marker, Polygon, Polyline, featureGroup, geoJSON, icon, marker } from 'leaflet'
import { useEffect, useRef } from 'react'

import poiDrawIconImg from '../../../assets/poi-draw-icon.png'
import poiIconImg from '../../../assets/poi-icon.png'
import type {
  ExtFeatureGroup,
  ExtPoi,
  ExtPolyline,
  ExtendedMarkerOptions,
  StationMarker,
  StationMeasures,
} from '../../../types'
import type { RoutesFeatureCollection } from '../../../types/RoutesFeaturedCollection'
import type { LeafletContextInterfaceOrNull } from '../LeafletContextInterfaceOrNull'

import { Feature, LineString, Point } from '@turf/turf'
import { StationIcon, type EditedLayer, type GeomanDrawProps } from './GeomanDraw'
import { getStationLayerMetadataFromLineCoords, handleLineMarkerDrag, handleLineVertexAdded } from './stationReposition'

export const PoiIcon = icon({
  iconUrl: poiIconImg,
  className: 'poi-marker-icon',
  iconSize: [20, 20],
  iconAnchor: [10, 20],
})

export const PoiDrawIcon = icon({
  iconUrl: poiDrawIconImg,
  className: 'poi-draw-marker-icon',
  iconSize: [20, 20],
  iconAnchor: [10, 20],
})

export const GEOJSON_NUM_PRECISION = 20

export const getStationPopupForm = (formId: string) =>
  `<form id="${formId}" class="flex max-w-xs flex-col items-start gap-3">\
<div class="mt-1 flex flex-col items-start">\
  <label for="station-name" class="mb-1">Name:</label>\
  <input id="station-name" type="text" class="mr-3 block w-40 rounded border border-gray-300 bg-gray-50 p-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500 sm:text-xs" />\
</div>\
<div class="flex flex-col gap-1">\
  <span class="flex">Stop type:</span>\
  <div class="flex">\
    <div class="mr-4 flex items-center">\
      <input id="robot-default-radio" type="radio" value="" name="stop-type" class="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500" />\
      <label for="robot-default-radio" class="ml-2 flex items-center text-sm font-medium text-gray-900 whitespace-nowrap">Robot Default</label>\
    </div>\
    <div class="mr-4 flex items-center">\
      <input id="duration-radio" type="radio" value="" name="stop-type" class="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500" />\
      <label for="duration-radio" class="ml-2 flex items-center text-sm font-medium text-gray-900">Duration</label>\
    </div>\
    <div class="flex items-center">\
      <input id="infinite-radio" type="radio" value="" name="stop-type" class="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500" />\
      <label for="infinite-radio" class="ml-2 text-sm font-medium text-gray-900">Indefinite</label>\
    </div>\
  </div>\
  <div class="flex items-center" id="duration-input-container"><input required id="station-stop-duration" type="number" step="1" min="0" class="mr-1 block w-40 rounded border border-gray-300 bg-gray-50 p-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500 sm:text-xs" /> sec</div>\
</div>\
<button type="submit" class="inline-flex shrink-0 items-center rounded border border-blue-700 bg-white px-3 py-2 text-center text-xs font-medium text-blue-700 hover:bg-blue-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300">Submit</button>\
</form>`

const handleCommonDragEnd = (layer: Layer, onEditRoute?: (editedLayer: EditedLayer) => void) => {
  const line = layer as ExtPolyline
  const parentLayerGroup = line?._pmLastGroupFetch?.groups?.[0]
  if (parentLayerGroup) {
    if (parentLayerGroup?.options?.routeId?.length) {
      onEditRoute?.({
        geojson: parentLayerGroup.toGeoJSON(GEOJSON_NUM_PRECISION) as RoutesFeatureCollection,
        layerId: parentLayerGroup._leaflet_id,
        routeId: parentLayerGroup.options.routeId,
        routeName: parentLayerGroup.options.routeName,
      })
    }
  }
}

export const handleLineMarkerDragEnd = (layer: Layer, onEditRoute?: (editedLayer: EditedLayer) => void) => {
  handleCommonDragEnd(layer, onEditRoute)
}

export const handleLineDragEnd = (
  e: {
    layer: Layer
    shape: string
  },
  onEditRoute?: (editedLayer: EditedLayer) => void
) => {
  handleCommonDragEnd(e.layer, onEditRoute)
}

let lastSnappedCoordinate: Position | null | undefined = null

export const handleMarkerSnap = (e: {
  shape: string
  distance: number
  layer: Layer
  workingLayer: Layer
  marker: Marker<any>
  layerInteractedWith: Layer
  segement: any
  snapLatLng: LatLng
}) => {
  lastSnappedCoordinate = null
  const editedMarker = e.layer as StationMarker
  const snapPosition = [e.snapLatLng.lng, e.snapLatLng.lat]
  const snapLayerCoords = (e.layerInteractedWith as Layer & { feature: Feature<LineString> })?.feature?.geometry
    ?.coordinates
  const snappedCoordinate = snapLayerCoords?.find((coord) => coord.toString() === snapPosition.toString())
  lastSnappedCoordinate = snappedCoordinate
  editedMarker._icon.classList.add('full')
}

export const handleMarkerUnSnap = (e: {
  shape: string
  distance: number
  layer: Layer
  workingLayer: Layer
  marker: Marker<any>
  layerInteractedWith: Layer
  segement: any
  snapLatLng: LatLng
}) => {
  const editedMarker = e.layer as StationMarker
  editedMarker._icon.classList.remove('full')
  editedMarker._icon.classList.add('transparent')
}

export const handleAddOrUpdateStation = ({
  layer,
  context,
  addStation,
  onEditRoute,
  markerMetadata,
}: {
  layer: Layer
  context: LeafletContextInterfaceOrNull
  addStation: boolean
  onEditRoute?: (editedLayer: EditedLayer) => void
  markerMetadata?: Record<string, string>
}) => {
  const createdMarker = layer as StationMarker
  createdMarker._icon.classList.add('full')
  createdMarker.on('pm:snap', handleMarkerSnap)
  createdMarker.on('pm:unsnap', handleMarkerUnSnap)
  let featureGroupToUpdate: ExtFeatureGroup | null = null
  let markerStationMeasures: StationMeasures | null = null
  context?.map?.eachLayer((layer) => {
    if (layer instanceof FeatureGroup) {
      const featureGroup = layer as ExtFeatureGroup
      let isMarkerInsideFeature = false
      featureGroup.getLayers().forEach((feature) => {
        if (feature instanceof Polyline) {
          const linePoints = feature.getLatLngs() as LatLng[]
          const lineCoords: LatLngTuple[] = linePoints.map((linePoint) => [linePoint.lng, linePoint.lat])
          const markerPoint = createdMarker.getLatLng()
          const markerCoords: LatLngTuple = [markerPoint.lng, markerPoint.lat]
          let indexToAddCoordinate = -1
          // find line segment where station was added
          let minDistance = 10000
          for (let i = 1; i < lineCoords.length; i++) {
            const startLinePointCoords = lineCoords[i - 1]
            const endLinePointCoords = lineCoords[i]
            const turfPoint = point([markerPoint.lng, markerPoint.lat])
            const turfLine = lineString([startLinePointCoords, endLinePointCoords] as Position[])
            const distanceFromLine = pointToLineDistance(turfPoint, turfLine)
            if (distanceFromLine < 0.01) {
              isMarkerInsideFeature = true
              featureGroupToUpdate = featureGroup
              if (distanceFromLine < minDistance) {
                minDistance = distanceFromLine
                indexToAddCoordinate = i
              }
            }
          }
          // add coordinate into segment where station was added
          const doesLineCoordExist = lineCoords.some((lineCoord) => lineCoord.toString() === markerCoords.toString())
          if (indexToAddCoordinate !== -1 && !doesLineCoordExist) {
            let updatedLineCoords = [...lineCoords]
            updatedLineCoords.splice(indexToAddCoordinate, 0, markerCoords)
            updatedLineCoords = updatedLineCoords.map((coords) => [coords[1], coords[0]])
            feature.setLatLngs(updatedLineCoords)
          }
          markerStationMeasures = getStationLayerMetadataFromLineCoords(lineCoords, markerCoords)
          if (markerStationMeasures) {
            createdMarker.options = {
              ...createdMarker.options,
              stationMeasures: {
                ...markerStationMeasures,
              },
            }
          }
        }
      })
      if (isMarkerInsideFeature) {
        const markerLatLng = createdMarker.getLatLng()

        const feature = {
          type: 'Feature',
          properties: {
            point_type: 'station',
            ...markerMetadata,
          },
          geometry: {
            type: 'Point',
            coordinates: [markerLatLng.lng, markerLatLng.lat],
          },
        }

        const station = geoJSON<Point>(feature as any, {
          pointToLayer: function (_feature, latlng) {
            return marker(latlng, {
              ...createdMarker.options,
              stationMeasures: {
                ...markerStationMeasures,
              },
              icon: StationIcon,
            } as ExtendedMarkerOptions)
          },
        })
        if (addStation) {
          featureGroup.addLayer(station)
        }
      }
    }
  })
  if (featureGroupToUpdate !== null) {
    const featureGroup = featureGroupToUpdate as ExtFeatureGroup
    if (featureGroup?.options?.routeId?.length) {
      onEditRoute?.({
        geojson: featureGroup.toGeoJSON(GEOJSON_NUM_PRECISION) as RoutesFeatureCollection,
        layerId: featureGroup._leaflet_id,
        routeId: featureGroup.options.routeId,
        routeName: featureGroup.options.routeName,
      })
    }
  }
}

export const useGeomanDraw = ({
  context,
  onCreateRoute,
  onCreatePolygon,
  onCreatePointOfInterest,
  onEditRoute,
  onSetPendingEditLayer,
  onToggleEditMode,
  onToggleCutMode,
  onToggleRotateMode,
  onToggleRemoveMode,
  onToggleDragMode,
  onToggleStationsDrawing,
  activeDrawing,
}: Pick<
  GeomanDrawProps,
  | 'onCreateRoute'
  | 'onCreatePolygon'
  | 'onCreatePointOfInterest'
  | 'onEditRoute'
  | 'onSetPendingEditLayer'
  | 'onToggleEditMode'
  | 'onToggleDragMode'
  | 'onToggleRemoveMode'
  | 'onToggleStationsDrawing'
  | 'onToggleCutMode'
  | 'onToggleRotateMode'
  | 'activeDrawing'
> & { context: LeafletContextInterfaceOrNull }) => {
  const isActiveDrawingPoiInternal = useRef(false)

  useEffect(() => {
    const mapContextRef = context?.map
    if (mapContextRef?.pm) {
      mapContextRef?.on('pm:create', (e: { layer: Layer; shape: PM.SUPPORTED_SHAPES }) => {
        if (e.layer && (e.layer as unknown as Layer & { pm: { enable: () => void } })?.pm) {
          if (e.shape === 'Line') {
            const line = e.layer as ExtPolyline
            line.on('pm:vertexadded', handleLineVertexAdded)
            line.on('pm:markerdrag', handleLineMarkerDrag)
            line.on('pm:markerdragend', (e) => {
              handleLineMarkerDragEnd(e.layer, onEditRoute)
            })
            line.on('pm:vertexremoved', (e) => {
              handleLineMarkerDragEnd?.(e.layer, onEditRoute)
            })
            line.on('pm:dragend', (e) => {
              handleLineDragEnd(e, onEditRoute)
            })

            //create a feature group where dragging is synced with markers
            const layerGroup = featureGroup([line]).addTo(mapContextRef) as ExtFeatureGroup
            const layerGroupId = layerGroup._leaflet_id
            const layerGroupGeoJson = layerGroup.toGeoJSON(GEOJSON_NUM_PRECISION) as RoutesFeatureCollection
            layerGroup?.pm.setOptions({ syncLayersOnDrag: true }) // drag the whole group

            // show route popup to add metadata
            let wasRouteCreated = false
            const lineWithPopup = line
              .bindPopup(
                '<form id="line-popup-form">\
                    <label for="route-name">Name:</label>\
                    <div class="flex items-center mt-1">\
                    <input required id="route-name" type="text" class="mr-3 block w-40 rounded border border-gray-300 bg-gray-50 p-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500 sm:text-xs"/>\
                    <button type="submit" class="inline-flex shrink-0 items-center rounded border border-blue-700 bg-white px-3 py-2 text-center text-xs font-medium text-blue-700 hover:bg-blue-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300">Add</button>\
                    </div>\
                </form>',
                { closeOnClick: false }
              )
              .on('popupopen', function (this: ExtPolyline) {
                const formEl = document.querySelector('#line-popup-form') as HTMLFormElement
                formEl?.addEventListener('submit', (e) => {
                  e.preventDefault()
                  const name = (formEl.querySelector('#route-name') as HTMLInputElement)?.value
                  wasRouteCreated = true
                  onCreateRoute?.({
                    geojson: layerGroupGeoJson,
                    layerId: layerGroupId,
                    routeName: name,
                  })
                  this.closePopup()
                  this.unbindPopup()
                })
              })
              .on('popupclose', function (this: ExtPolyline) {
                if (!wasRouteCreated) {
                  mapContextRef.removeLayer(layerGroup as FeatureGroup)
                }
              })
            lineWithPopup.openPopup()
          }
          if (e.shape === 'Marker') {
            const createdMarker = e.layer as Marker
            if (isActiveDrawingPoiInternal.current) {
              // show poi popup to add metadata
              let wasPoiCreated = false
              const markerWithPopup = createdMarker
                .bindPopup(
                  '<form id="poi-popup-form">\
                    <label for="poi-name">Name:</label>\
                    <div class="flex items-center mt-1">\
                    <input required id="poi-name" type="text" class="mr-3 block w-40 rounded border border-gray-300 bg-gray-50 p-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500 sm:text-xs"/>\
                    <button type="submit" class="inline-flex shrink-0 items-center rounded border border-blue-700 bg-white px-3 py-2 text-center text-xs font-medium text-blue-700 hover:bg-blue-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300">Add</button>\
                    </div>\
                </form>',
                  { closeOnClick: false }
                )
                .on('popupopen', function (this: Polygon) {
                  const formEl = document.querySelector('#poi-popup-form') as HTMLFormElement
                  formEl?.addEventListener('submit', (e) => {
                    e.preventDefault()
                    const name = (formEl.querySelector('#poi-name') as HTMLInputElement)?.value
                    wasPoiCreated = true
                    onCreatePointOfInterest?.({
                      geojson: createdMarker.toGeoJSON(GEOJSON_NUM_PRECISION),
                      poiName: name,
                    })
                    this.closePopup()
                    this.unbindPopup()
                  })
                })
                .on('popupclose', function (this: Polygon) {
                  if (!wasPoiCreated) {
                    mapContextRef.removeLayer(createdMarker)
                  }
                })
              markerWithPopup.openPopup()
            } else {
              if (lastSnappedCoordinate) {
                createdMarker.setLatLng(new LatLng(lastSnappedCoordinate[1], lastSnappedCoordinate[0]))
              }
              let wasMarkerCreated = false
              const popupFormId = 'marker-popup-form'
              const markerWithPopup = createdMarker
                .bindPopup(getStationPopupForm(popupFormId), { closeOnClick: false })
                .on('popupopen', function (this: StationMarker) {
                  const form = document.querySelector(`#${popupFormId}`) as HTMLFormElement

                  const durationRadio = form.querySelector('#duration-radio') as HTMLInputElement
                  const indefiniteRadio = form.querySelector('#infinite-radio') as HTMLInputElement
                  const robotDefaultRadio = form.querySelector('#robot-default-radio') as HTMLInputElement
                  const durationContainer = form.querySelector('#duration-input-container') as HTMLDivElement
                  const durationInput = form.querySelector('#station-stop-duration') as HTMLInputElement
                  robotDefaultRadio.checked = true

                  // function to toggle the visibility of the duration input
                  function toggleDurationInputVisibility() {
                    if (durationRadio.checked) {
                      durationContainer.style.display = 'flex'
                      durationInput.required = true
                    } else if (indefiniteRadio.checked || robotDefaultRadio.checked) {
                      durationContainer.style.display = 'none'
                      durationInput.required = false
                    }
                  }

                  // attach the event listeners to the radio buttons
                  durationRadio.addEventListener('change', toggleDurationInputVisibility)
                  indefiniteRadio.addEventListener('change', toggleDurationInputVisibility)
                  robotDefaultRadio.addEventListener('change', toggleDurationInputVisibility)

                  // call the function initially to set the correct state based on the pre-checked radio button
                  toggleDurationInputVisibility()

                  form?.addEventListener('submit', (e) => {
                    e.preventDefault()
                    const name = (form.querySelector('#station-name') as HTMLInputElement)?.value
                    const durationRadioVal = durationRadio?.checked
                    const infiniteRadioVal = indefiniteRadio?.checked
                    const robotDefaultVal = robotDefaultRadio?.checked
                    const durationVal = durationInput?.value
                    const markerMetadata = {
                      ...(name?.length > 0 ? { name } : {}),
                      ...(robotDefaultVal ? {} : { stop_type: infiniteRadioVal ? 'indefinite' : 'duration' }),
                      ...(durationRadioVal ? { stop_duration_in_sec: durationVal } : {}),
                    }
                    wasMarkerCreated = true
                    handleAddOrUpdateStation({ layer: createdMarker, context, addStation: true, markerMetadata })
                    onSetPendingEditLayer?.(createdMarker)
                    this.closePopup()
                    this.unbindPopup()
                  })
                })
                .on('popupclose', function (this: StationMarker) {
                  if (!wasMarkerCreated) {
                    mapContextRef.removeLayer(e.layer)
                  }
                })
              markerWithPopup.openPopup()
            }
          }
          if (e.shape === 'Polygon' || e.shape === 'Rectangle') {
            const polygon = e.layer
            if (polygon instanceof Polygon) {
              // show polygon popup to add metadata
              let wasPolygonCreated = false
              const lineWithPopup = polygon
                .bindPopup(
                  '<form id="polygon-popup-form">\
                    <label for="polygon-name">Name:</label>\
                    <div class="flex items-center mt-1">\
                    <input required id="polygon-name" type="text" class="mr-3 block w-40 rounded border border-gray-300 bg-gray-50 p-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500 sm:text-xs"/>\
                    <button type="submit" class="inline-flex shrink-0 items-center rounded border border-blue-700 bg-white px-3 py-2 text-center text-xs font-medium text-blue-700 hover:bg-blue-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300">Add</button>\
                    </div>\
                </form>',
                  { closeOnClick: false }
                )
                .on('popupopen', function (this: Polygon) {
                  const formEl = document.querySelector('#polygon-popup-form') as HTMLFormElement
                  formEl?.addEventListener('submit', (e) => {
                    e.preventDefault()
                    const name = (formEl.querySelector('#polygon-name') as HTMLInputElement)?.value
                    wasPolygonCreated = true
                    onCreatePolygon?.({
                      geojson: polygon.toGeoJSON(GEOJSON_NUM_PRECISION),
                      polygonName: name,
                    })
                    this.closePopup()
                    this.unbindPopup()
                  })
                })
                .on('popupclose', function (this: Polygon) {
                  if (!wasPolygonCreated) {
                    mapContextRef.removeLayer(polygon)
                  }
                })
              lineWithPopup.openPopup()
            }
          }
        }
      })

      mapContextRef?.on('pm:drawstart', (e: { workingLayer: Layer; shape: PM.SUPPORTED_SHAPES }) => {
        if (e.shape === 'Marker') {
          const marker = e.workingLayer as StationMarker
          marker._icon.classList.add('transparent')
          marker.on('pm:snap', handleMarkerSnap)
          marker.on('pm:unsnap', handleMarkerUnSnap)
        } else {
          context?.map?.eachLayer((layer) => {
            if (layer instanceof Marker) {
              const poiLayer = layer as ExtPoi
              const poiId = poiLayer?.options?.poiId
              if (poiId) {
                DomUtil.addClass(poiLayer._icon, 'disable-interaction')
              }
            }
          })
        }
      })

      mapContextRef?.on('pm:drawend', (e) => {
        if (e.shape !== 'Marker') {
          context?.map?.eachLayer((layer) => {
            if (layer instanceof Marker) {
              const poiLayer = layer as ExtPoi
              const poiId = poiLayer?.options?.poiId
              if (poiId) {
                DomUtil.removeClass(poiLayer._icon, 'disable-interaction')
              }
            }
          })
        }
      })

      mapContextRef?.on('pm:globaleditmodetoggled', ({ enabled }) => {
        if (!enabled) {
          // if we switch to another mode, at first all modes are disabled and after that the another mode is enabled
          setTimeout(() => {
            onToggleEditMode?.(enabled)
          }, 0)
        } else {
          onToggleEditMode?.(enabled)
        }
      })

      mapContextRef?.on('pm:globalremovalmodetoggled', ({ enabled }) => {
        if (!enabled) {
          // if we switch to another mode, at first all modes are disabled and after that the another mode is enabled
          setTimeout(() => {
            onToggleRemoveMode?.(enabled)
          }, 0)
        } else {
          onToggleRemoveMode?.(enabled)
        }
      })

      mapContextRef?.on('pm:globaldragmodetoggled', ({ enabled }) => {
        if (!enabled) {
          // if we switch to another mode, at first all modes are disabled and after that the another mode is enabled
          setTimeout(() => {
            onToggleDragMode?.(enabled)
          }, 0)
        } else {
          onToggleDragMode?.(enabled)
        }
      })

      mapContextRef?.on('pm:globalcutmodetoggled', ({ enabled }) => {
        if (!enabled) {
          // if we switch to another mode, at first all modes are disabled and after that the another mode is enabled
          setTimeout(() => {
            onToggleCutMode?.(enabled)
          }, 0)
        } else {
          onToggleCutMode?.(enabled)
        }
      })

      mapContextRef?.on('pm:globalrotatemodetoggled', ({ enabled }) => {
        if (!enabled) {
          // if we switch to another mode, at first all modes are disabled and after that the another mode is enabled
          setTimeout(() => {
            onToggleRotateMode?.(enabled)
          }, 0)
        } else {
          onToggleRotateMode?.(enabled)
        }
      })

      mapContextRef?.on('pm:globaldrawmodetoggled', (e) => {
        if (isActiveDrawingPoiInternal.current === false) {
          if (e.shape === 'Marker') {
            onToggleStationsDrawing?.(e.enabled)
          }
        }
      })
    }
  }, [context?.map])

  // allow drawing stations
  useEffect(() => {
    if (context?.map?.pm) {
      if (activeDrawing?.isDrawingStationsActive === false) {
        context.map.pm.disableDraw('Marker')
      } else if (activeDrawing?.isDrawingStationsActive) {
        context.map.pm.enableDraw('Marker', { requireSnapToFinish: true })
        context.map.pm.addControls({
          drawMarker: true,
        })
      }
    }
  }, [activeDrawing?.isDrawingStationsActive, context?.map?.pm])

  useEffect(() => {
    if (context?.map?.pm) {
      if (activeDrawing?.isDrawingPoiActive === false) {
        context.map.pm.disableDraw('Marker')
        context.map.pm.setGlobalOptions({ markerStyle: { icon: StationIcon } })
        context.map.pm.setLang(
          'en',
          {
            tooltips: {
              placeMarker: 'Click to place a station',
            },
          },
          'en'
        )
        isActiveDrawingPoiInternal.current = false
        const addStationBtn = document.querySelector(
          '.button-container[title="Add station"] .leaflet-buttons-control-button'
        )
        const addStationsActions = document.querySelector(
          '.button-container[title="Add station"] .leaflet-pm-actions-container '
        )
        addStationBtn?.classList?.remove('pm-disabled')
        addStationsActions?.classList?.remove('hidden')
      } else if (activeDrawing?.isDrawingPoiActive) {
        isActiveDrawingPoiInternal.current = true
        context.map.pm.setLang(
          'en',
          {
            tooltips: {
              placeMarker: 'Click to place a Point of Interest',
            },
          },
          'en'
        )
        context.map.pm.enableDraw('Marker')
        context.map.pm.setGlobalOptions({ markerStyle: { icon: PoiIcon } })
        const addStationContainer = document.querySelector('.button-container[title="Add station"]')
        const addStationBtn = document.querySelector(
          '.button-container[title="Add station"] .leaflet-buttons-control-button'
        )
        const addStationsActions = document.querySelector(
          '.button-container[title="Add station"] .leaflet-pm-actions-container '
        )
        addStationBtn?.classList?.add('pm-disabled')
        addStationsActions?.classList?.add('hidden')
        addStationContainer?.classList?.remove('active')
      }
    }
  }, [activeDrawing?.isDrawingPoiActive, context?.map?.pm])
}
