import { FunctionComponent, PropsWithChildren } from 'react'
import { Panel, PanelGroup, PanelProps } from 'react-resizable-panels'
import { PanelDrawer, PanelDrawerProps } from './PanelDrawer' // Adjust the import path as necessary

export enum PanelDrawerLayoutMode {
  DRAWER = 'drawer',
  PANEL = 'panel',
}

interface PanelDrawerLayoutProps extends PropsWithChildren {
  childrenPanelProps?: PanelProps
  left: PanelDrawerProps
  right: PanelDrawerProps
  mode: PanelDrawerLayoutMode
}

export const PanelDrawerLayout: FunctionComponent<PanelDrawerLayoutProps> = ({
  children,
  childrenPanelProps,
  left,
  right,
  mode,
}) => {
  return (
    <PanelGroup direction='horizontal'>
      <PanelDrawer {...left} mode={mode} position='left' />
      <Panel {...childrenPanelProps}>{children}</Panel>
      <PanelDrawer {...right} mode={mode} position='right' />
    </PanelGroup>
  )
}
