import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ResponseError, ResponseSuccess } from 'api-types'
import { Button } from 'flowbite-react'
import type { UpdatePointBodyParams } from 'modules/points/types'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { API_URLS, GET_FOLDERS_KEY } from '../../../../constants'
import { useCustomForm } from '../../../../hooks/useCustomForm'
import { TreeFolderOrShape } from '../../../../types/TreeFolderOrShape'
import { TreePoint, isTreePoint } from '../../../../types/TreePoint'
import { apiPost } from '../../../../utils/api'
import { SimpleModal } from '../../modals/SimpleModal'
import type { TreeItems } from '../types'
import { findItemDeep } from '../utilities'

interface RouteForm {
  name: string
}

export function EditPoiModal({ tree }: { tree: TreeItems<TreeFolderOrShape> }) {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const editPoiId = queryParams.get('editPoi') || ''
  const treeItem = findItemDeep(tree, editPoiId)
  let poi: TreePoint | null = null
  if (treeItem && isTreePoint(treeItem)) {
    poi = treeItem
  }

  const { values, onChange } = useCustomForm<RouteForm>({
    name: poi ? poi?.name : '',
  })
  const queryClient = useQueryClient()

  const mutationEditPoi = useMutation({
    mutationFn: async () => {
      const { name } = values
      if (poi?.id) {
        await apiPost<UpdatePointBodyParams, ResponseSuccess>(API_URLS.poi.editPoi, {
          pointId: poi?.id,
          geojson: JSON.stringify(poi?.geojson),
          pointName: name,
        })
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      handleDismiss()
    },
    onError: (errorResponse: ResponseError) => {
      toast.error(errorResponse.error)
    },
  })

  const handleDismiss = () => {
    queryParams.delete('editPoi')
    navigate(`/${queryParams.toString() ? '?' + queryParams.toString() : ''}`, { replace: true })
  }

  const handleUpdateRoute = () => {
    mutationEditPoi.mutate()
  }

  const isLoading = mutationEditPoi.isPending

  let buttonLabel = ''
  if (isLoading) {
    buttonLabel = 'Updating'
  } else {
    buttonLabel = 'Update'
  }

  return (
    <SimpleModal
      onClose={handleDismiss}
      headerText={
        <>
          <span className='font-normal'>Edit Point of Interest</span> <span className='font-bold'>{poi?.name}</span>
        </>
      }
      footer={
        <Button onClick={handleUpdateRoute} className='mr-2 rounded' color='blue' isProcessing={isLoading}>
          {buttonLabel} Poi
        </Button>
      }
    >
      <div className={`${isLoading ? 'opacity-20' : ''}`}>
        <form className='flex flex-col gap-6'>
          <div>
            <div className='mb-2 block'>
              <label htmlFor='name' className='mb-2 block text-sm font-medium text-gray-900'>
                Poi name
              </label>
            </div>
            <input
              id='name'
              type='name'
              placeholder='Poi name'
              name='name'
              required={true}
              onChange={onChange}
              value={values.name}
              className='block rounded border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
            />
          </div>
        </form>
      </div>
    </SimpleModal>
  )
}
