import { createRoot } from 'react-dom/client'
import './assets/index.css'
import { App } from './components/App.tsx'
import { initAmplitude } from './integrations/amplitude.ts'
import { initSentry } from './integrations/sentry.ts'

initSentry()
initAmplitude()

createRoot(document.getElementById('root') as HTMLElement).render(<App />)
