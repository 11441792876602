import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ResponseError } from 'api-types'
import type { CreateFolderBodyParams, CreateFolderResponse } from 'modules/folders/types'
import type { CreatePointBodyParams } from 'modules/points/types'
import type { CreatePolygonBodyParams } from 'modules/polygons/types'
import type { AddRouteBodyParams } from 'modules/routes/types'
import type { Dispatch } from 'react'
import { SetStateAction } from 'react'
import { API_URLS, GET_FOLDERS_KEY } from '../constants'
import type { DrawingState } from '../types/DrawingState'
import type { RoutesFeatureCollection } from '../types/RoutesFeaturedCollection'
import { apiPost } from '../utils/api'

import { Feature, MultiPolygon, Point, Polygon } from '@turf/turf'
import type { CreatedItem } from './useCreatedItems'

export function useCreateFolderMutation({ setCreatedItem }: { setCreatedItem: Dispatch<SetStateAction<CreatedItem>> }) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ folderName, parentFolderId }: CreateFolderBodyParams) => {
      return await apiPost<CreateFolderBodyParams, CreateFolderResponse>(API_URLS.folders.createFolder, {
        folderName,
        parentFolderId,
      })
    },
    onSuccess: async ({ folderId }) => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      setCreatedItem((prev) => ({
        ...prev,
        createdFolder: {
          folderIdToSelect: folderId,
        },
      }))
    },
    onError: (errorResponse: ResponseError) => {
      console.error(errorResponse.error)
    },
  })
}

export function useCreateRouteMutation({
  setCreatedItem,
  setRoutesOperationError,
}: {
  setCreatedItem: Dispatch<SetStateAction<CreatedItem>>
  setRoutesOperationError: Dispatch<SetStateAction<string>>
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      name,
      geojson,
      geojsonString,
    }: {
      name: string
      geojson?: RoutesFeatureCollection
      geojsonString?: string
    }) => {
      return await apiPost<AddRouteBodyParams, { guid: string; lastModified: number }>(API_URLS.routes.addRoute, {
        name,
        geojsonString: geojsonString ? geojsonString : JSON.stringify(geojson),
      })
    },
    onSuccess: async ({ guid }) => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      setCreatedItem((prev) => ({
        ...prev,
        createdRoute: {
          routeIdToSelect: guid,
          routeIdToUnselect: '',
        },
      }))
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}

export function useCreatePolygonMutation({
  setCreatedItem,
  setRoutesOperationError,
}: {
  setCreatedItem: Dispatch<SetStateAction<CreatedItem>>
  setRoutesOperationError: Dispatch<SetStateAction<string>>
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ polygonName, geojson }: { polygonName: string; geojson: Feature<Polygon | MultiPolygon> }) => {
      return await apiPost<CreatePolygonBodyParams, { polygonId: string }>(API_URLS.polygons.addPolygon, {
        polygonName,
        geojson: JSON.stringify(geojson),
      })
    },
    onSuccess: async ({ polygonId }) => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      setCreatedItem((prev) => ({
        ...prev,
        createdPolygon: {
          polygonIdToSelect: polygonId,
        },
      }))
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}

export function useCreatePoiMutation({
  setCreatedItem,
  setActiveDrawing,
  setRoutesOperationError,
}: {
  setCreatedItem: Dispatch<SetStateAction<CreatedItem>>
  setActiveDrawing: Dispatch<SetStateAction<DrawingState>>
  setRoutesOperationError: Dispatch<SetStateAction<string>>
}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ poiName, geojson }: { poiName: string; geojson: Feature<Point> }) => {
      return await apiPost<CreatePointBodyParams, { pointId: string }>(API_URLS.poi.addPoi, {
        pointName: poiName,
        geojson: JSON.stringify(geojson),
      })
    },
    onSuccess: async ({ pointId }) => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
      setCreatedItem((prev) => ({
        ...prev,
        createdPoi: {
          poiIdToSelect: pointId,
        },
      }))
      setActiveDrawing((prev) => ({
        ...prev,
        isDrawingPoiActive: false,
      }))
    },
    onError: (errorResponse: ResponseError) => {
      setRoutesOperationError(errorResponse.error)
    },
  })
}
