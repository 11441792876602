import type { Device } from 'modules/devices/types'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import disconnectedIcon from '../../assets/disconnected-icon.svg'

import { Spinner } from 'flowbite-react'
import { CredentialsForm } from './CredentialsForm'

interface RobotItemProps {
  robot: Device
  isFirstItem: boolean
  isLastItem: boolean
  onSelectRobot: (robot: Device) => void
  isRobotSelected: boolean
}

export function RobotItem({ robot, isFirstItem, isLastItem, onSelectRobot, isRobotSelected }: RobotItemProps) {
  const [isCredentialsFormVisible, setIsCredentialsFormVisible] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const isLoading = false

  useEffect(() => {
    if (!isRobotSelected && isCredentialsFormVisible) {
      setIsCredentialsFormVisible(false)
    }
  }, [isRobotSelected])

  const robotNum = robot.name.slice(-4)

  return (
    <li
      className={`cursor-pointer p-3 sm:p-2 ${isRobotSelected ? 'bg-gray-100' : ''} ${
        isFirstItem ? `first:rounded-t-md` : isLastItem ? `last:rounded-b-md` : ''
      } ${isLoading ? 'opacity-20' : ''}`}
      onClick={() => onSelectRobot(robot)}
    >
      {isLoading && <Spinner />}
      <div className={`flex items-center justify-between ${isCredentialsFormVisible ? 'mb-3' : ''}`}>
        <div className='flex'>
          <span className='flex flex-1 border border-transparent p-1.5 text-sm text-gray-900 dark:text-white'>
            {robot.name}
          </span>
          {!robot.isOnline && <img src={disconnectedIcon} className='relative top-1.5 h-3' alt='route with offset' />}
        </div>
        <div className='flex shrink-0'>
          <button
            type='button'
            className={`${
              isCredentialsFormVisible ? 'bg-white' : 'bg-transparent'
            } mr-1 flex h-8 w-8 shrink-0 items-center justify-center rounded border border-solid border-transparent bg-transparent px-1 py-1 text-sm font-normal text-stone-700 hover:border-solid hover:border-blue-500 hover:bg-white hover:text-blue-500 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200`}
            onClick={() => setIsCredentialsFormVisible(!isCredentialsFormVisible)}
            title='Add Credentials'
          >
            <svg
              fill='currentColor'
              version='1.1'
              id='gps'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 297 297'
              className='h-6 w-6'
              style={{ height: '17.5px', width: '17.5px' }}
            >
              <path
                d='M148.5,0C66.653,0,0.067,66.616,0.067,148.499C0.067,230.383,66.653,297,148.5,297s148.433-66.617,148.433-148.501
	C296.933,66.616,230.347,0,148.5,0z M158.597,276.411v-61.274c0-5.575-4.521-10.097-10.097-10.097s-10.097,4.521-10.097,10.097
	v61.274c-62.68-4.908-112.845-55.102-117.747-117.814h61.207c5.575,0,10.097-4.521,10.097-10.097s-4.522-10.097-10.097-10.097
	H20.656C25.558,75.69,75.723,25.497,138.403,20.589v61.274c0,5.575,4.521,10.097,10.097,10.097s10.097-4.521,10.097-10.097V20.589
	c62.681,4.908,112.846,55.102,117.747,117.814h-61.207c-5.575,0-10.097,4.521-10.097,10.097s4.521,10.097,10.097,10.097h61.207
	C271.441,221.31,221.276,271.503,158.597,276.411z'
              />
            </svg>
          </button>
          <button
            type='button'
            className={`mr-1 flex h-8 w-8 shrink-0 items-center justify-center rounded border border-solid border-transparent bg-transparent px-1 py-1 text-sm font-normal text-stone-700 hover:border-solid hover:border-blue-500 hover:bg-white hover:text-blue-500 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200`}
            title='Add Schedule'
            onClick={() => {
              queryParams.set('schedule', robotNum)
              navigate(`${location.pathname}?${queryParams.toString()}`)
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='h-6 w-6'
            >
              <path strokeLinecap='round' strokeLinejoin='round' d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z' />
            </svg>
          </button>
        </div>
      </div>
      {isCredentialsFormVisible && <CredentialsForm robotNum={robotNum} robotName={robot.name} />}
    </li>
  )
}
