import { User } from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Navbar } from 'flowbite-react'
import deburr from 'lodash.deburr'
import { UsersResponse } from 'modules/auth/types'
import { FunctionComponent, MouseEventHandler, useCallback, useContext, useState } from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { FaBars } from 'react-icons/fa6'
import { SingleValueProps } from 'react-select'
import { Async } from 'react-select-virtualized'
import { useLocalstorageState } from 'rooks'
import { API_URLS, BURRO_CUSTOMER_TAGS, STORAGE_FOLDER_TREE_KEY } from '../../constants'
import { SelectedUserContext, SelectOption } from '../../context/SelectedUserContext'
import { apiGet } from '../../utils/api'
import { useUser } from '../../utils/auth'
import { debounce } from '../../utils/debounce'
import { AutocompleteDropdownModal } from './modals/AutocompleteDropdownModal'

export const AtlasNavbar: FunctionComponent<{
  onClickLeft?: MouseEventHandler<HTMLButtonElement>
  onClickRight?: MouseEventHandler<HTMLButtonElement>
}> = ({ onClickLeft, onClickRight }) => {
  const [, setUserLsState] = useLocalstorageState<User | null>('BURRO_USER', null)
  const { selectedUser, setSelectedUser } = useContext(SelectedUserContext)
  const { data: user }: { data: User | null } = useUser()
  const queryClient = useQueryClient()
  const [isShowingCustomerTagModalState, setIsShowingCustomerTagModalState] = useState(false)
  const loadOptionsDebounced = useCallback(
    debounce((inputValue: string, callback: (options: any) => void) => {
      loadOptions(inputValue, callback)
    }, 500),
    []
  )

  return (
    <>
      <Navbar fluid={true} className='w-full !px-2'>
        <div className='flex items-center'>
          {onClickLeft !== undefined && (
            <Button color='gray' onClick={onClickLeft}>
              <FaBars />
            </Button>
          )}
        </div>
        <div>
          {user?.isAdmin && (
            <div className='flex flex-col items-start lg:flex-row lg:items-center'>
              <span className='mx-3 mb-3 lg:mx-0 lg:mb-0'>
                Selected customer tag:{' '}
                <strong>{selectedUser && selectedUser.value?.length > 0 ? selectedUser.value : 'null'}</strong>
              </span>
              <div className='mx-3 flex min-w-[150px] flex-1 sm:min-w-[500px]'>
                <Async
                  cacheOptions
                  loadOptions={loadOptionsDebounced}
                  placeholder='Select user...'
                  className='w-full'
                  value={selectedUser}
                  onChange={(e: SelectOption) => {
                    if (e?.value !== selectedUser?.value || e === null) {
                      window.localStorage.removeItem(STORAGE_FOLDER_TREE_KEY)
                    }
                    setSelectedUser(e)
                  }}
                  components={{ SingleValue: SelectUserComponent }}
                />
              </div>
            </div>
          )}
        </div>

        <div className='flex-1 flex justify-center'>
          <div className='text-center text-gray-900 dark:text-white'>
            {user?.email?.includes('burro.ai') ? (
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  setIsShowingCustomerTagModalState(true)
                }}
                className='underline'
              >
                {user.customerTag === '' ? 'Select customer tag' : user.customerTag}
              </a>
            ) : (
              user?.customerTag
            )}
          </div>
        </div>

        <div className='flex items-center gap-2'>
          {onClickRight !== undefined && (
            <Button color='gray' onClick={onClickRight}>
              <FaInfoCircle />
            </Button>
          )}
        </div>
      </Navbar>
      <AutocompleteDropdownModal
        headerText='Change customer tag'
        size='sm'
        placeholder='Search or enter customer tag'
        show={isShowingCustomerTagModalState}
        onClose={() => {
          setIsShowingCustomerTagModalState(false)
        }}
        items={BURRO_CUSTOMER_TAGS}
        onSelection={(item) => {
          setUserLsState({ ...user, customerTag: item } as any)
          setSelectedUser({ value: item, label: item })
          queryClient.invalidateQueries()
          window.location.reload()
        }}
      />
    </>
  )
}

export const SelectUserComponent = ({
  children,
  data,
}: SingleValueProps<{
  value: string
  label: string
}>) => {
  return (
    <div
      className={`col-start-1 col-end-3 row-start-1 row-end-2 mx-[2px] box-border grid max-w-full truncate ${
        data.value?.length > 0 ? 'text-slate-700' : 'text-gray-400'
      }`}
    >
      {children}
    </div>
  )
}

export const loadOptions = async (input: string, callback: (options: { value: string; label: string }[]) => void) => {
  const response = await apiGet<UsersResponse[]>(API_URLS.auth.users)
  const filteredResponse = response.filter(({ firstName, lastName, email, customerTag }) => {
    const fstNameLowCase = firstName?.trim().toLowerCase()
    const sanitizedFstName = deburr(fstNameLowCase)
    const lastNameLowCase = lastName?.trim().toLowerCase()
    const sanitizedLastName = deburr(lastNameLowCase)
    const inputLowCase = input?.trim().toLowerCase()
    const sanitizedInput = deburr(inputLowCase)
    return (
      sanitizedFstName?.includes(sanitizedInput) ||
      sanitizedLastName?.includes(sanitizedInput) ||
      customerTag === input ||
      email?.includes(sanitizedInput)
    )
  })
  const mappedResult = filteredResponse.map((user) => ({
    value: user.customerTag,
    label: `${user.firstName} ${user.lastName} (${user.email})`,
  }))
  callback(mappedResult)
}
