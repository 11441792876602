import { Avatar, Dropdown, useThemeMode } from 'flowbite-react'
import { FunctionComponent, useContext, useState } from 'react'
import { FaMailBulk, FaSignOutAlt } from 'react-icons/fa'
import { FaMoon, FaSun } from 'react-icons/fa6'
import { GlobalStateContext } from '../../context/GlobalStateContext'
import { useLogout, User, useUser } from '../../utils/auth'
import { FeedbackModal } from './modals/FeedbackModal'

export const AtlasAvatar: FunctionComponent<{ className?: string }> = ({ className }) => {
  const logout = useLogout()
  const [isShowingFeedbackModalState, setIsShowFeedbackModalState] = useState(false)
  const { basePathComponent, toggleBasePathComponent } = useContext(GlobalStateContext)
  const { data: user }: { data: User | null } = useUser()
  const themeMode = useThemeMode()

  const nameInitials =
    user?.firstName?.length || user?.lastName?.length
      ? `${user?.firstName?.length ? user.firstName?.charAt(0)?.toUpperCase() : ''}${
          user?.lastName?.length ? user.lastName?.charAt(0).toUpperCase() : ''
        }`
      : ''
  const handleLogout = () => {
    logout.mutate()
  }
  const allowedToSwitchBasePath =
    user?.email.includes('@burro.ai') && user.email?.includes('dev') && !user.email?.includes('charlie')
  //const showProdWarning = MetaEnv.burroEnv !== 'prod' && basePathComponent === 'prod'
  return (
    <div className={`${user?.isAdmin ? 'mt-3' : ''} ${className}`}>
      <Dropdown arrowIcon={false} inline={true} label={<Avatar placeholderInitials={nameInitials} />}>
        <Dropdown.Header>
          <span className='block text-sm'>
            {user?.firstName} {user?.lastName}
          </span>
          <span className='block truncate text-sm font-medium'>{user?.email}</span>
        </Dropdown.Header>
        <Dropdown.Item onClick={() => setIsShowFeedbackModalState(true)} icon={FaMailBulk}>
          Send feedback
        </Dropdown.Item>
        <Dropdown.Item onClick={handleLogout} icon={FaSignOutAlt}>
          Sign out
        </Dropdown.Item>
        <Dropdown.Item
          icon={themeMode.mode == 'dark' ? FaSun : FaMoon}
          onClick={() => {
            themeMode.toggleMode()
          }}
        >
          {themeMode.mode == 'dark' ? 'Light Mode' : 'Dark Mode'}
        </Dropdown.Item>

        {allowedToSwitchBasePath && (
          <Dropdown.Item onClick={() => toggleBasePathComponent()}>
            {basePathComponent.toLocaleUpperCase()} (toggle)
          </Dropdown.Item>
        )}
      </Dropdown>
      {user?.email && (
        <FeedbackModal
          defaultValues={{
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
          }}
          show={isShowingFeedbackModalState}
          onClose={() => setIsShowFeedbackModalState(false)}
        />
      )}
    </div>
  )
}
