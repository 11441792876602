import type { DestinationMapStationProperties } from 'burro-map-utils'
import { Button, Label, TextInput } from 'flowbite-react'
import { FunctionComponent } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'

export const StationPointForm: FunctionComponent<{
  feature: { properties: DestinationMapStationProperties }
  onValid: SubmitHandler<DestinationMapStationProperties>
}> = ({ feature, onValid }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DestinationMapStationProperties>({
    defaultValues: { name: feature.properties.name, stop_time: feature.properties.stop_time },
  })

  return (
    <form
      className='flex min-w-[200px] max-w-lg flex-col gap-2 py-2'
      onSubmit={handleSubmit((data) => {
        onValid({ ...feature.properties, ...data })
      })}
    >
      <Label htmlFor='name' value='Name' />
      <TextInput id='name' type='text' placeholder={`Name`} {...register('name')} />
      {errors.name && <p className='text-sm text-red-500'>This field is required.</p>}

      <Label htmlFor='stop_time' value='Stop time (seconds)' />
      <TextInput
        id='stop_time'
        type='number'
        placeholder={`Stop time`}
        required
        {...register('stop_time', { required: true, valueAsNumber: true })}
      />
      {errors.stop_time && <p className='text-sm text-red-500'>This field is required.</p>}
      <Button type='submit' size='sm'>
        Save
      </Button>
    </form>
  )
}
