import { useDraggable } from '@dnd-kit/core'
import type { DestinationMapPointFeature } from 'burro-map-utils'
import { Button, Dropdown } from 'flowbite-react'
import { DotsVertical, MapPinAlt } from 'flowbite-react-icons/outline'
import { FunctionComponent, HTMLAttributes } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import { FaGripVertical, FaTrash } from 'react-icons/fa6'
import { DESTINATION_DND_DELIMITER } from './DestinationMapsListEntry'

export const DestinationMapsFoldersTreeLeafComponent: FunctionComponent<
  {
    data: DestinationMapPointFeature
    onClickRename: () => void
    onClickDelete: () => void
    onClickSelect: () => void
  } & HTMLAttributes<HTMLDivElement>
> = ({ data, onClickRename, onClickDelete, onClickSelect, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef: setDraggableNodeRef,
    setActivatorNodeRef: setDraggableActivatorNodeRef,
    transform,
  } = useDraggable({
    id: `draggable${DESTINATION_DND_DELIMITER}destination${DESTINATION_DND_DELIMITER}${data.id}${DESTINATION_DND_DELIMITER}${data.properties.name}`,
  })

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined

  return (
    <div ref={setDraggableNodeRef} style={style} {...props}>
      <div className={`flex items-center gap-x-2`}>
        <button ref={setDraggableActivatorNodeRef} {...listeners} {...attributes} className='cursor-grabbing'>
          <FaGripVertical />
        </button>

        <Button color='light' size='xs' onClick={onClickSelect}>
          <MapPinAlt />
        </Button>

        <div className='flex-1 text-sm'>{data.properties.name}</div>

        <Dropdown
          label='edit route'
          renderTrigger={() => (
            <Button size='xs' color='gray'>
              <DotsVertical />
            </Button>
          )}
        >
          <Dropdown.Item icon={FaRegEdit} onClick={onClickRename}>
            Rename
          </Dropdown.Item>
          <Dropdown.Item icon={FaTrash} onClick={onClickDelete}>
            Delete
          </Dropdown.Item>
        </Dropdown>
      </div>
    </div>
  )
}
