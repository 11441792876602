import { mapByLinkingLineStringWithIds, mapByUnlinkingLineStringsWithIds, mapWithSpeedLimit } from 'burro-map-utils'
import { Tabs, TabsRef } from 'flowbite-react'
import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react'
import { FaTractor } from 'react-icons/fa'
import { FaLocationDot, FaMap } from 'react-icons/fa6'
import { IoAnalyticsOutline } from 'react-icons/io5'
import { isLineStringFeature, isPointFeature } from 'turf-extensions'
import { DestinationMapsContext } from './DestinationMapsContext'
import { DestinationMapSettings } from './DestinationMapSettings'
import { DestinationMapsFormLineString } from './DestinationMapsFormLineString'
import { DestinationMapsFormPoint } from './DestinationMapsFormPoint'
import { DestinationMapsLinkLineStringButton } from './DestinationMapsLinkLineStringButton'
import { DestinationMapsSpeedLimitForm } from './DestinationMapsSpeedLimitForm'

export const DestinationMapsPanelDrawerRight: FunctionComponent<{ className?: string }> = ({ className }) => {
  const { selectedFeatureIds, destinationMapRenderState, setDestinationMapRenderState } =
    useContext(DestinationMapsContext)

  const tabsRef = useRef<TabsRef>(null)
  const [activeTabState, setActiveTabState] = useState(0)

  const selectedFeatures =
    destinationMapRenderState?.features?.filter((value) => value.id === selectedFeatureIds[0]) ?? []
  const everySelectedFeatureIsLineString = selectedFeatures.every(isLineStringFeature)
  const firstSelectedFeature = destinationMapRenderState?.features?.find((value) => value.id === selectedFeatureIds[0])
  const secondSelectedFeature = destinationMapRenderState?.features?.find((value) => value.id === selectedFeatureIds[1])

  useEffect(() => {
    if (firstSelectedFeature) {
      tabsRef.current?.setActiveTab(2)
    }
  }, [firstSelectedFeature?.id])

  return (
    <div className={`flex flex-col h-full ${className ?? ''}`}>
      <Tabs
        ref={tabsRef}
        onActiveTabChange={setActiveTabState}
        variant='fullWidth'
        className={`overflow-y-none`}
        theme={{ base: '', tabpanel: '' }}
        style={{ height: '60px' }}
      >
        <Tabs.Item title='Settings' icon={FaTractor} />
        <Tabs.Item title='Map' icon={FaMap} />

        {selectedFeatureIds.length === 1 && firstSelectedFeature && isPointFeature(firstSelectedFeature) && (
          <Tabs.Item title='Point' icon={FaLocationDot} />
        )}
        {selectedFeatureIds.length > 0 && everySelectedFeatureIsLineString && (
          <Tabs.Item title='Line' icon={IoAnalyticsOutline} />
        )}
        {selectedFeatureIds.length === 2 &&
          firstSelectedFeature &&
          isLineStringFeature(firstSelectedFeature) &&
          secondSelectedFeature &&
          isLineStringFeature(secondSelectedFeature) && <Tabs.Item title='Lines' icon={IoAnalyticsOutline} />}
      </Tabs>
      {activeTabState === 0 && destinationMapRenderState && (
        <DestinationMapsSpeedLimitForm
          onDeleteAll={() => {
            setDestinationMapRenderState(
              mapWithSpeedLimit({ destinationMap: destinationMapRenderState!, speedLimit: 0, action: 'setAll' })
            )
          }}
          onSetAll={(data) => {
            setDestinationMapRenderState(
              mapWithSpeedLimit({
                destinationMap: destinationMapRenderState!,
                speedLimit: data.speedLimitMetersPerSecond,
                action: 'setAll',
              })
            )
          }}
          onSetDefault={(data) => {
            setDestinationMapRenderState(
              mapWithSpeedLimit({
                destinationMap: destinationMapRenderState!,
                speedLimit: data.speedLimitMetersPerSecond,
                action: 'setDefault',
              })
            )
          }}
        />
      )}
      {activeTabState === 1 && <DestinationMapSettings className='flex flex-col flex-grow overflow-y-auto gap-2' />}
      {activeTabState === 2 && selectedFeatureIds.length > 0 && everySelectedFeatureIsLineString && (
        <DestinationMapsFormLineString />
      )}
      {activeTabState === 2 &&
        selectedFeatureIds.length === 1 &&
        firstSelectedFeature &&
        isPointFeature(firstSelectedFeature) && <DestinationMapsFormPoint />}
      {activeTabState === 2 &&
        selectedFeatureIds.length === 2 &&
        firstSelectedFeature &&
        isLineStringFeature(firstSelectedFeature) &&
        secondSelectedFeature &&
        isLineStringFeature(secondSelectedFeature) && (
          <DestinationMapsLinkLineStringButton
            className='w-full'
            lineString1={firstSelectedFeature as any}
            lineString2={secondSelectedFeature as any}
            onClickLink={() => {
              setDestinationMapRenderState(
                mapByLinkingLineStringWithIds({
                  destinationMap: destinationMapRenderState!,
                  lineId1: firstSelectedFeature.id!,
                  lineId2: secondSelectedFeature.id!,
                })
              )
            }}
            onClickUnlink={() => {
              setDestinationMapRenderState(
                mapByUnlinkingLineStringsWithIds({
                  destinationMap: destinationMapRenderState!,
                  lineId1: firstSelectedFeature.id!,
                  lineId2: secondSelectedFeature.id!,
                })
              )
            }}
          />
        )}
    </div>
  )
}
