import { Button, Modal, ModalProps } from 'flowbite-react'
import { FunctionComponent } from 'react'

export const AlertModal: FunctionComponent<
  ModalProps & {
    headerText?: string
    closeText?: string
  }
> = ({ headerText, closeText, children, ...modalProps }) => {
  return (
    <Modal {...modalProps}>
      {headerText && <Modal.Header>{headerText}</Modal.Header>}
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button
          color='gray'
          onClick={() => {
            modalProps.onClose && modalProps.onClose()
          }}
        >
          {closeText ? closeText : 'Close'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
