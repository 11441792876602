import { FunctionComponent } from 'react'
import { Provider } from 'react-redux'
import { DestinationMapsPage } from './DestinationMapsPage'
import { destinationMapStore } from './destinationMapSlice'

export const DestinationMapsPageContainer: FunctionComponent = () => {
  return (
    <Provider store={destinationMapStore}>
      <DestinationMapsPage />
    </Provider>
  )
}
