import { useMutation } from '@tanstack/react-query'

import type { DefaultApiRouteCreatePostRequest } from 'burro-web-api-js-client'
import { useAtlasApi } from '../../context/AtlasApiProvider'

export const routesCreatePostMutationKey = () => ['routesCreatePostQueryKey']

export const useRoutesCreatePostMutation = () => {
  const api = useAtlasApi()

  return useMutation({
    mutationKey: routesCreatePostMutationKey(),
    mutationFn: async (params: DefaultApiRouteCreatePostRequest) => {
      const response = await api.routeCreatePost(params)
      return response.data
    },
  })
}
