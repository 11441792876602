import classNames from 'classnames'
import { CSSProperties, forwardRef, ReactNode } from 'react'

import styles from './List.module.css'

export interface Props {
  children: ReactNode
  columns?: number
  style?: CSSProperties
  horizontal?: boolean
}

export const List = forwardRef<HTMLUListElement, Props>(({ children, columns = 1, horizontal, style }: Props, ref) => {
  return (
    <ul
      ref={ref}
      style={
        {
          ...style,
          '--columns': columns,
        } as CSSProperties
      }
      className={classNames(styles.List, horizontal && styles.horizontal)}
    >
      {children}
    </ul>
  )
})
