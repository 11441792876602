import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { createContext } from 'react'
import { useLocalstorageState } from 'rooks'

export interface RoutesListContextInterface {
  scrollYPosition: number
  setScrollYPosition: Dispatch<SetStateAction<number>>
}

export const RoutesListContext = createContext<RoutesListContextInterface>({
  scrollYPosition: 0,
  setScrollYPosition: () => Promise.resolve(0),
})

export function RoutesListProvider({ children }: { children: ReactNode }) {
  const [scrollYPosition, setScrollYPosition] = useLocalstorageState<number>('burro-app:routesListScrollYPosition', 0)

  return (
    <RoutesListContext.Provider value={{ scrollYPosition, setScrollYPosition }}>{children}</RoutesListContext.Provider>
  )
}
