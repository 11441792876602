import type { UniqueIdentifier } from '@dnd-kit/core/dist/types'
import { Feature, LineString, Point } from '@turf/turf'
import gjv from 'geojson-validation'
import type { LatLng, Layer, Polygon } from 'leaflet'
import { Marker, geoJSON, marker } from 'leaflet'
import {
  ExtFeatureGroup,
  ExtPolyline,
  ExtendedFeatureGroupOptions,
  ExtendedMarkerOptions,
  ExtendedPolygonOptions,
  StationMarker,
} from '../../../types'
import { TreeFolderOrShape } from '../../../types/TreeFolderOrShape'
import { TreePoint, isTreePoint } from '../../../types/TreePoint'
import { TreePolygon, isTreePolygon } from '../../../types/TreePolygon'
import { TreeRoute, isTreeRoute } from '../../../types/TreeRoute'
import { EditedLayer, StationIcon } from '../../RoutesMap/GeomanDraw/GeomanDraw'
import {
  getStationLayerMetadataFromLineCoords,
  handleLineMarkerDrag,
  handleLineVertexAdded,
} from '../../RoutesMap/GeomanDraw/stationReposition'
import {
  PoiDrawIcon,
  PoiIcon,
  getStationPopupForm,
  handleAddOrUpdateStation,
} from '../../RoutesMap/GeomanDraw/useGeomanDraw'
import { LeafletContextInterface } from '../../RoutesMap/LeafletContextInterfaceOrNull'
import { addRoutesSigns, removeSignsFromAllRoutes } from './RouteSigns'
import {
  DEFAULT_DISABLED_GEOMAN_OPTIONS,
  DEFAULT_DRAW_PATH_COLOR,
  DEFAULT_PATH_COLOR,
  getArrowHeadsOptions,
} from './ShapesTree'
import type { ExtendedTreeItem, TreeItems } from './types'
import { flattenTree } from './utilities'

function getCoordinateFormString({ formId, coordinate }: { formId: string; coordinate: LatLng }) {
  return `<form id="${formId}">\
  <div class="flex items-center">\
    <label for="lat" class="mb-1 mr-1">Lat:</label>\
    <input value="${coordinate.lat}" id="lat" type="number" step="any" class="block w-40 rounded border border-gray-300 bg-gray-50 p-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500 sm:text-xs" />\
  </div>\
  <div class="my-2 flex items-center">\
    <label for="lng" class="mb-1 mr-1">Lng:</label>\
    <input value="${coordinate.lng}" id="lng" type="number" step="any" class="block w-40 rounded border border-gray-300 bg-gray-50 p-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500 sm:text-xs" />\
  </div>\
  <button type="submit" class="inline-flex shrink-0 items-center rounded border border-blue-700 bg-white px-3 py-2 text-center text-xs font-medium text-blue-700 hover:bg-blue-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300">Update</button>\
  </form>`
}

export function createRouteLayer({
  context,
  treeItem,
  onEditRoute,
  onSetPendingEditLayer,
  handleRemoveStation,
  isInDrawMode,
  onSetSelectedTreeItem,
}: {
  context: LeafletContextInterface | null
  treeItem: TreeRoute
  onEditRoute?: (editedLayer: EditedLayer) => void
  onSetPendingEditLayer?: (layer: Layer | EditedLayer) => void
  handleRemoveStation: (e: { layer: Layer; shape: string }) => void
  isInDrawMode: boolean
  onSetSelectedTreeItem: (routeId: UniqueIdentifier) => void
}) {
  if (context?.map) {
    const geojson = treeItem.geojson
    const geoJsonLayer = geoJSON<LineString>(geojson, {
      pointToLayer: function (_feature, latlng) {
        const lineFeature: LineString | undefined = geojson.features.find((feature: Feature) => {
          return feature.geometry.type === 'LineString'
        })?.geometry as LineString
        let markerStationMeasures = null
        if (lineFeature) {
          const lineCoords = lineFeature.coordinates
          markerStationMeasures = getStationLayerMetadataFromLineCoords(lineCoords, [latlng.lng, latlng.lat])
        }
        const stationMarker = marker(latlng, {
          icon: StationIcon,
          stationMeasures: {
            ...markerStationMeasures,
          },
        } as ExtendedMarkerOptions) as StationMarker
        const popupFormId = `marker_${Math.abs(latlng.lat).toString().replace('.', '')}_${Math.abs(latlng.lng)
          .toString()
          .replace('.', '')}`
        stationMarker.bindPopup(getStationPopupForm(popupFormId)).on('popupopen', function (this: StationMarker) {
          const form = document.querySelector(`#${popupFormId}`) as HTMLFormElement

          const durationRadio = form.querySelector('#duration-radio') as HTMLInputElement
          const indefiniteRadio = form.querySelector('#infinite-radio') as HTMLInputElement
          const robotDefaultRadio = form.querySelector('#robot-default-radio') as HTMLInputElement
          const durationContainer = form.querySelector('#duration-input-container') as HTMLDivElement
          const durationInput = form.querySelector('#station-stop-duration') as HTMLInputElement
          const nameInput = form.querySelector('#station-name') as HTMLInputElement

          if (this.feature?.properties?.name?.length > 0) {
            nameInput.value = this.feature?.properties.name
          }

          if (this.feature?.properties?.stop_duration_in_sec?.length > 0) {
            durationInput.value = this.feature?.properties.stop_duration_in_sec
            durationRadio.checked = true
          } else if (this.feature?.properties?.stop_type === 'indefinite') {
            indefiniteRadio.checked = true
          } else {
            robotDefaultRadio.checked = true
          }

          // function to toggle the visibility of the duration input
          function toggleDurationInputVisibility() {
            if (durationRadio.checked) {
              durationContainer.style.display = 'flex'
              durationInput.required = true
            } else if (indefiniteRadio.checked || robotDefaultRadio.checked) {
              durationContainer.style.display = 'none'
              durationInput.required = false
            }
          }

          // attach the event listeners to the radio buttons
          durationRadio.addEventListener('change', toggleDurationInputVisibility)
          indefiniteRadio.addEventListener('change', toggleDurationInputVisibility)
          robotDefaultRadio.addEventListener('change', toggleDurationInputVisibility)

          // call the function initially to set the correct state based on the pre-checked radio button
          toggleDurationInputVisibility()

          form?.addEventListener('submit', (e) => {
            e.preventDefault()
            const name = nameInput?.value
            const durationRadioVal = durationRadio?.checked
            const infiniteRadioVal = indefiniteRadio?.checked
            const robotDefaultVal = robotDefaultRadio?.checked
            const durationVal = durationInput?.value
            const markerMetadata = {
              ...(name?.length > 0 ? { name } : {}),
              ...(robotDefaultVal ? {} : { stop_type: infiniteRadioVal ? 'indefinite' : 'duration' }),
              ...(durationRadioVal ? { stop_duration_in_sec: durationVal } : {}),
            }
            if (this.feature?.properties) {
              this.feature.properties = {
                point_type: 'station',
                ...markerMetadata,
              }
            }
            handleAddOrUpdateStation({ layer: this, context, addStation: false, onEditRoute, markerMetadata })
            this.closePopup()
            this.unbindPopup()
          })
        })
        if (treeItem.isEditable) {
          stationMarker.on('pm:remove', handleRemoveStation)
          stationMarker?.pm.setOptions({ allowEditing: false })
        } else {
          stationMarker?.pm.setOptions(DEFAULT_DISABLED_GEOMAN_OPTIONS)
        }
        return stationMarker as Marker
      },
      onEachFeature: function (feature, layer) {
        const lineString = layer as ExtPolyline
        if (feature.geometry.type === 'LineString') {
          lineString.on('click', (e) => {
            const layerOptions = e.target.options as ExtendedFeatureGroupOptions
            if (layerOptions?.routeId && layerOptions?.routeName) {
              onSetSelectedTreeItem(treeItem.id)
            }
          })
          if (treeItem.isEditable) {
            lineString.on('pm:vertexadded', handleLineVertexAdded)
            lineString.on('pm:vertexremoved', (e) => {
              if (e.shape === 'Line') {
                const line = e.layer as ExtPolyline
                const parentLayerGroup = line?._pmLastGroupFetch?.groups?.[0]
                if (parentLayerGroup) {
                  parentLayerGroup.getLayers().forEach((layerInGroup) => {
                    if (layerInGroup instanceof Marker) {
                      const stationMarker = layerInGroup as StationMarker
                      const markerLatLng = stationMarker.getLatLng()
                      if (markerLatLng.equals(e.marker.getLatLng())) {
                        parentLayerGroup.removeLayer(stationMarker)
                        onSetPendingEditLayer?.(e.layer)
                      }
                    }
                  })
                }
              }
            })
            lineString.on('pm:markerdrag', handleLineMarkerDrag)
            lineString.on('pm:markerdragend', (e) => {
              onSetPendingEditLayer?.(e.layer)
            })
            lineString.on('pm:vertexremoved', (e) => {
              onSetPendingEditLayer?.(e.layer)
            })
            lineString.on('pm:dragend', (e) => {
              onSetPendingEditLayer?.(e.layer)
            })
            lineString.on('pm:enable', (e) => {
              const routeLayer = e.layer as ExtPolyline
              const routeId = (routeLayer.options as ExtendedFeatureGroupOptions)?.routeId ?? ''
              const latLngs = routeLayer?.getLatLngs() as LatLng[]
              if (context?.map) {
                context.map.eachLayer((layer) => {
                  if (layer instanceof Marker) {
                    const routeCoordinateIndex = latLngs.findIndex((routeLatLng) => routeLatLng === layer.getLatLng())
                    if (routeCoordinateIndex !== -1 && layer?.feature?.properties?.point_type !== 'station') {
                      const markerLatLng = layer.getLatLng()
                      layer
                        .bindPopup(getCoordinateFormString({ formId: routeId, coordinate: markerLatLng }))
                        .on('popupopen', function (this: Marker) {
                          const form = document.querySelector(`#${routeId}`) as HTMLFormElement
                          const latInput = form.querySelector('#lat') as HTMLInputElement
                          const lngInput = form.querySelector('#lng') as HTMLInputElement

                          form?.addEventListener('submit', (evt) => {
                            evt.preventDefault()
                            const updatedLat = parseFloat(latInput?.value)
                            const updatedLng = parseFloat(lngInput?.value)
                            latLngs[routeCoordinateIndex].lat = updatedLat
                            latLngs[routeCoordinateIndex].lng = updatedLng
                            routeLayer.setLatLngs(latLngs)
                            routeLayer.redraw()
                            routeLayer.pm.disable()
                            routeLayer.pm.enable()
                            handleLineMarkerDrag(e)
                            onSetPendingEditLayer?.(routeLayer)
                          })
                        })
                    }
                  }
                })
              }
            })

            addRoutesSigns({
              map: context.map,
              featureCoords: feature.geometry.coordinates,
              routeId: treeItem.id,
            })
            lineString?.pm.setOptions({ allowRemoval: false })
          } else {
            lineString?.pm.setOptions(DEFAULT_DISABLED_GEOMAN_OPTIONS)
          }
        }
      },
      style: {
        color: isInDrawMode ? DEFAULT_DRAW_PATH_COLOR : DEFAULT_PATH_COLOR,
      },

      // @ts-ignore
      routeId: treeItem.id,
      routeName: treeItem.name,
      type: treeItem.type,
      arrowheads: getArrowHeadsOptions({
        isShapeInDrawMode: isInDrawMode,
      }),
    })
    geoJsonLayer.addTo(context.map)
    if (treeItem.isEditable) {
      geoJsonLayer?.pm.setOptions({
        syncLayersOnDrag: true, // drag the whole group
      })
    } else {
      geoJsonLayer?.pm.setOptions(DEFAULT_DISABLED_GEOMAN_OPTIONS)
    }
  }
}

export function createPolygonLayer({
  context,
  treeItem,
  onSetPendingEditLayer,
  isInDrawMode,
  onSetSelectedTreeItem,
}: {
  context: LeafletContextInterface | null
  treeItem: TreePolygon
  onSetPendingEditLayer?: (layer: Layer | EditedLayer) => void
  isInDrawMode: boolean
  onSetSelectedTreeItem: (routeId: UniqueIdentifier) => void
}) {
  if (context?.map) {
    const geojson = treeItem.geojson
    const geoJsonLayer = geoJSON<Polygon>(geojson, {
      onEachFeature: function (feature, layer) {
        const polygon = layer as Polygon
        if (feature.geometry.type === 'Polygon') {
          polygon.on('click', (e) => {
            const layerOptions = e.target.options as ExtendedPolygonOptions
            if (layerOptions?.polygonId && layerOptions?.polygonName) {
              onSetSelectedTreeItem(treeItem.id)
            }
          })
          polygon.on('pm:vertexadded', (e) => {
            // @ts-ignore
            onSetPendingEditLayer?.(e.layer)
          })
          polygon.on('pm:vertexremoved', (e) => {
            onSetPendingEditLayer?.(e.layer)
          })
          polygon.on('pm:markerdragend', (e) => {
            onSetPendingEditLayer?.(e.layer)
          })
          polygon.on('pm:dragend', (e) => {
            onSetPendingEditLayer?.(e.layer)
          })
          polygon.on('pm:cut', (e) => {
            onSetPendingEditLayer?.(e.layer)
          })
          polygon.on('pm:rotateend', (e) => {
            onSetPendingEditLayer?.(e.layer)
          })
        }
      },
      style: {
        color: isInDrawMode ? DEFAULT_DRAW_PATH_COLOR : DEFAULT_PATH_COLOR,
      },

      // @ts-ignore
      polygonId: treeItem.id,
      polygonName: treeItem.name,
      type: treeItem.type,
    })
    geoJsonLayer.addTo(context.map)
  }
}

export function createPointLayer({
  context,
  treeItem,
  onSetPendingEditLayer,
  isInDrawMode,
  onSetSelectedTreeItem,
}: {
  context: LeafletContextInterface | null
  treeItem: TreePoint
  onSetPendingEditLayer?: (layer: Layer | EditedLayer) => void
  isInDrawMode: boolean
  onSetSelectedTreeItem: (routeId: UniqueIdentifier) => void
}) {
  if (context?.map) {
    const geojson = treeItem.geojson
    const geoJsonLayer = geoJSON<Point>(geojson, {
      pointToLayer: function (_feature, latlng) {
        const poiMarker = marker(latlng, {
          icon: isInDrawMode ? PoiDrawIcon : PoiIcon,

          // @ts-ignore
          poiId: treeItem.id,
          poiName: treeItem.name,
          type: treeItem.type,
        })
        return poiMarker
      },
      onEachFeature: function (feature, layer) {
        const point = layer as Marker
        if (feature.geometry.type === 'Point') {
          point.on('click', (e) => {
            const layerOptions = e.target.options as ExtendedPolygonOptions
            if (layerOptions?.polygonId && layerOptions?.polygonName) {
              onSetSelectedTreeItem(treeItem.id)
            }
          })
          point.on('pm:markerdragend', (e) => {
            onSetPendingEditLayer?.(e.layer)
          })
          point.on('pm:dragend', (e) => {
            onSetPendingEditLayer?.(e.layer)
          })

          const pointLatLng = point.getLatLng()
          point
            .bindPopup(getCoordinateFormString({ formId: treeItem.id, coordinate: pointLatLng }))
            .on('popupopen', function (this: Marker) {
              const form = document.getElementById(`${treeItem.id}`) as HTMLFormElement
              const latInput = form.querySelector('#lat') as HTMLInputElement
              const lngInput = form.querySelector('#lng') as HTMLInputElement

              form?.addEventListener('submit', (evt) => {
                evt.preventDefault()
                const updatedLat = parseFloat(latInput?.value)
                const updatedLng = parseFloat(lngInput?.value)
                pointLatLng.lat = updatedLat
                pointLatLng.lng = updatedLng
                point.setLatLng(pointLatLng)
                point.pm.disable()
                point.pm.enable()
                onSetPendingEditLayer?.(point)
              })
            })
        }
      },
    })
    geoJsonLayer.addTo(context.map)
  }
}

export function addShapeLayersToMap({
  context,
  tree = [],
  isEditModeActive,
  isActiveDrawingStations,
  onEditRoute,
  onSetPendingEditLayer,
  handleRemoveStation,
  shapeInDrawMode,
  onSetSelectedTreeItem,
}: {
  context: LeafletContextInterface | null
  tree: TreeItems<TreeFolderOrShape>
  isEditModeActive: boolean
  isActiveDrawingStations: boolean
  onEditRoute?: (editedLayer: EditedLayer) => void
  onSetPendingEditLayer?: (layer: Layer | EditedLayer) => void
  handleRemoveStation: (e: { layer: Layer; shape: string }) => void
  shapeInDrawMode: ExtendedTreeItem<TreeFolderOrShape> | undefined | null
  onSetSelectedTreeItem: (routeId: UniqueIdentifier) => void
}) {
  if (context?.map) {
    const drawLayers = context.map?.pm?.getGeomanDrawLayers()
    // remove draw layer because when we finishing drawing, we load all routes and activate geoman
    drawLayers.forEach((layer) => {
      context.map.removeLayer(layer)
    })
    context.map?.eachLayer((layer) => {
      const layerWithOptions = layer as ExtFeatureGroup
      if (layerWithOptions?.options?.routeId?.length && layerWithOptions?.options?.routeName?.length) {
        context?.map.removeLayer(layer)
      }
    })

    const flatTree = flattenTree(tree ?? [])
    const filteredTree = flatTree.filter(
      (treeItem) => (treeItem as TreePolygon | TreeRoute)?.geojson && treeItem?.selected
    )

    // we need to sort routes, to put the selected route at the end to display it on top of map
    const sortedRoutes = filteredTree
      ? [...filteredTree].sort((a, _b) => {
          if (shapeInDrawMode && a.id === shapeInDrawMode.id) {
            return 1
          } else return -1
        })
      : []

    removeSignsFromAllRoutes(context.map)
    sortedRoutes?.forEach((treeItem) => {
      let shouldAddRoute = true

      if (
        isEditModeActive &&
        isTreeRoute(treeItem) &&
        shapeInDrawMode &&
        isTreeRoute(shapeInDrawMode) &&
        treeItem.name !== shapeInDrawMode.name &&
        !gjv.valid(treeItem.geojson)
      ) {
        shouldAddRoute = false
      }
      if (shouldAddRoute && isTreeRoute(treeItem) && !treeItem?.disabled) {
        createRouteLayer({
          context,
          treeItem,
          onEditRoute,
          onSetPendingEditLayer,
          handleRemoveStation,
          isInDrawMode: Boolean(shapeInDrawMode),
          onSetSelectedTreeItem,
        })
      }

      let shouldAddPolygon = true
      if (
        isEditModeActive &&
        isTreePolygon(treeItem) &&
        shapeInDrawMode &&
        isTreePolygon(shapeInDrawMode) &&
        treeItem.name !== shapeInDrawMode.name
      ) {
        shouldAddPolygon = false
      }
      if (shouldAddPolygon && isTreePolygon(treeItem) && !treeItem?.disabled) {
        createPolygonLayer({
          context,
          treeItem,
          onSetPendingEditLayer,
          isInDrawMode: Boolean(shapeInDrawMode),
          onSetSelectedTreeItem,
        })
      }

      let shouldAddPoi = true
      if (
        isEditModeActive &&
        isTreePoint(treeItem) &&
        shapeInDrawMode &&
        isTreePoint(shapeInDrawMode) &&
        treeItem.name !== shapeInDrawMode.name
      ) {
        shouldAddPoi = false
      }
      if (shouldAddPoi && isTreePoint(treeItem) && !treeItem?.disabled) {
        createPointLayer({
          context,
          treeItem,
          onSetPendingEditLayer,
          isInDrawMode: Boolean(shapeInDrawMode),
          onSetSelectedTreeItem,
        })
      }
    })

    if (isEditModeActive) {
      context.map.pm.enableGlobalEditMode()
    }

    if (isActiveDrawingStations || isEditModeActive) {
      removeSignsFromAllRoutes(context.map)
    }
  }
}
