import { DestinationMapLineStringFeature } from 'burro-map-utils'
import { Button } from 'flowbite-react'
import { FunctionComponent } from 'react'

interface DestinationMapsLinkLineStringButtonProps {
  lineString1: DestinationMapLineStringFeature
  lineString2: DestinationMapLineStringFeature
  onClickLink: (lineString1: DestinationMapLineStringFeature, lineString2: DestinationMapLineStringFeature) => void
  onClickUnlink: (lineString1: DestinationMapLineStringFeature, lineString2: DestinationMapLineStringFeature) => void
  className?: string
}

export const DestinationMapsLinkLineStringButton: FunctionComponent<DestinationMapsLinkLineStringButtonProps> = ({
  lineString1,
  lineString2,
  onClickLink,
  onClickUnlink,
  className,
}) => {
  const linked = lineString1.properties.trafficLinkedLineStringIds?.includes(lineString2.id!)

  return (
    <Button
      className={className}
      onClick={() => {
        if (linked) {
          onClickUnlink(lineString1, lineString2)
        } else {
          onClickLink(lineString1, lineString2)
        }
      }}
    >
      {linked ? 'Unlink' : 'Link'}
    </Button>
  )
}
