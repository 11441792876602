import { featureCollection } from '@turf/turf'
import { DestinationMapPointFeature, mapDeletingDestination } from 'burro-map-utils'
import { Button, Label } from 'flowbite-react'
import { FunctionComponent, useContext } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import toast from 'react-hot-toast'
import { PanelDrawerLayoutMode } from '../ui/PanelDrawerLayout/PanelDrawerLayout'
import { DestinationMapPointFeatureForm } from './DestinationMapPointFeatureForm'
import { DestinationMapsContext } from './DestinationMapsContext'

export const DestinationMapsFormPoint: FunctionComponent<{}> = () => {
  const {
    selectedFeatureIds: selectedFeatureIdState,
    destinationMapRenderState,
    setDestinationMapRenderState,
    setIsRightOpen,
    mode,
    setSelectedFeatureId,
    setNavigatingFromPosition,
    setNavigatingToPosition,
  } = useContext(DestinationMapsContext)

  const selectedPointFeature = destinationMapRenderState?.features?.find(
    (value) => value.id === selectedFeatureIdState[0]
  ) as DestinationMapPointFeature | undefined

  if (!selectedPointFeature) {
    return null
  }

  function handleCloseIfNecessary() {
    if (mode === PanelDrawerLayoutMode.DRAWER) {
      unstable_batchedUpdates(() => {
        setSelectedFeatureId([])
        setIsRightOpen(false)
      })
    }
  }

  return (
    <div className='grid gap-2'>
      <DestinationMapPointFeatureForm
        key={selectedPointFeature.id}
        feature={selectedPointFeature}
        onValidWatch={function (data) {
          const newDestinationMap = structuredClone(destinationMapRenderState)
          const indexOfFeature = destinationMapRenderState?.features?.findIndex(
            (value) => value.id === selectedPointFeature.id
          )
          if (indexOfFeature === undefined || indexOfFeature === -1) {
            return
          }
          newDestinationMap!.features![indexOfFeature].properties = data as any
          setDestinationMapRenderState(newDestinationMap)
          handleCloseIfNecessary()
        }}
      />
      <Label value='Route preview' />
      <Button
        size='sm'
        onClick={() => {
          setNavigatingFromPosition(selectedPointFeature.geometry.coordinates)
        }}
        className='w-full'
      >
        Go from
      </Button>
      <Button
        size='sm'
        onClick={() => {
          setNavigatingToPosition(selectedPointFeature.geometry.coordinates)
        }}
        className='w-full'
      >
        Go to
      </Button>
      <Button
        type='button'
        color='failure'
        onClick={() => {
          const map = mapDeletingDestination({
            map: destinationMapRenderState!,
            destinationId: selectedPointFeature.id!,
          })
          if (!map) {
            return
          }
          setDestinationMapRenderState(map)
        }}
        size='sm'
        className='w-full'
      >
        Delete
      </Button>
      <Button
        size='sm'
        color='gray'
        className='w-full'
        onClick={() => {
          navigator.clipboard.writeText(JSON.stringify(featureCollection([selectedPointFeature])))
          toast.success('Copied GeoJSON to clipboard.')
        }}
      >
        Copy GeoJSON
      </Button>
      <Button
        color='gray'
        onClick={() => {
          const file = new Blob([JSON.stringify(featureCollection([selectedPointFeature]))], {
            type: 'application/json',
          })
          const element = document.createElement('a')
          element.href = URL.createObjectURL(file)
          element.download = `${selectedPointFeature.properties.name?.trim() || 'Point'}_${new Date().toISOString()}.geojson`
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)
          toast.success('Downloaded')
        }}
      >
        Download GeoJSON
      </Button>
    </div>
  )
}
