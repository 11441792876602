import { useEffect, useState } from 'react'

export enum TailwindBreakpoint {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  TWOXL = '2xl',
}

export const useTailwindBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(TailwindBreakpoint.SM)

  const handleResize = () => {
    const windowWidth = window.innerWidth

    if (windowWidth >= 1536) {
      setBreakpoint(TailwindBreakpoint.TWOXL)
    } else if (windowWidth >= 1280) {
      setBreakpoint(TailwindBreakpoint.XL)
    } else if (windowWidth >= 1024) {
      setBreakpoint(TailwindBreakpoint.LG)
    } else if (windowWidth >= 768) {
      setBreakpoint(TailwindBreakpoint.MD)
    } else {
      setBreakpoint(TailwindBreakpoint.SM)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return breakpoint
}
