import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import { Feature, LineString } from '@turf/turf'
import { FunctionComponent, useState } from 'react'
import { useLocalstorageState } from 'rooks'
import { usePolygonsGetQuery } from '../../hooks/queries/usePolygonsGetQuery'
import { TailwindBreakpoint, useTailwindBreakpoint } from '../../utils/useTailwindBreakpoint'
import { AtlasNavbar } from '../ui/AtlasNavbar'
import { AlertModal } from '../ui/modals/AlertModal'
import { LoadingModal } from '../ui/modals/LoadingModal'
import { PanelDrawerLayout, PanelDrawerLayoutMode } from '../ui/PanelDrawerLayout/PanelDrawerLayout'
import { PolygonContext } from './PolygonContext'
import { PolygonMapContainer } from './PolygonMapContainer'
import { PolygonsPagePanelDrawerLeft } from './PolygonsPagePanelDrawerLeft'
import { PolygonsPagePanelDrawerRight } from './PolygonsPagePanelDrawerRight'

export const PolygonsPage: FunctionComponent = () => {
  const [isLeftOpenState, setIsLeftOpenState] = useState(true)
  const [isRightOpenState, setIsRightOpenState] = useState(false)

  const [selectedPolygonIdState, setSelectedPolygonIdState] = useState<string | null>(null)
  const [isShowingExperimentalModalState, showIsShowingExperimentalModalState] = useLocalstorageState<boolean>(
    'isShowingExperimentalModalState',
    true
  )
  const [plannedPathState, setPlannedPathState] = useState<Feature<LineString> | null>(null)

  const [excludedPolygonIdState, setExcludedPolygonIdState] = useState<string[] | null>(null)
  const tailwindBreakpoint = useTailwindBreakpoint()
  const mode = tailwindBreakpoint === TailwindBreakpoint.SM ? PanelDrawerLayoutMode.DRAWER : PanelDrawerLayoutMode.PANEL
  const { data: polygonsGetQueryData } = usePolygonsGetQuery({})

  const polygons = polygonsGetQueryData?.polygons
    ?.filter((v) => !v.is_deleted)
    ?.sort((a, b) => {
      if (a.polygon_name && b.polygon_name) {
        return a.polygon_name.localeCompare(b.polygon_name)
      }
      return 0
    })

  const isFetching = useIsFetching()
  const isMutating = useIsMutating()

  function handleAddExcludedPolygonId(id: string) {
    setExcludedPolygonIdState((values) => {
      if (values === null) {
        return [id]
      }
      if (values.includes(id)) {
        return values
      }
      return [...values, id]
    })
  }

  function handleRemoveExcludedPolygonIds(id: string) {
    setExcludedPolygonIdState((values) => {
      if (values === null) {
        return null
      }
      return values.filter((value) => value !== id)
    })
  }

  return (
    <PolygonContext.Provider
      value={{
        plannedPath: plannedPathState,
        setPlannedPath: setPlannedPathState,
        mode: mode,
        polygons: polygons ?? null,
        isLeftOpen: isLeftOpenState,
        isRightOpen: isRightOpenState,
        setIsLeftOpen: setIsLeftOpenState,
        setIsRightOpen: setIsRightOpenState,
        selectedPolygonId: selectedPolygonIdState,
        selectedPolygon:
          selectedPolygonIdState != null
            ? (polygonsGetQueryData?.polygons?.find((value) => value.polygon_id === selectedPolygonIdState) ?? null)
            : null,
        setSelectedPolygonId: (id) => {
          setSelectedPolygonIdState(id)
        },
        setExcludedPolygonIds: setExcludedPolygonIdState,
        excludedPolygonIds: excludedPolygonIdState,
        excludedPolygons:
          polygonsGetQueryData?.polygons.filter((value) =>
            value.polygon_id ? excludedPolygonIdState?.includes(value.polygon_id) : false
          ) ?? null,
        addExcludedPolygonId: handleAddExcludedPolygonId,
        removeExcludedPolygonId: handleRemoveExcludedPolygonIds,
      }}
    >
      <LoadingModal show={isFetching !== 0 || isMutating !== 0} />
      <AtlasNavbar
        onClickLeft={() => {
          setIsLeftOpenState(!isLeftOpenState)
        }}
        onClickRight={() => {
          setIsRightOpenState(!isRightOpenState)
        }}
      />

      <PanelDrawerLayout
        mode={mode}
        childrenPanelProps={{
          order: 1,
        }}
        left={{
          drawerProps: {
            open: isLeftOpenState,
            onClose: () => setIsLeftOpenState(false),
            className: 'h-full',
          },
          drawerItemsProps: { className: 'h-full' },
          panelDrawer: <PolygonsPagePanelDrawerLeft className='flex h-full w-full flex-col gap-2' />,
          panelProps: {
            order: 0,
            collapsible: true,
            minSize: 25,
            defaultSize: 25,
            className: isLeftOpenState ? 'mx-2 mb-2' : '',
          },
        }}
        right={{
          drawerProps: {
            open: isRightOpenState,
            onClose: () => setIsRightOpenState(false),
            className: 'h-full',
          },
          drawerItemsProps: { className: 'h-full' },
          panelDrawer: <PolygonsPagePanelDrawerRight className='flex h-full w-full flex-col gap-2' />,
          panelProps: {
            order: 2,
            collapsible: true,
            minSize: 25,
            defaultSize: 0,
            className: isRightOpenState ? 'mx-2 mb-2 dark:bg-gray-800' : '',
          },
        }}
      >
        <PolygonMapContainer />
      </PanelDrawerLayout>
      <AlertModal
        closeText='I acknowledge'
        dismissible={false}
        show={isShowingExperimentalModalState}
        onClose={() => {
          showIsShowingExperimentalModalState(false)
        }}
      >
        <div>
          <h2 className='text-xl font-medium'>Warning: Experimental</h2>
          <p className='pt-3'>This is an experimental feature. You acknowledge:</p>
          <ul className='list-disc list-inside pt-3'>
            <li>This was shipped with extreme haste</li>
            <li>It is completely untested</li>
            <li>The developer did his best</li>
          </ul>
        </div>
      </AlertModal>
    </PolygonContext.Provider>
  )
}
