import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ResponseSuccess } from 'api-types'
import { Spinner } from 'flowbite-react'
import polygonIcon from '../../../../assets/polygon_icon.svg'
import previewFolderIcon from '../../../../assets/preview-folder-icon.svg'
import routeIcon from '../../../../assets/route_icon.svg'
import { API_URLS, GET_FOLDERS_KEY } from '../../../../constants'
import { apiPut } from '../../../../utils/api'
import { CustomDropdown, CustomDropdownMenuItem } from '../../CustomDropdown'

export enum DisabledItemTypes {
  DeletedRoute = 'DeletedRoute',
  DeletedPolygon = 'DeletedPolygon',
  DeletedFolder = 'DeletedFolder',
  TrashFolder = 'TrashFolder',
  PreviewFolder = 'PreviewFolder',
}

interface DisabledItemProp {
  name: string
  type: DisabledItemTypes
  id?: string
}

export function DisabledItem({ name, id, type }: DisabledItemProp) {
  const queryClient = useQueryClient()

  const mutationRestoreRoute = useMutation({
    mutationFn: async () => {
      if (id) {
        await apiPut<null, ResponseSuccess>(API_URLS.routes.restoreRoute(id))
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_FOLDERS_KEY] })
    },
  })

  const isLoading = mutationRestoreRoute.isPending

  return (
    <div className={`flex flex-1 p-2 sm:p-1`}>
      <div className={`flex flex-1 items-center justify-between ${isLoading ? 'opacity-20' : ''}`}>
        {isLoading && <Spinner />}
        <div className='flex flex-1 items-center'>
          {type === DisabledItemTypes.PreviewFolder && (
            <img src={previewFolderIcon} className=' my-auto ml-1 h-4 w-4' alt='preview icon' />
          )}
          {type === DisabledItemTypes.DeletedRoute && (
            <img src={routeIcon} className=' my-auto ml-1 h-4 w-4' alt='route icon' />
          )}
          {type === DisabledItemTypes.DeletedPolygon && (
            <img src={polygonIcon} className=' my-auto ml-1 h-4 w-4' alt='polygon icon' />
          )}
          {(type === DisabledItemTypes.TrashFolder || type === DisabledItemTypes.DeletedFolder) && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='ml-1 h-4 w-4'
            >
              {type === DisabledItemTypes.TrashFolder ? (
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0'
                />
              ) : (
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z'
                />
              )}
            </svg>
          )}
          <div className='flex'>
            <span className='flex flex-1 border border-transparent p-1 text-sm text-gray-900 dark:text-white whitespace-nowrap w-60 overflow-hidden'>
              {name ?? 'Trash'}
            </span>
          </div>
        </div>
      </div>
      {type === DisabledItemTypes.DeletedRoute && (
        <div className='flex shrink-0 items-center'>
          <CustomDropdown
            label='trash item menu'
            TriggerComponent={
              <button
                type='button'
                className={`mr-1 flex h-8 w-8 shrink-0 items-center justify-center rounded border border-solid border-transparent bg-transparent px-1 py-1 text-sm font-normal text-stone-700 hover:border-solid hover:border-blue-500 hover:bg-white hover:text-blue-500 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200`}
                title='Edit route'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='h-6 w-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z'
                  />
                </svg>
              </button>
            }
          >
            <CustomDropdownMenuItem
              label='Restore'
              onClick={(e) => {
                e.stopPropagation()
                mutationRestoreRoute.mutate()
              }}
              iconComponent={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='h-4 w-4'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99'
                  />
                </svg>
              }
            >
              Restore
            </CustomDropdownMenuItem>
          </CustomDropdown>
        </div>
      )}
    </div>
  )
}
