import type { VerifyTokenResponseError, VerifyTokenResponseSuccess } from 'middleware/types'

import { getQueryParamFromSelectedUser } from '../context/SelectedUserContext'
import { localStorage } from './localStorage'

if (!import.meta.env.VITE_API_URL) {
  throw new Error('VITE_API_URL is missing')
}

export async function handleApiResponse<TResponse>(response: Response): Promise<TResponse> {
  try {
    const data = await response.json()
    if (response.ok) {
      if ((data as VerifyTokenResponseSuccess)?.redirect_url) {
        const redirectUrl = (data as VerifyTokenResponseSuccess).redirect_url
        if (redirectUrl.includes('/login')) {
          localStorage.clearUser()
        }
        window.location.href = redirectUrl
      }
      return data as TResponse
    } else {
      if ('error' in data && 'type' in data) {
        if ((data as VerifyTokenResponseError).type) {
          localStorage.clearUser()
          window.location.href = '/login'
        }
      }
      return Promise.reject(data)
    }
  } catch (err) {
    console.error('handleApiResponse error: ', JSON.stringify(err))
    return Promise.reject(err)
  }
}

export async function apiPost<TData, TResponse>(url: string, data?: TData): Promise<TResponse> {
  const userParameter = getQueryParamFromSelectedUser()
  return fetch(`${import.meta.env.VITE_API_URL}/${url}${userParameter}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then(handleApiResponse<TResponse>)
}

export async function apiPostForm<TResponse>(url: string, data?: FormData): Promise<TResponse> {
  const userParameter = getQueryParamFromSelectedUser()
  return fetch(`${import.meta.env.VITE_API_URL}/${url}${userParameter}`, {
    method: 'POST',
    body: data,
    credentials: 'include',
  }).then(handleApiResponse<TResponse>)
}

export async function apiPut<TData, TResponse>(url: string, data?: TData): Promise<TResponse> {
  const userParameter = getQueryParamFromSelectedUser()
  return fetch(`${import.meta.env.VITE_API_URL}/${url}${userParameter}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then(handleApiResponse<TResponse>)
}

export async function apiDelete<TResponse>(url: string): Promise<TResponse> {
  const userParameter = getQueryParamFromSelectedUser()
  return fetch(`${import.meta.env.VITE_API_URL}/${url}${userParameter}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then(handleApiResponse<TResponse>)
}

export async function apiGet<TResponse>(url: string): Promise<TResponse> {
  const userParameter = getQueryParamFromSelectedUser()
  const baseUrl = import.meta.env.VITE_API_URL
  return await fetch(`${baseUrl}/${url}${userParameter}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then(handleApiResponse<TResponse>)
}
