import { useLocalstorageState } from 'rooks'

const LocalStorageKeys = {
  DeviceColorIndex: 'deviceColorIndexState',
  PathColorIndex: 'pathColorIndexState',
  PointColorIndex: 'destinationColorIndexState',
  EndpointColorIndex: 'endpointColorIndexState',
  CoordinateColorIndex: 'pointColorIndexState',
  WarningColorIndex: 'warningColorIndexState',
  IsUsingFeet: 'isUsingFeetState',
  IsSafeAngleModeIsOn: 'isSafeAngleModeIsOnState',
  TurnRadius: 'turnRadiusState',
  IsSnapModeOn: 'isSnapModeOnState',
  IsShowingSiteBounds: 'isShowingSiteBoundsState',
  IsShowingTextMarkers: 'isShowingTextMarkersState',
  IsShowingAngleWarnings: 'isShowingAngleWarningsState',
  IsShowingTerminalWarnings: 'isShowingTerminalWarningsState',
  IsMapShowingSatelliteTiles: 'isMapShowingSatelliteTiles',
  IsMapShowingOverlayTiles: 'isMapShowingOverlayTiles',
  IsMapShowingDroneTiles: 'isMapShowingDroneTiles',
  CoverageWidthMeters: 'coverageWidthMeters',
  TurnRadiusMeters: 'turnRadiusMeters',
  SwathsVariant: 'swathsVariant',
  RobotWidthMeters: 'robotWidthMeters',
  MowPatternSkip: 'mowPatternSkip',
  OverlapPercent: 'overlapPercent',
  CoverPerimeters: 'coverPerimeters',
  SwathsAngleRadians: 'swathsAngleRadians',
} as const

export const useDeviceColorIndex = () => useLocalstorageState(LocalStorageKeys.DeviceColorIndex, 0)
export const usePathColorIndex = () => useLocalstorageState(LocalStorageKeys.PathColorIndex, 1)
export const usePointColorIndex = () => useLocalstorageState(LocalStorageKeys.PointColorIndex, 2)
export const useEndpointColorIndex = () => useLocalstorageState(LocalStorageKeys.EndpointColorIndex, 3)
export const useCoordinateColorIndex = () => useLocalstorageState(LocalStorageKeys.CoordinateColorIndex, 4)
export const useWarningColorIndex = () => useLocalstorageState(LocalStorageKeys.WarningColorIndex, 5)
export const useIsUsingFeet = () => useLocalstorageState(LocalStorageKeys.IsUsingFeet, true)
export const useIsSafeAngleModeIsOn = () => useLocalstorageState<boolean>(LocalStorageKeys.IsSafeAngleModeIsOn, false)
export const useTurnRadius = () => useLocalstorageState<string | null>(LocalStorageKeys.TurnRadius, null)
export const useIsSnapModeOn = () => useLocalstorageState<boolean>(LocalStorageKeys.IsSnapModeOn, false)
export const useIsShowingSiteBounds = () => useLocalstorageState<boolean>(LocalStorageKeys.IsShowingSiteBounds, false)
export const useIsShowingTextMarkers = () => useLocalstorageState<boolean>(LocalStorageKeys.IsShowingTextMarkers, false)
export const useIsShowingAngleWarnings = () =>
  useLocalstorageState<boolean>(LocalStorageKeys.IsShowingAngleWarnings, false)
export const useIsShowingTerminalWarnings = () =>
  useLocalstorageState<boolean>(LocalStorageKeys.IsShowingTerminalWarnings, false)
export const useIsMapShowingSatelliteTiles = () =>
  useLocalstorageState<boolean>(LocalStorageKeys.IsMapShowingSatelliteTiles, true)
export const useIsMapShowingOverlayTiles = () =>
  useLocalstorageState<boolean>(LocalStorageKeys.IsMapShowingOverlayTiles, true)
export const useIsMapShowingDroneTiles = () =>
  useLocalstorageState<boolean>(LocalStorageKeys.IsMapShowingDroneTiles, true)
export const useCoverageWidthMeters = () => useLocalstorageState<number>(LocalStorageKeys.CoverageWidthMeters, 0.914)
export const useTurnRadiusMeters = () => useLocalstorageState<number>(LocalStorageKeys.TurnRadiusMeters, 2.2)
export const useSwathsVariant = () => useLocalstorageState<number | null>(LocalStorageKeys.SwathsVariant, null)
export const useRobotWidthMeters = () => useLocalstorageState<number>(LocalStorageKeys.RobotWidthMeters, 0.94615)
export const useMowPatternSkip = () => useLocalstorageState<number>(LocalStorageKeys.MowPatternSkip, 4)
export const useOverlapPercent = () => useLocalstorageState<number>(LocalStorageKeys.OverlapPercent, 0.2)
export const useCoverPerimeters = () => useLocalstorageState<boolean>(LocalStorageKeys.CoverPerimeters, false)
export const useSwathsAngleRadians = () =>
  useLocalstorageState<number | null>(LocalStorageKeys.SwathsAngleRadians, null)
