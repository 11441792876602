import deburr from 'lodash.deburr'
import type { Device } from 'modules/devices/types'
import { useLocation } from 'react-router-dom'
import { AtlasAvatar } from '../ui/AtlasAvatar'
import { AtlasNavigationDropdown } from '../ui/AtlasNavigationDropdown'
import { CustomSearchForm } from '../ui/CustomSearchForm'
import { LoadingModal } from '../ui/modals/LoadingModal'
import { RobotItem } from './RobotItem'
import { ScheduleModal } from './Schedule/ScheduleModal'

interface RobotsListProps {
  className?: string
  disableRefetch?: boolean
  robotsList?: Device[]
  isLoading: boolean
  selectedRobot: Device
  onSetSelectedRobot: (newSelectedRobot: Device) => void
}

export function RobotsList({ className, robotsList, isLoading, selectedRobot, onSetSelectedRobot }: RobotsListProps) {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const searchTermFromQuery = queryParams.get('searchTerm') || ''
  const editRouteId = queryParams.get('schedule') || ''

  const trimmedSearchTerm = searchTermFromQuery.trim()
  let filteredRobots = robotsList
  if (trimmedSearchTerm?.length > 0) {
    filteredRobots = robotsList?.filter((robot) => {
      const nameLowCase = robot.name.trim().toLowerCase()
      const sanitizedName = deburr(nameLowCase)
      const searchTermLowCase = trimmedSearchTerm.toLowerCase()
      const sanitizedSearchTermLowCase = deburr(searchTermLowCase)
      return sanitizedName.includes(sanitizedSearchTermLowCase)
    })
  }

  return (
    <div className='w-full h-full flex flex-col gap-2'>
      <div className='flex items-center justify-between'>
        <div className='flex-1'>
          <AtlasAvatar className='' />
        </div>
        <div className='flex-1 flex justify-center'>
          <AtlasNavigationDropdown />
        </div>
        <div className='flex-1 flex justify-end'></div>
      </div>
      <div
        className={`${className ? className : ''} relative flex-1 flex flex-col ${isLoading ? 'opacity-20' : ''} overflow-hidden`}
      >
        <LoadingModal show={isLoading} />
        <CustomSearchForm placeholder='Filter robots' />
        <ul className='relative flex-1 flex flex-col divide-y divide-gray-100 overflow-auto'>
          {filteredRobots?.map((robot, idx) => (
            <RobotItem
              key={robot.id}
              robot={robot}
              isRobotSelected={selectedRobot.id === robot.id}
              isFirstItem={idx === 0}
              isLastItem={Boolean(filteredRobots && idx === filteredRobots.length - 1)}
              onSelectRobot={onSetSelectedRobot}
            />
          ))}
        </ul>
        {editRouteId?.length > 0 && <ScheduleModal />}
      </div>
    </div>
  )
}
