import { Button } from 'flowbite-react'
import type { AddRouteBodyParams } from 'modules/routes/types'
import { type ChangeEvent, type FormEvent } from 'react'
import { AlertFailure } from '../../AlertFailure'
import { DEFAULT_IMPORT_GEOJSON } from '../ShapesTree'

interface AddRouteProps {
  onAddField: () => void
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  inputSubLabel?: string
  geojsonErrors: string[]
  values: AddRouteBodyParams
  isLoading?: boolean
}

export function AddRoute({
  onAddField,
  onChange,
  inputSubLabel,
  geojsonErrors,
  values,
  isLoading = false,
}: AddRouteProps) {
  const handleImportRoute = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onAddField()
  }

  return (
    <form className='mt-2 flex w-80 flex-col items-start gap-3' onSubmit={handleImportRoute}>
      <input
        type='text'
        placeholder='Route name'
        required
        name='name'
        value={values.name}
        onChange={onChange}
        className='mr-3 block w-full rounded border border-gray-300 p-2.5 text-sm text-gray-900 focus:border-blue-700 focus:ring-blue-700'
      />
      <textarea
        rows={10}
        required
        onChange={onChange}
        value={values.geojsonString}
        name='geojsonString'
        className='block w-full rounded border border-gray-300 p-2.5 text-sm text-gray-900 focus:border-blue-700 focus:ring-blue-700'
        placeholder={DEFAULT_IMPORT_GEOJSON}
      />
      {geojsonErrors?.map((errorMessage) => <AlertFailure key={errorMessage} errorMsg={errorMessage} />)}
      <Button
        type='submit'
        outline
        color='blue'
        className='[& svg]:fill-blue-700 shrink-0 rounded [&>*]:rounded-sm'
        isProcessing={isLoading}
        disabled={isLoading}
      >
        Save
      </Button>
      {inputSubLabel?.length && <p className='mt-2 text-sm text-gray-500'>{inputSubLabel}</p>}
    </form>
  )
}
